import { faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Scale } from "@mui/icons-material";
import React, { useContext } from "react";
import { Accordion, Button, Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import offer1 from "../../../assets/images/offer1.png";
import WebContext from "../../../context/web-context";

function MemberBenefits() {
  const { windowDimensions } = useContext(WebContext);
  let navigate = useNavigate();

  const benefitsHeadingBanner = () => (
    <div className="row px-2 py-4 bg-success text-white fs-5">
      <div className="col-8 d-flex flex-column justify-content-center">
        <div className="row ">
          <div className="col-sm-12 col-md-12 col-lg-6 ps-sm-5 ">
            PREMIUM
            <span className="text-decoration-line-through ms-2">($10/MO.)</span>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 ">
            LIMITED TIME OFFER
          </div>
        </div>
      </div>
      <div className="col-4 d-flex justify-content-end  my-0">
        <div
          className=""
          style={{
            transform:
              windowDimensions.width <= 768 ? "scale(1.8)" : "scale(2.5)",
          }}
        >
          <img
            style={{
              width: "100%",
              minHeight: 50,
              maxHeight: 50,
              objectFit: "contain",
            }}
            src={offer1}
            alt="Offer image"
          />
        </div>
      </div>
    </div>
  );

  const benefitsAccordian = () => (
    <div className="mt-5">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <FontAwesomeIcon
              icon={faShare}
              style={{
                transform: "scaleY(-1)",
              }}
              className="text-success me-2 pt-1"
            />
            Deals
          </Accordion.Header>
          <Accordion.Body>
            Get awesome deals with huge savings from favorite local and national
            merchants.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <FontAwesomeIcon
              icon={faShare}
              style={{
                transform: "scaleY(-1)",
              }}
              className="text-success me-2 pt-1"
            />
            Faster Rewards
          </Accordion.Header>
          <Accordion.Body>
            Earn points for deal purchases you make, checking-in and sharing
            activity on social media. You will also receive points for purchases
            and other activites of your referrals.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <FontAwesomeIcon
              icon={faShare}
              style={{
                transform: "scaleY(-1)",
              }}
              className="text-success me-2 pt-1"
            />
            Eligible for weekly, monthly & quarterly giveaways
          </Accordion.Header>
          <Accordion.Body>
            Automatically receive 10 entries into every single giveaway and
            drawing we do. If you redeem points for extra entries into a
            giveaway and do not win, you will get 10% of those points back.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );

  const benefitsRegisterNowButton = () => (
    <Container className="mt-3 d-flex justify-content-between flex-wrap">
      <div className="mb-2">
        <span className="text-success">Register Now</span> to be eligible for
        these great prizes, trips and more!
      </div>
      <div>
        <Button variant="success" onClick={() => navigate("/SignUp")}>
          Register Now
        </Button>
      </div>
    </Container>
  );

  return (
    <div className="container mt-5">
      <Card className="bg-white shadow my-5 ps-2 py-4 p-sm-5  overflow-hidden border-0">
        {benefitsHeadingBanner()}
        {benefitsAccordian()}
        {benefitsRegisterNowButton()}
      </Card>
    </div>
  );
}

export default MemberBenefits;
