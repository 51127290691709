import React from "react";
import MySpinner from "./MySpinner";

const Loader = ({ height, width }) => {
  return (
    <div
      style={{
        height,
        width,
      }}
      className="d-flex justify-content-center align-items-center text-success mb-5"
    >
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
};

export default Loader;
