import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CATEGORY_LIST_HIERARCHY_ADMIN } from "../../../config/endpoints";
import myAxios from "../../../config/my-axios";
import MySpinner from "../../common/MySpinner";
import MerchantDashboardContainer from "../components/DashboardContainer";
import PageTitle from "../components/PageTitle";
import TreeMenu from "react-simple-tree-menu";
import "../../../../node_modules/react-simple-tree-menu/dist/main.css";

export default function AdminCategoryHierarchy() {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [properties, setProperties] = useState([]);

  const loading = () => (
    <div
      className="d-flex justify-content-center align-items-center mb-5"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );

  function loadCategories() {
    setLoader(true);
    myAxios
      .post(CATEGORY_LIST_HIERARCHY_ADMIN)
      .then((res) => {
        setLoader(false);
        let resD = res.data;
        if (resD.success) {
          setData(resD.data.filter((item) => item.status === "active"));
        } else {
          toast.error(resD.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.message);
      });
  }

  const handleSubSubCategories = (child) => {
    let children = [];
    if (child?.length > 0) {
      child.forEach((data) => {
        if (data.status === "active") {
          children.push({
            key: data?.id,
            label: data?.name,
          });
        }
      });
      return children?.length > 0 && children;
    }
  };
  const handleSubCategories = (child) => {
    let children = [];
    if (child?.length > 0) {
      child.forEach((data) => {
        if (data.status === "active") {
          children.push({
            key: data?.id,
            label: data?.name,
            nodes: data?.children && handleSubSubCategories(data?.children),
          });
        }
      });
      return children?.length > 0 && children;
    }
  };
  useEffect(() => {
    if (data.length > 0) {
      let properties = [];
      data.forEach((data) => {
        if (data.status === "active") {
          properties.push({
            key: data?.id,
            label: data?.name,
            nodes: data?.children && handleSubCategories(data?.children),
          });
        }
      });
      properties.length > 0 && setProperties(properties);
    }
  }, [data]);

  useEffect(() => {
    loadCategories();
  }, []);

  return (
    <MerchantDashboardContainer>
      <PageTitle title={`Category hierarchy`} />
      {loader ? (
        loading()
      ) : (
        <Row>
          <Col sm={12}>
            <Card body className="shadow py-2 mt-4">
              <TreeMenu data={properties} hasSearch={false} />
            </Card>
          </Col>
        </Row>
      )}
    </MerchantDashboardContainer>
  );
}
