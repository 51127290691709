import { useContext, useState } from "react";
import { Container } from "react-bootstrap";
import { Navigate, Outlet } from "react-router-dom";
import myAxios from "../../../config/my-axios";
import AdminContext from "../../../context/admin-context";
import "./AdminLayout.css";
import AdminNavbar from "./AdminNavbar";
import Sidebar from "./Sidebar";

function AdminLayout(props) {
  const { authUser } = useContext(AdminContext);

  const [sidebar, setSidebar] = useState(false)

  if (!authUser) {
    return <Navigate to="/admin-login" replace />;
  }

  function switchSidebar() {
    setSidebar(!sidebar)
  }

  function closeSidebar() {
    setSidebar(false)
  }


  myAxios.defaults.headers.common["Authorization"] = "Bearer " + authUser.token;

  return (
    <div className="Main position-relative">
      <Sidebar user={authUser} sidebar={sidebar} closeSidebar={closeSidebar} />
      <div className="d-none d-md-block bg-danger" style={{ width: "270px", minHeight: '100vh' }}></div>
      <div style={{ position: "relative", flexGrow: 1, width : 'calc(100% - 270px)' }}>
        <AdminNavbar switchSidebar={switchSidebar} />
        <Container fluid className="py-3 px-md-4 px-3" style={{ backgroundColor: '#F1F4FC', minHeight: '100vh' }}>
          <Outlet />
        </Container>
      </div>
    </div>
  );
}

export default AdminLayout;
