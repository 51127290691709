import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { MERCHANT_DETAIL } from "../../../config/endpoints";
import myAxios from "../../../config/my-axios";
import MerchantContext from "../../../context/merchant-context";
import MySpinner from "../../common/MySpinner";
import MerchantDashboardContainer from "../components/MerchantDashboardContainer";
import MerchantPageTitle from "../components/MerchantPageTitle";
import AddMerchantKeywords from "./AddMerchantKeywords";
import MerchantWorkingHours from "./MerchantWorkingHours";

function ManageBusinessHours() {
  const { authMerchant } = useContext(MerchantContext);
  const [form, setForm] = useState();
  const [load, setLoad] = useState(true);
  const id = authMerchant?.merchant?._id;

  const loading = () => (
    <div
      className="d-flex justify-content-center align-items-center mb-5"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  function loadMerchant() {
    if (id) {
      myAxios
        .post(MERCHANT_DETAIL, { id })
        .then((res) => {
          let data = res.data;
          if (data.success) {
            let merchant = data.data;
            setForm({
              ...merchant,
            });
            setLoad(false);
          } else {
            toast.error(data.message);
            setLoad(false);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setLoad(false);
        });
    } else {
      setLoad(false);
    }
  }

  useEffect(() => {
    loadMerchant();
  }, []);

  return (
    <MerchantDashboardContainer>
      <MerchantPageTitle
        title={"Manage Business Hours"}
        rightSide={
          <Button
            type="submit"
            form="workinhHours"
            variant="secondary"
            className="btn btn-success "
            style={{ float: "right" }}
          >
            Save
          </Button>
        }
      />
      {load ? (
        loading()
      ) : (
        <MerchantWorkingHours
          loadMerchant={loadMerchant}
          workingHours={form?.workingHours}
          saveButton={false}
        />
      )}
    </MerchantDashboardContainer>
  );
}

export default ManageBusinessHours;
