import {
  BASE_URL,
  CATEGORY_LIST_ADMIN,
  GET_MERCHANT,
  HOMESCREEN,
  SEARCH_CATEGORY_KEYWORD,
  SEARCH_MERCHANT,
} from "../../config/endpoints";
import http from "../httpService";
import { handleFailure } from "../../utils/handleFailure";
import { get_access_token } from "../../utils/apiUtils";

const merchantService = {
  getSearchMerchant: async ({ keyword, latitude, longitude, location }) => {
    const config = {
      headers: { Authorization: `Bearer sdfsdf234rwkjh@#$wesjfh98serbwsdr` },
    };
    const url = SEARCH_MERCHANT;
    const params = {
      keyword: keyword || "",
      latitude: latitude || "",
      longitude: longitude || "",
      location: location || "",
    };
    try {
      const response = await http.post(url, params, config);
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  getSearchByCategoryKeyword: async ({ keyword }) => {
    const config = {
      headers: { Authorization: `Bearer sdfsdf234rwkjh@#$wesjfh98serbwsdr` },
    };
    const url = SEARCH_CATEGORY_KEYWORD;
    const params = {
      keyword: keyword || "",
    };
    try {
      const response = await http.post(url, params, config);
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  getMerchant: async (merchantId) => {
    try {
      const response = await http.post(GET_MERCHANT, { merchantId }, null);
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  getMerchantReview: async (merchantId) => {
    const url = BASE_URL + "/merchant/tripadvisor-reviews";
    try {
      const response = await http.post(url, { merchantId }, null);
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
};

export default merchantService;
