import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ADD_IMAGE_MERCHANT, MERCHANT_DETAIL, REMOVE_IMAGE_MERCHANT } from "../../../config/endpoints";
import myAxios from "../../../config/my-axios";
import { toast } from "react-toastify";
import ImageCropper from "../../common/ImageCropper";
import Loader from "../../common/Loader";

export default function MerchantImages() {
  const { id } = useParams();
  const [images, setImages] = useState([]);
  const [load, setLoad] = useState(true);
  const [modal, showModal] = useState(false);
  const [form, setForm] = useState({
    image: null,
    imageUrl: "",
    croppedImage: null,
    croppedImageUrl: "",
    originalImage: null,
  });

  useEffect(() => {
    myAxios
      .post(MERCHANT_DETAIL, { id })
      .then((res) => {
        let data = res.data;
        if (data.success && data.data) {
          setImages(data.data.images);
        }
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, []);

  function uploadImage() {
    if (!form.croppedImage) {
      toast.error("Please uplaod image first");
      return;
    }

    setLoad(true);
    const config = { headers: { "content-type": "multipart/form-data" } };
    let formData = new FormData();
    formData.append("id", id);

    // formData.append("image", form.image, form.image.name);
    formData.append("image", form.croppedImage, "cropped_image.png");
    formData.append("originalImage", form.image, "original_image.png");

    myAxios
      .post(ADD_IMAGE_MERCHANT, formData, config)
      .then((res) => {
        let data = res.data;
        if (data.success) {
          setImages([...images, { image: form.croppedImageUrl }]);
          setForm({
            image: null,
            imageUrl: "",
            croppedImage: null,
            croppedImageUrl: "",
            originalImage: null,
          });
          toast.success("Image uploaded successfully");
        } else {
          toast.error(data.message);
        }
        setLoad(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  function handleImage(e) {
    setForm({ ...form, image: e.target.files[0], imageUrl: URL.createObjectURL(e.target.files[0]) });
    showModal(true);
  }

  function handleCrop(image) {
    setForm({
      ...form,
      croppedImage: image,
      croppedImageUrl: URL.createObjectURL(image),
    });

    showModal(false);
  }

  function handleDelete(imageId, index) {
    let check = window.confirm("Are you sure to delete the image ?");
    if (check) {
      setLoad(true);

      myAxios
        .post(REMOVE_IMAGE_MERCHANT, { id: imageId, merchantId: id })
        .then((res) => {
          let data = res.data;
          if (data && data.success) {
            toast.success("Image deleted successfully");
            let newImages = [...images];
            newImages.splice(index, 1);
            setImages(newImages);
          } else {
            toast.error(data.message);
          }
          setLoad(false);
        })
        .catch((err) => {
          toast.error(err.message);
          setLoad(false);
        });
    }
  }

  return (
    <Container fluid>
      {load && <Loader />}
      <Row className="bg-white">
        <Col sm="6" md="3" className=" py-3">
          <div className="d-flex position-relative flex-column">
            <div
              className="position-absolute bg-secondary rounded d-flex justify-content-center align-items-center"
              style={{
                height: "200px",
                width: "100%",
                zIndex: 10,
                top: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="text-white" style={{ fontSize: 70 }} />
            </div>
            <Form.Control
              type="file"
              size="sm"
              onChange={handleImage}
              name="image"
              style={{
                height: "200px",
                width: "100%",
                zIndex: 20,
                opacity: 0.1,
              }}
            />
          </div>
        </Col>

        <Col sm="6" md="3"></Col>

        <Col sm="6" md="3">
          <div className="d-flex justify-content-center align-items-center flex-column mb-3 h-100">
            <span>Image preview</span>
            <Image src={form.croppedImageUrl} style={{ width: "100%", height: "auto" }} />
            <Button onClickCapture={uploadImage} size="sm" variant="success" className="mt-2" style={{ width: "80%" }}>
              upload image
            </Button>
          </div>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col sm="12">
          {form.imageUrl && (
            <>
              <ImageCropper
                image={form.imageUrl}
                width={340}
                height={240}
                onCrop={handleCrop}
                modal={modal}
                showModal={showModal}
                size="lg"
              />
            </>
          )}
        </Col>

        {images.map((image, i) => {
          return (
            <Col sm="6" md="3" key={i}>
              <div className="d-flex justify-content-center align-items-center flex-column mb-3 h-100">
                <Image src={image.image} style={{ width: "80%", height: "auto" }} />
                <a
                  onClickCapture={() => handleDelete(image.id, i)}
                  size="sm"
                  variant="light"
                  className="text-danger hover-cursor mt-2"
                >
                  delete image
                </a>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
