import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AdminNavbar({switchSidebar}) {
  return <div className="navbar w-100 d-md-none d-flex justify-content-between align-items-center px-3" style={{ height: '50px', zIndex: 2000 }}> 
  <FontAwesomeIcon icon={faBars} className="text-white hover-cursor" size="2x" onClickCapture={switchSidebar}  />
  <img src="/logo.png" style={{ height: "34px", width: "auto" }} />
  <div></div>
  </div>;
}

export default AdminNavbar;
