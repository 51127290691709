import React, { createContext, useContext, useReducer } from "react";

const commonContext = createContext(null);

const initialVal = {
  pool: {},
};
const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_POOL_DATA": {
      return { ...state, pool: action.payload };
    }

    default:
      return state;
  }
};

export const GlobalProvider = (props) => {
  const [commonCtx, dispatchCommon] = useReducer(reducer, initialVal);

  return <commonContext.Provider value={[commonCtx, dispatchCommon]} {...props} />;
};

export const CommonContext = () => {
  const context = useContext(commonContext);
  if (!context) {
    throw new Error("Please use the context inside parent scope");
  }
  return context;
};
