import React, { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation, faQuestionCircle, faSearch, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import WebContext from "../../../context/web-context";
import { CommonContext } from "../../../store/commonStore";
import styles from "./Banner.module.css";
import { toast } from "react-toastify";

function Banner({ poolData }) {
  const {
    showSearchBar,
    setShowSearchBar,
    setSearchMerchant,
    windowDimensions,
    userLocationStatus,
    handleSearchBarFormSubmit,
    categoryInput,
    setCategoryInput,
    useUserLocation,
    setUseUserLocation,
    getLocation,
    showUserLocationButton,
    autoCompleteInputField,
    handleCategorySearchBarField,
    searchBarModal,
    handleSearchBarModalShow,
    handleSetCurrentLocation,
    isAllow,
    setSelectedKeyword,
    selectedKeyword,
  } = useContext(WebContext);

  const [commonCtx] = CommonContext();

  const buttonFontSize = windowDimensions.width <= 768 ? `${styles.memberPoolFontSize}` : "fs-4";
  const pollIconMargin = windowDimensions.width <= 768 ? 4 : 10;
  const toggleVisible = () => {
    if (windowDimensions.width <= 768) {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 150) {
        setShowSearchBar(true);
      } else if (scrolled <= 100) {
        setShowSearchBar(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => window.removeEventListener("scroll", toggleVisible);
  }, []);
  useEffect(() => {
    return () => {
      setShowSearchBar(false);
    };
  }, []);
  return (
    <>
      <section
        className={`banner bg-full overlay d-flex justify-content-center align-items-center ${styles.banner} mt-4`}
      >
        {searchBarModal()}
        <div className="">
          <div className="container  ">
            <div className="row ">
              <div className="col-xs-12 text-left ">
                <div className={`mb-md-5 h1 text-white ${styles.bannersubheading}`}>
                  <h3>
                    We are helping Merchants bring in valuable business and Members get the information and money saving
                    offers that matter to them the most.
                  </h3>
                </div>
                <form
                  action="/search"
                  onClick={() => {
                    windowDimensions.width <= 768 && handleSearchBarModalShow();
                  }}
                  onSubmit={handleSearchBarFormSubmit}
                >
                  <div className="container d-flex justify-content-center ">
                    <div className={`${styles.searbarContainer}`}>
                      <div className={`${styles.searbarInput1}`}>
                        {windowDimensions.width <= 768 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: 12,
                            }}
                          >
                            <input
                              style={{
                                border: 0,
                                width: "100%",
                                fontSize: "15px",
                                fontWeight: 400,
                                lineHeight: 1.5,
                              }}
                              autofocus={false}
                              onChange={(e) => {
                                handleSearchBarModalShow();
                                setSelectedKeyword(e.target.value);
                              }}
                              placeholder="What can we help you find today?"
                              value={selectedKeyword}
                            />
                          </div>
                        ) : (
                          handleCategorySearchBarField(
                            `${windowDimensions.width <= 768 && "keywordClass"} ${
                              styles.searbarInput
                            } categorySearchBar`
                          )
                        )}
                      </div>
                      <div style={{ display: "flex" }} className={`py-3`}>
                        <div className={` vr mx-2`}></div>
                      </div>
                      <div className={`d-flex justify-content-center align-items-center ${styles.searbarInput2}`}>
                        {windowDimensions.width <= 768
                          ? autoCompleteInputField(`${styles.mobileInputClass}`, true, false)
                          : autoCompleteInputField()}
                        {isAllow && (
                          <span
                            className="me-1 p-2"
                            style={{ cursor: "pointer" }}
                            // onClick={() => setUseUserLocation(true)}
                            onClick={() => {
                              setUseUserLocation(true);
                              handleSetCurrentLocation();
                            }}
                          >
                            <FontAwesomeIcon fontSize={20} icon={faLocation} />
                          </span>
                        )}
                      </div>
                      <button
                        // disabled={useUserLocation ? false : !categoryInput}
                        type="submit"
                        className={`py-3 btn-icon ${styles.searbarButton}`}
                      >
                        <FontAwesomeIcon fontSize={20} color="#fff" icon={faSearch} />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className={`container-fluid d-flex align-items-center bg-full  `}
        style={{
          backgroundColor: "#00446B",
          height: "50px",
          position: showSearchBar && "fixed",
          top: showSearchBar && 110,
          transition: ".3s ease-in-out",
          zIndex: 1000,
        }}
      >
        <div className="container px-0  d-flex justify-content-center align-items-center ">
          <div className={`px-0 ${styles.poolContainer}`}>
            <div className={`text-white ${buttonFontSize}  ${styles.pool}`}>
              <div className="d-flex justify-content-center align-items-center">
                <span
                  style={{
                    marginRight: pollIconMargin,
                  }}
                  onClickCapture={() => toast.info("Coming soon")}
                >
                  <FontAwesomeIcon
                    fontSize={windowDimensions.width <= 768 ? "15px" : 18}
                    className={`text-white ${buttonFontSize} `}
                    icon={faQuestionCircle}
                  />
                </span>
                <span
                  style={{
                    fontSize: windowDimensions.width <= 768 ? "12px" : 18,
                  }}
                >
                  Members Pool
                  {/* ${commonCtx?.pool?.member} */}
                </span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className={`text-white fw-bold ${buttonFontSize} vr mx-2`}></div>
            </div>
            <div className={`text-white ${buttonFontSize} ${styles.pool}`}>
              <div className="d-flex justify-content-center align-items-center">
                <span
                  style={{
                    marginRight: pollIconMargin,
                  }}
                  onClickCapture={() => toast.info("Coming soon")}
                >
                  <FontAwesomeIcon
                    fontSize={windowDimensions.width <= 768 ? "smaller" : 18}
                    className={`text-white ${buttonFontSize} `}
                    icon={faQuestionCircle}
                  />
                </span>
                <span
                  style={{
                    fontSize: windowDimensions.width <= 768 ? "12px" : 18,
                  }}
                >
                  Community Pool 
                  {/* ${commonCtx?.pool?.community} */}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
