import React, { useContext, useEffect, useState } from "react";
import WebContext from "../../../context/web-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsers, faSearch, faLocationDot, faLocation, faQuestion, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { CommonContext } from "../../../store/commonStore";
import styles from "../Sections/Banner.module.css";
import "./SeachbarAndPoolBarForBigScreen.css";
import { toast } from "react-toastify";

const SeachbarAndPoolBarForBigScreen = ({ poolData }) => {
  const {
    windowDimensions,

    handleSearchBarFormSubmit,
    categoryInput,
    useUserLocation,
    setUseUserLocation,
    autoCompleteInputField,
    handleCategorySearchBarField,
    handleSetCurrentLocation,
    isAllow,
  } = useContext(WebContext);
  const [commonCtx] = CommonContext();

  return (
    <>
      {windowDimensions.width > 768 && (
        <div
          className={`container-fluid align-items-center bg-full mt-4 `}
          style={{
            backgroundColor: "#00446B",
            height: "55px",
            lineHeight: "55px",
            position: "fixed",
            top: 93,
            transition: ".3s ease-in-out",
            zIndex: 99,
          }}
        >
          <div className="row">
            <div className="col-6 align-items-center d-flex ">
              <form
                className="row d-flex justify-content-between "
                style={{ flex: 1 }}
                onSubmit={handleSearchBarFormSubmit}
              >
                <div className="col-5 pe-0">
                  {handleCategorySearchBarField("form-control elementsHeight elementInput")}
                </div>
                <div className="col-5 pe-0">
                  <div className="d-flex justify-content-center align-items-center bg-white rounded">
                    <FontAwesomeIcon fontSize={15} icon={faLocationDot} color="#00446B" className="ps-2" />
                    {/* {autoCompleteInputField("elementsHeight elementInput")} */}
                    {autoCompleteInputField("elementsHeight elementInput")}

                    {isAllow && (
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setUseUserLocation(true);
                          handleSetCurrentLocation();
                        }}
                        fontSize={18}
                        icon={faLocation}
                        className="m-2"
                      />
                    )}
                  </div>
                </div>
                <div className="col-1 pe-0">
                  <div
                    className=" rounded border bg-white d-flex justify-content-around align-items-center elementsHeight "
                    style={{ width: 40, cursor: "pointer" }}
                    disabled={useUserLocation ? false : !categoryInput}
                    type="submit"
                    onClick={handleSearchBarFormSubmit}
                  >
                    <FontAwesomeIcon fontSize={20} color="#00446B" icon={faSearch} />
                  </div>
                </div>
              </form>
            </div>
            <div className="col-6 ">
              <div className="container-fluid d-flex justify-content-between align-items-center ">
                <div className={`${styles.poolContainer}`}>
                  <div className={`text-white   ${styles.pool}`}>
                    <div>
                      <span onClickCapture={() => toast.info("Coming soon")}>
                        <FontAwesomeIcon fontSize={18} className={`text-white  me-1`} icon={faQuestionCircle} />
                      </span>
                      <span>Members Pool</span>
                      {/* <span>Members Pool ${commonCtx?.pool?.member}</span> */}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className={`text-white fw-bold  vr mx-2`}></div>
                  </div>
                  <div className={`text-white  ${styles.pool}`}>
                    <div>
                      <span onClickCapture={() => toast.info("Coming soon")}>
                        <FontAwesomeIcon fontSize={18} className={`text-white  me-1`} icon={faQuestionCircle} />
                      </span>
                      <span> Community Pool</span>
                      {/* <span> Community Pool ${commonCtx?.pool?.community}</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SeachbarAndPoolBarForBigScreen;
