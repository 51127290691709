import React, { createContext, useContext, useReducer } from "react";

const signUpContext = createContext(null);

const initialVal = {
  memberFormData: {
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    password: "",
    city: "",
    state: "",
    zipcode: "",
    checked: true,
  },
  merchantFormData: {
    businessName: "",
    address: "",
    phone: "",
    name: "",
    lastName: "",
    email: "",
    dob: "",
    password: "",
    city: "",
    state: "",
    zipcode: "",
    checked: true,
  },
};
const reducer = (state, action) => {
  switch (action.type) {
    case "FILL_MEMBER_FORM": {
      return { ...state, memberFormData: { ...state.memberFormData, [action.payload.name]: action.payload.value } };
    }
    case "FILL_MERCHANT_FORM": {
      return { ...state, merchantFormData: { ...state.merchantFormData, [action.payload.name]: action.payload.value } };
    }
    case "RESET_MEMBER_FORM": {
      return { ...state, memberFormData: initialVal.memberFormData };
    }
    case "RESET_MERCHANT_FORM": {
      return { ...state, merchantFormData: initialVal.merchantFormData };
    }

    default:
      return state;
  }
};

export const SignUpProvider = (props) => {
  const [singUpCtx, dispatchSignUp] = useReducer(reducer, initialVal);

  return <signUpContext.Provider value={[singUpCtx, dispatchSignUp]} {...props} />;
};

export const SingUpContext = () => {
  const context = useContext(signUpContext);
  if (!context) {
    throw new Error("Please use the context inside parent scope");
  }
  return context;
};
