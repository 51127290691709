import React from "react";
import { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { MERCHANT_SAVE_KEYWORDS } from "../../../config/endpoints";
import MySpinner from "../../common/MySpinner";
import { toast } from "react-toastify";
import MerchantContext from "../../../context/merchant-context";
import axios from "axios";
import { WithContext as ReactTags } from "react-tag-input";
import "./AddMerchantKeywordsStyle.css";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function AddMerchantKeywords({ keywordString, saveButton = true }) {
  const { authMerchant } = useContext(MerchantContext);
  const [loader, setLoader] = useState(false);
  const keywordStringArray = keywordString?.split(",");
  const [tags, setTags] = useState([]);
  const getTagsData = () => {
    if (keywordStringArray?.length > 0) {
      let tagsArray = [];
      keywordStringArray?.forEach((data) => {
        tagsArray.push({ id: data, text: data });
      });

      setTags(tagsArray);
    } else {
      setTags([]);
    }
  };
  useEffect(() => {
    getTagsData();
  }, [keywordString]);
  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const saveKeywords = (payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${authMerchant?.token}`,
        "content-type": "application/json",
      },
    };
    setLoader(true);
    axios
      .post(MERCHANT_SAVE_KEYWORDS, payload, config)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          let data = res.data;
          if (data.success) {
            toast.success(data.message);
          } else {
            toast.error(data.message);
          }
        } else {
          toast.error("something went wrong");
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.message);
      });
  };
  const loading = () => (
    <div
      className="d-flex justify-content-center align-items-center mb-5"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  const handleFormSubmit = (e) => {
    e.preventDefault();
    let tagsArray = [];
    if (tags?.length > 0) {
      tags.forEach((tag) => {
        tagsArray.push(tag.id);
      });
    }
    const tagsString = tagsArray.join(",");
    const payload = {
      keywordString: tagsString,
    };
    payload && saveKeywords(payload);
  };

  return (
    <Card body className="mt-2 shadow py-2">
      {loader && loading()}
      <form id="merchantKeywords" onSubmit={handleFormSubmit}>
        <Row className="g-2 justify-content-start align-items-center">
          <Col sm={12} md={6} lg={6}>
            <ReactTags
              inline
              tags={tags}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              // handleTagClick={handleTagClick}
              inputFieldPosition="bottom"
            />
          </Col>
          {saveButton && (
            <Col sm={12} md={6} lg={6}>
              <button
                disabled={loader}
                onClick={handleFormSubmit}
                type="submit"
                className="btn btn-success"
                form="merchantKeywords"
              >
                Save Keyword
              </button>
            </Col>
          )}
        </Row>
      </form>
    </Card>
  );
}

export default AddMerchantKeywords;
