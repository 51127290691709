import { Button, Card, Container } from "react-bootstrap";
import React, { useContext, useEffect } from "react";
import SeachbarAndPoolBarForSmallScreen from "./SeachbarAndPoolBarForSmallScreen";
import SeachbarAndPoolBarForBigScreen from "./SeachbarAndPoolBarForBigScreen";
import WebContext from "../../../context/web-context";
import { useLocation, useNavigate } from "react-router-dom";
import WeeklyGiveaway from "../../../assets/images/Gift_Card.jpeg";
import MonthlyGiveaway from "../../../assets/images/Debit_Card.png";
import TravelocityGiveaway from "../../../assets/images/travelocity.png";

const data = [
  {
    title: "Weekly Giveaway",
    description: "Every Friday, we randomly draw 2 Members to receive a $50 Visa® debit card.",
    image: WeeklyGiveaway,
  },
  {
    title: "Monthly Giveaway",
    description: "The last day of every month we randomly draw 1 Member to receive a $250 Visa® debit card.",
    image: MonthlyGiveaway,
  },
  {
    title: "Travelocity® Giveaway",
    description: "Every 3 months, we will randomly draw 1 Members name to receive a $2,500 credit for Travelocity®.",
    image: TravelocityGiveaway,
  },
];
function Giveaways() {
  const { windowDimensions } = useContext(WebContext);
  let navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // window.scroll(0, 0);
  }, [pathname]);
  return (
    <>
      <SeachbarAndPoolBarForSmallScreen />
      <SeachbarAndPoolBarForBigScreen />
      <div className={`pt-1 ${windowDimensions.width > 768 && "mt-5"}`}>
        <section className="my-5">
          <Container
            style={{ minHeight: 320 }}
            className="d-flex justify-content-center justify-content-lg-between flex-wrap"
          >
            {data.map((data) => (
              <Card className="mb-3 bg-white shadow" key={data.title} style={{ width: "22rem" }}>
                <Card.Img
                  className="p-3"
                  variant="top"
                  style={{ height: 240, objectFit: "cover" }}
                  src={data.image ? data.image : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"}
                />
                <Card.Body>
                  <Card.Title>{data.title}</Card.Title>
                  <Card.Text>{data.description}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </Container>
          <Container className="mt-5">
            As a Member, you are automatically entered into each and every drawing/giveaway we have. Your number of
            entries depends on your chosen subscription level and any other promotions you as an active Member have
            participated in. Basic Members receive 2 entries , Premium Members receive 10 entries into each
            drawing/giveaway.
          </Container>
          <Container className="mt-4">
            Don't forget that as an active Member, you get points for virtually everything you do while on our site.
            These points can be redeemed towards extra entries into some of our giveaways. The best part is that if you
            redeem points and do not win, you will get a percentage (based on your subscription level) of those points
            back for future use. Your points can also be used towards gift cards to many of your favorite retailers,
            restaurants, etc.
          </Container>
          <Container className="mt-3 d-flex justify-content-between flex-wrap">
            <div>
              <span className="text-success">Register Now</span> to be eligible for these great prizes, trips and more!
            </div>
            <div>
              <Button variant="success" onClick={() => navigate("/SignUp")}>
                Register Now
              </Button>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
}

export default Giveaways;
