import styles from "./Styles/Test.module.css";

export default function Test() {
  return (
    <>
      <div className={styles.emailOuter}>
        <div className={styles.emailInner}>
          <div className={styles.header}>
            <h1>Welcome To</h1>
            <p>
              Hi!
              <span style={{ paddingleft: "5px" }}>
                <b>Saif</b>
              </span>
            </p>
            <p>
              Your Lifetime FREE Premium account has successfully been created!
              Feel free to set your preferences so we can help you get the best
              deals around and see what makes us different. If you have any
              questions, please feel free to contact us by replying to this
              email or calling us at 920-903-1010.
            </p>
            <a target="_blank" href="${verifyLink}">
              Click Here to confirm Your Mail
            </a>
            <p style={{ marginBottom: "0px" }}>Thanks,</p>
            <h4 style={{ marginTop: "0px" }}>Eazyer.com</h4>
          </div>
          <div className={styles.giveaway}>
            <div className={styles.weekly}>
              <img src="https://eayer.s3.us-east-2.amazonaws.com/50+Gift+Card.jpeg" />
              <p>Weekly Giveaway</p>
            </div>
            <div className={styles.monthly}>
              <img src="https://eayer.s3.us-east-2.amazonaws.com/250+Debit+Card.png" />
              <p>Monthy Giveaway</p>
            </div>
            <div className={styles.travel}>
              <img src="https://eayer.s3.us-east-2.amazonaws.com/Travelocity+Logo.jpeg" />
              <p>Travelocity Giveaway</p>
            </div>
          </div>
          <div className={styles.content}>
            <p>
              You are receiving this email because you signed up as{" "}
              <a href="mailto:${to}">etst@gmail.com</a> to get news and offers
              from Eazyer.com (Rockin Rewards, LLC). It's a easy to select what
              communication you would like to recieve from us by updating your
              email preferences online or by calling us at 920-903-8030.
            </p>
            <p>
              Please add{" "}
              <a href="mailto:eazyersupport@eazyer.com">
                eazyersupport@eazyer.com
              </a>{" "}
              to your address book.
            </p>
            <p>2019 Eazyer.com/Rockin Rewards, LLC. All rights reserved.</p>
            <p>
              Eazyer.com trademark is a registered trademark of Rockin rewards,
              LLC. 101 W. College Ave. Suite 234 Appleton, WI 54915 920-903-8030
            </p>
            <p>
              <a href="${unsubscribeLink}">unsubscribe</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
