import {
  BASE_URL,
  CATEGORY_LIST_ADMIN,
  GET_USER_CONFIG,
  HOMESCREEN,
  MERCHANT_REVIEW,
  MERCHANT_SIGNUP,
  SEARCH_MERCHANT,
  STATE_LIST,
  TRIP_ADVISOR_DETAILS,
  USER_SIGNUP,
} from "../../config/endpoints";
import http from "../httpService";
import { handleFailure } from "../../utils/handleFailure";
import { get_access_token } from "../../utils/apiUtils";
import { GOOGLE_API_KEY } from "../../components/common/googlePlaceApiKey";

const commonService = {
  getHierarchyCategories: async () => {
    const url = BASE_URL + "category/hierarchy";
    try {
      const response = await http.post(url, null, get_access_token());
      return response.data.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  getHomeScreenData: async ({ latitude, longitude }) => {
    const url = HOMESCREEN;
    const params = {
      latitude: latitude || "",
      longitude: longitude || "",
    };
    try {
      const response = await http.post(url, params, null);
      return response.data.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  getCategories: async (params) => {
    const config = {
      headers: { Authorization: `Bearer sdfsdf234rwkjh@#$wesjfh98serbwsdr` },
    };
    try {
      const response = await http.post(SEARCH_MERCHANT, params, config);
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  memberSignUp: async (payload) => {
    try {
      const response = await http.post(USER_SIGNUP, payload, null);
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  merchantSignUp: async (payload) => {
    try {
      const response = await http.post(MERCHANT_SIGNUP, payload, null);
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  getAllStates: async (payload) => {
    try {
      const response = await http.get(STATE_LIST, null, null);
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  getConfig: async () => {
    try {
      const response = await http.post(GET_USER_CONFIG, null, null);
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  getMerchantReviewDetail: async (merchantId) => {
    const config = {
      headers: { Authorization: `Bearer sdfsdf234rwkjh@#$wesjfh98serbwsdr` },
    };
    try {
      const response = await http.post(TRIP_ADVISOR_DETAILS, { merchantId }, config);
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  getMerchantReview: async (merchantId) => {
    const config = {
      headers: { Authorization: `Bearer sdfsdf234rwkjh@#$wesjfh98serbwsdr` },
    };
    try {
      const response = await http.post(MERCHANT_REVIEW, { merchantId }, config);
      return response.data.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  searchLocation: async (keyword) => {
    const url = `https://maps.googleapis.com/maps/api/place/autocomplete/json`,
      params = {
        input: keyword,
        key: GOOGLE_API_KEY,
        components: "country:us",
        types: "(regions)",
      };
    try {
      const response = await http.post(url, params, { headers: {} });
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
};

export default commonService;
