import { useContext, useEffect, useRef, useState } from "react";
import { Badge, Button, Card, Col, Container, Form, Image, OverlayTrigger, Popover, Row } from "react-bootstrap";
import {
  ADD_CATEGORY_ADMIN,
  CATEGORY_LIST_ADMIN,
  CATGORY_ACTIVATE_DEACTIVATE,
  UPDATE_CATEGORY_ADMIN,
} from "../../../config/endpoints";
import myAxios from "../../../config/my-axios";
import MySpinner from "../../common/MySpinner";
import { toast } from "react-toastify";
import DashboardContainer from "../components/DashboardContainer";
import PageTitle from "../components/PageTitle";
import axios from "axios";
import AdminContext from "../../../context/admin-context";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useNavigate } from "react-router-dom";
import adminService from "../../../services/admin/adminServices";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const options = {
  onSizePerPageChange: (sizePerPage, page) => {},
  onPageChange: (page, sizePerPage) => {},
};

function AdminAddSubCategories() {
  const { authUser } = useContext(AdminContext);
  const categoryImageRef = useRef();
  const [parentListLoader, setParentListLoader] = useState(false);
  const [parentCategory, setParentCategory] = useState("");
  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [category, setCategory] = useState("");
  const [categoryImage, setCategoryImage] = useState(null);
  const [mode, setMode] = useState("add");
  const [imagePreview, setImagePreview] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const makeInactive = (e, id) => {
    e.preventDefault();
    myAxios
      .post(CATGORY_ACTIVATE_DEACTIVATE, { categoryId: id })
      .then((re) => {
        let res = re.data;
        if (res.success) {
          getCategoriesList();
          toast.success("details updated successfully");
        } else {
          toast.error(res.message);
        }
      })
      .catch((er) => {
        toast.error(er.message);
      });
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row, rowIndex, colIndex) => row?.rowIndex,
    },
    {
      dataField: "name",
      text: "Sub Category Name",
      formatter: (cell, row, rowIndex, colIndex) => <span>{`${row?.name}`}</span>,
      filter: textFilter({
        placeholder: "Search...",
      }),
    },
    {
      dataField: "parent.name",
      text: "Parent Category Name",
      formatter: (cell, row, rowIndex, colIndex) => <span>{`${row?.parent?.name}`}</span>,
      filter: textFilter({
        placeholder: "Search...",
      }),
    },
    // {
    //   dataField: "image",
    //   text: "Image",
    //   formatter: (cell, row, rowIndex, colIndex) => (
    //     <img src={row.image} style={{ height: 40, width: "auto" }} />
    //   ),
    // },
    {
      text: "Status",
      dataField: "status",
      formatter: (cell, row, rowIndex, colIndex) => (
        <span>
          <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose={true}
            overlay={
              <Popover>
                <Popover.Body>
                  <a href="#" onClickCapture={(e) => makeInactive(e, row?.id)}>
                    make {row?.status == "active" ? "inactive" : "active"}
                  </a>
                </Popover.Body>
              </Popover>
            }
          >
            <Badge className="hover-cursor" bg={row.status == "active" ? "success" : "danger"}>
              {row?.status}
            </Badge>
          </OverlayTrigger>
        </span>
      ),
      filter: textFilter({
        placeholder: "Search...",
      }),
    },
    {
      text: "Action",
      formatter: (cell, row, rowIndex, colIndex) => (
        <span
          style={{ cursor: "pointer" }}
          className="fas fa-pencil-alt"
          onClick={() => handleEditClick(row?.id, row?.name, row?.parent)}
        ></span>
      ),
    },
  ];

  const handleEditClick = (id, name, parent) => {
    setMode("edit");
    setCategoryId(id);
    setCategory(name);
    setParentCategory(parent?.id);
  };
  const noData = () => <Container className="d-flex justify-content-center">No data Found</Container>;
  const handleReset = () => {
    setMode("add");
    setLoader(false);
    setCategory("");
    setParentCategory("");
    categoryImageRef.current.value = "";
    setImagePreview(false);
    setCategoryImage(null);
  };
  const saveCategories = () => {
    setLoader(true);
    let params = {};
    params = {
      category_level: "level1",
      parent: parentCategory,
      name: category,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${authUser?.token}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(ADD_CATEGORY_ADMIN, params, config)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          if (data.success) {
            toast.success(data.message);
            getCategoriesList();
          } else {
            toast.error(data.message);
          }
          handleReset();
        } else {
          toast.error(res);
          handleReset();
        }
      })
      .catch((err) => {
        // toast.error("Something went wrong");
        handleReset();
      });
  };
  const updateCategories = () => {
    setLoader(true);
    let params = {};
    params = {
      parent: parentCategory,
      name: category,
      id: categoryId,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${authUser?.token}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(UPDATE_CATEGORY_ADMIN, params, config)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          if (data.success) {
            toast.success(data.message);
            getCategoriesList();
          } else {
            toast.error(data.message);
          }
          handleReset();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err);
        handleReset();
      });
  };
  const loading = () => (
    <div
      className="d-flex justify-content-center align-items-center mb-5"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  const handleInputChange = (e) => {
    setCategory(e.target.value.replace(/[^a-zA-Z0-9-\-\ \']/g, ""));
  };
  const handleParentCategoryChange = (e) => {
    setParentCategory(e.target.value);
  };
  const handleCategoryImageChange = (e) => {
    e.preventDefault();
    setImagePreview(false);
    let files = e.target.files[0];
    if (files) {
      setCategoryImage(files);
    }
  };

  const categoryFormSection = () => (
    <form onSubmit={handleFormSubmit}>
      <Row className="g-2 justify-content-center align-items-center">
        <Col sm={12} md={4} lg={4}>
          <Form.Group className="mb-4">
            <Form.Select
              name="parent"
              onChange={handleParentCategoryChange}
              value={parentCategory}
              disabled={mode === "edit"}
              required
            >
              <option value="">-- Select parent category --</option>
              {parentCategoryList.map((el) => {
                return (
                  <option value={el?.id} key={el?.id}>
                    {el?.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4}>
          <Form.Group className="mb-4">
            <input
              type="text"
              className="form-control"
              placeholder="Category Name"
              onChange={handleInputChange}
              value={category}
              required
            />
          </Form.Group>
        </Col>

        <Col sm={12} md={4} lg={4}>
          <Form.Group className="mb-4">
            <button disabled={loader} type="submit" className="btn btn-success">
              {mode === "edit" ? "Update Category" : "Add Category"}
            </button>
            {mode === "edit" && (
              <button disabled={loader} className="ms-4 btn btn-secondary" onClick={handleReset}>
                Cancel
              </button>
            )}
          </Form.Group>
        </Col>
      </Row>
    </form>
  );
  const categoryListSection = () => {
    return categoryList.length > 0 ? (
      <BootstrapTable
        keyField="_id"
        data={categoryList}
        columns={columns}
        pagination={paginationFactory({ page: currentPageNumber, onPageChange: setCurrentPageNumber })}
        striped
        bordered={false}
        wrapperClasses="table-responsive"
        filter={filterFactory()}
        classes="customBootStrapClasses"
      />
    ) : (
      noData()
    );
  };

  const getCategoriesList = async () => {
    setListLoader(true);
    // myAxios
    //   .post(CATEGORY_LIST_ADMIN, {
    //     sort: { desc: true },
    //     filter: {
    //       level: 1,
    //     },
    //   })
    //   .then((res) => {
    //     setListLoader(false);
    //     if (res.status === 200) {
    //       let data = res.data;
    //       if (data.success) {
    //         setCategoryList(data.data);
    //       } else {
    //         toast.error(data.message);
    //       }
    //     } else {
    //       toast.error(res.data.message);
    //     }
    //   })
    //   .catch((err) => {
    //     toast.error("Something went wrong");
    //     setListLoader(false);
    //   });
    const data = await adminService.getCategories(1);
    if (data.success) {
      setCategoryList(data.data.map((item, index) => ({ ...item, rowIndex: index + 1 })));
    } else {
      toast.error(data.message);
    }
    setListLoader(false);
  };
  const getParentCategoriesList = () => {
    setParentListLoader(true);
    myAxios
      .post(CATEGORY_LIST_ADMIN, {
        sort: { desc: true },
        filter: {
          level: 0,
          active: true,
        },
      })
      .then((res) => {
        setParentListLoader(false);
        if (res.status === 200) {
          let data = res.data;
          if (data.success) {
            setParentCategoryList(data.data);
          } else {
            toast.error(data.message);
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setParentListLoader(false);
      });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (mode === "add") {
      category && parentCategory && saveCategories();
    } else if (mode === "edit") {
      category && parentCategory && updateCategories();
    }
  };
  useEffect(() => {
    getCategoriesList();
    getParentCategoriesList();
    return setCategoryList([]);
  }, []);

  return (
    <DashboardContainer>
      <PageTitle title={"Sub Categories"} />
      <Card body className="shadow py-2">
        {loader && loading()}
        {categoryFormSection()}
      </Card>
      <Card body className="shadow py-2 mt-4">
        {listLoader ? loading() : categoryListSection()}
      </Card>
    </DashboardContainer>
  );
}

export default AdminAddSubCategories;
