/* global google */
import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  MERCHANT_ACTIVATE,
  MERCHANT_APPROVE,
  MERCHANT_DETAIL,
  MERCHANT_SAVE_KEYWORDS,
  MERCHANT_CHANGE_PASSWORD_ADMIN,
  MERCHANT_SAVE_LOCATION_ADMIN,
} from "../../../config/endpoints";
import myAxios from "../../../config/my-axios";
import MySpinner from "../../common/MySpinner";
import AddMerchantKeywords from "./AddMerchantKeywords";
import styles from "../../web/Pages/ForgotPassword.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import DashboardContainer from "../components/DashboardContainer";
import PageTitle from "../components/PageTitle";
import { loadGMaps } from "../../../config/helper";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const PWD_DIGIT_REGEX = /(?=.*\d)/; //should contain atleast 1 digit
const PWD_LOWER_REGEX = /(?=.*[a-z])/; //should contain atleast 1 lowercase
const PWD_UPPER_REGEX = /(?=.*[A-Z])/; //should contain atleast 1 uppercase
const PWD_SPCHAR_REGEX = /[$@$!%*?&]/; //should contain atleast 1 Special Character

const timings = [
  {
    id: 0,
    day: "Sunday",
  },
  {
    id: 1,
    day: "Monday",
  },
  {
    id: 2,
    day: "Tuesday",
  },
  {
    id: 3,
    day: "Wednesday",
  },
  {
    id: 4,
    day: "Thursday",
  },
  {
    id: 5,
    day: "Friday",
  },
  {
    id: 6,
    day: "Saturday ",
  },
];

export default function MerchantDetail() {
  let initObj = {
    newPassword: "",
    confirmPassword: "",
  };

  const addressRef = useRef();
  const [merchant, setMerchant] = useState(null);
  const [locationLoader, setLocationLoader] = useState(false);
  const [locationAddress, setLocationAddress] = useState({
    formatted_address: "",
    lat: "",
    lng: "",
  });
  const [form, setForm] = useState(initObj);
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [chkDigit, setChkDigit] = useState(false);
  const [chkLower, setChkLower] = useState(false);
  const [chkUpper, setChkUpper] = useState(false);
  const [chkSpecialChar, setChkSpecialChar] = useState(false);
  const [chkChar, setChkChar] = useState(false);
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const [load, setLoad] = useState(false);
  const handlePwdVal = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const loader = () => (
    <div className="d-flex justify-content-center align-items-center text-success">
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  function approveMerchant() {
    myAxios
      .post(MERCHANT_APPROVE, { merchantId: id })
      .then((r) => {
        let res = r.data;
        if (res.success) {
          toast.success("Merchant approved successfully");
          setMerchant(res.data);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  function resetPassword() {
    const v1 = PWD_REGEX.test(form.newPassword);
    const v2 = PWD_REGEX.test(form.confirmPassword);
    if (!v1 || !v2) {
      toast.error("Please enter your new password");
      return;
    }
    const params = {
      password: form?.newPassword,
      confirm: form?.confirmPassword,
      merchantId: merchant?.id,
    };
    setLoad(true);
    let url = MERCHANT_CHANGE_PASSWORD_ADMIN;
    myAxios
      .post(url, params)
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          setForm(initObj);
          setLoad(false);
        } else {
          toast.error(res.data.message);
          setLoad(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoad(false);
      });
  }

  const submitNewPassWord = () =>
    load ? (
      loader()
    ) : (
      <>
        <div className="row gy-4 pt-3 px-4">
          <div className="col-12">
            <input
              autoComplete="off"
              type="password"
              name="newPassword"
              value={form.newPassword}
              onChange={handlePwdVal}
              className="form-control"
              placeholder="New Password*"
              aria-invalid={validPwd ? "false" : "true"}
              aria-describedby="pwdnote"
              onFocus={() => setPwdFocus(true)}
              onBlur={() => setPwdFocus(false)}
            />
          </div>
        </div>
        <div className="row gy-4 pt-3 px-4">
          <div className="col-12">
            <input
              autoComplete="off"
              type="password"
              name="confirmPassword"
              value={form.confirmPassword}
              onChange={handlePwdVal}
              className="form-control"
              placeholder="Confirm Password*"
              aria-invalid={validPwd ? "false" : "true"}
              aria-describedby="cnfpwdnote"
              onFocus={() => setMatchFocus(true)}
              onBlur={() => setMatchFocus(false)}
            />
          </div>
        </div>
        <div className="row gy-4 py-4 px-4 text-center">
          <div id="pwdnote" className={`row ${pwdFocus && !validPwd ? styles.instructions : styles.offscreen}`}>
            <div className="row">
              <div className="col-12 col-md-12">
                Must include Uppercase letters.{" "}
                {chkUpper ? (
                  <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                ) : (
                  <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                )}
              </div>
              <div className="col-12 col-md-12">
                Must include lowercase letters.{" "}
                {chkLower ? (
                  <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                ) : (
                  <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                At Least 1 number.{" "}
                {chkDigit ? (
                  <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                ) : (
                  <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                )}
              </div>
              <div className="col-12 col-md-12">
                Allowed special characters: <span aria-label="exclamation mark">!</span>{" "}
                <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span>{" "}
                <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>{" "}
                {chkSpecialChar ? (
                  <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                ) : (
                  <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                Minimum 8 characters.{" "}
                {chkChar ? (
                  <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                ) : (
                  <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                )}
              </div>
              <div className={`col-12 col-md-12 ${validMatch ? "" : styles.offscreen}`}>
                Password Match.{" "}
                {validMatch ? (
                  <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                ) : (
                  <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                )}
              </div>
            </div>
          </div>
          <div id="cnfpwdnote" className={`row ${matchFocus && !validMatch ? styles.instructions : styles.offscreen}`}>
            <div className="row">
              <div className="col-12 col-md-12">
                Must match the first password input field.{" "}
                {validMatch ? (
                  <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                ) : (
                  <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                )}
              </div>
            </div>
          </div>
          <div className="col-12 text-center">
            <button type="button" onClickCapture={resetPassword} className="register-now btn btn-success">
              Reset Password
            </button>
          </div>
        </div>
      </>
    );

  function saveMerchantLocation(e) {
    e.preventDefault();
    const params = {
      latitude: locationAddress?.lat,
      longitude: locationAddress?.lng,
      address: locationAddress?.formatted_address,
      merchantId: merchant?.id,
    };
    setLocationLoader(true);
    let url = MERCHANT_SAVE_LOCATION_ADMIN;
    myAxios
      .post(url, params)
      .then((res) => {
        if (res.data.success) {
          toast.success("Location added successfully");
          setLocationLoader(false);
        } else {
          toast.error(res.data.message);
          setLocationLoader(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLocationLoader(false);
      });
  }

  const merchantLocation = () =>
    locationLoader ? (
      loader()
    ) : (
      <>
        <div className="row gy-4 pt-3 px-4">
          <div className="col-12">
            <label className="text-muted">location Address</label>
            <input
              defaultValue={locationAddress?.formatted_address || ""}
              size="sm"
              name="locationAddress"
              className="form-control form-control-sm"
              id="locationAddress"
              ref={addressRef}
              required
            />
            {
              <span className="text-info">
                {locationAddress?.lat} , {locationAddress?.lng}
              </span>
            }
          </div>
        </div>

        <div className="row gy-4 py-4 px-4 text-center">
          <div className="col-12 text-center">
            <button
              type="button"
              onClickCapture={(e) => saveMerchantLocation(e)}
              className="register-now btn btn-success"
            >
              Save Location
            </button>
          </div>
        </div>
      </>
    );

  const workingDay = (day) => {
    return timings.map((val) => {
      return val.id === day && val.day;
    });
  };

  const workingHours = () => {
    if (merchant?.workingHours?.length > 0) {
      return merchant?.workingHours?.map((hour) => {
        return (
          <div style={{ display: "flex" }} key={hour?.day}>
            <div style={{ width: "20%" }}>{workingDay(hour?.day)}:</div>
            <span style={{ whiteSpace: "pre-line" }}>
              <strong>{hour?.hours}</strong>
            </span>
          </div>
        );
      });
    }
  };

  const getMerchantDetails = () => {
    myAxios
      .post(MERCHANT_DETAIL, { id })
      .then((r) => {
        let res = r.data;
        if (res.success) {
          setMerchant(res.data);
        }
      })
      .catch((err) => {});
  };

  function initMaps() {
    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
      types: ["establishment"],
    };

    const autocomplete = new google.maps.places.Autocomplete(addressRef.current, options);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      setLocationAddress({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        formatted_address: place.formatted_address,
      });
    });
  }

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(form.newPassword));
    setChkDigit(PWD_DIGIT_REGEX.test(form.newPassword));
    setChkLower(PWD_LOWER_REGEX.test(form.newPassword));
    setChkUpper(PWD_UPPER_REGEX.test(form.newPassword));
    setChkSpecialChar(PWD_SPCHAR_REGEX.test(form.newPassword));
    form.newPassword.length > 8 ? setChkChar(true) : setChkChar(false);
  }, [form.newPassword]);
  useEffect(() => {
    setValidMatch(form.newPassword === form.confirmPassword);
  }, [form.confirmPassword]);

  useEffect(() => {
    if (
      merchant &&
      merchant.location &&
      Array.isArray(merchant.location.coordinates) &&
      merchant.location.coordinates.length
    ) {
      setLocationAddress({
        formatted_address: merchant?.locationAddress,
        lat: merchant?.location?.coordinates[1],
        lng: merchant?.location?.coordinates[0],
      });
    }
  }, [merchant]);

  useEffect(() => {
    getMerchantDetails();

    loadGMaps(() => {
      initMaps();
    });
  }, []);

  return (
    <DashboardContainer>
      <PageTitle
        title={"Merchant detail"}
        rightSide={
          <Link
            to={"/admin/merchants/add/" + merchant?.id}
            className="btn btn-success btn-sm"
            style={{ float: "right" }}
          >
            Edit
          </Link>
        }
      />
      {merchant && (
        <>
          <Row>
            <Col md="6">
              <Card body>
                <p>
                  Name: <strong className="ms-3">{merchant?.name}</strong>
                </p>
                <p>
                  Business name:
                  <strong className="ms-3">{merchant?.businessName || "NA"}</strong>
                </p>
                <p>
                  Description:
                  <strong className="ms-3">{merchant?.description || "NA"}</strong>
                </p>
                <p>
                  Email:
                  <strong className="ms-3">
                    {merchant?.email} |{" "}
                    <span className={`${merchant?.emailVerified ? "text-success" : "text-warning"}`}>
                      {merchant?.emailVerified ? "Verified" : "Not Verified"}
                    </span>
                  </strong>
                </p>
                <p>
                  Phone: <strong className="ms-3">{merchant?.phone || "NA"}</strong>
                </p>
                {merchant?.category && (
                  <p>
                    Category:
                    <strong className="ms-3">{merchant?.category?.name}</strong>
                  </p>
                )}
                {merchant?.childCategory && (
                  <p>
                    subCategory:
                    <strong className="ms-3">{merchant?.childCategory?.name}</strong>
                  </p>
                )}
                {!!merchant?.categories.length && (
                  <p>
                    sub SubCategory:
                    <strong className="ms-3">{merchant?.categories.map((category) => category.name)}</strong>
                  </p>
                )}

                <p>
                  Address: <strong className="ms-3">{merchant?.address || "NA"}</strong>
                </p>

                <p>
                  zipcode: <strong className="ms-3">{merchant?.zipcode || "NA"}</strong>
                </p>

                <p>
                  state: <strong className="ms-3">{merchant?.state || "NA"}</strong>
                </p>
                <p>
                  subscription:
                  <strong className="ms-3">{merchant?.subscription?.name}</strong>
                </p>

                <p>
                  website:
                  <strong className="ms-3">
                    {merchant?.website.replace("https://", "").replace("http://", "") || "NA"}
                  </strong>
                </p>
                <p>
                  signupDate:
                  <strong className="ms-3">{merchant?.signupDate}</strong>
                </p>

                <p>
                  Account status:
                  <strong className={`ms-3 ${merchant.accountStatus == "approved" ? "text-success" : "text-warning"}`}>
                    {merchant?.accountStatus}
                  </strong>
                </p>

                <div>
                  {merchant.accountStatus != "approved" && (
                    <Button type="button" size="sm" onClickCapture={approveMerchant}>
                      approve merchant
                    </Button>
                  )}
                </div>
              </Card>
            </Col>

            <Col>
              <Card body>{workingHours()}</Card>
              <Card body className="mt-2">
                {merchantLocation()}
              </Card>
              {/* <AddMerchantKeywords
                customStyle={true}
                getMerchantDetails={getMerchantDetails}
                merchantId={merchant?.id}
                keywordString={merchant?.keywordString}
              /> */}
            </Col>
          </Row>

          <Row className="mt-2">
            <Col col={6}>
              <AddMerchantKeywords
                getMerchantDetails={getMerchantDetails}
                merchantId={merchant?.id}
                keywordString={merchant?.keywordString}
              />
            </Col>
            <Col col={6}>
              <Card body className="mt-2">
                {submitNewPassWord()}
              </Card>
            </Col>
          </Row>
        </>
      )}
    </DashboardContainer>
  );
}
