import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Card, Container } from "react-bootstrap";
import { UPDATE_USER_STATUS, USER_LIST } from "../../../config/endpoints";
import useTable from "../../../hooks/useTable";
import axios from "axios";
import DashboardContainer from "../components/DashboardContainer";
import PageTitle from "../components/PageTitle";
import { useEffect, useState } from "react";
import MySpinner from "../../common/MySpinner";
import myAxios from "../../../config/my-axios";
import "./UserList.css";
import { Icon } from "@mui/material";
import { Checkbox, Switch, Popconfirm } from "antd";
import { toast } from "react-toastify";
import adminService from "../../../services/admin/adminServices";
import { CheckCircleTwoTone } from "@ant-design/icons";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

function UserList() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const options = {
    onSizePerPageChange: (sizePerPage, page) => {},
    onPageChange: setCurrentPageNumber,
    page: currentPageNumber,
  };
  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setLoading(true);
    const data = await adminService.getUsers();
    if (data.success) {
      setUsers(data.data.map((item, index) => ({ ...item, rowIndex: index + 1 })));
    } else {
      toast.error(data.message);
    }
    setLoading(false);
  };

  const updateStatus = async (userId) => {
    setToggleLoading(true);
    const data = await adminService.updateUserStatus(userId);
    if (data.success) {
      toast.success(`User status successfully changed`);
      getUsers();
    } else {
      toast.error(data.message);
    }
    setToggleLoading(false);
  };

  const handleUserVerified = async (userId) => {
    const data = await adminService.userVerified(userId);
    if (data.success) {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
    getUsers();
  };
  const columns = [
    {
      dataField: "id",
      text: "#",
      // formatter: (cell, row, rowIndex, colIndex) => `${rowIndex}`,
      formatter: (cell, row, rowIndex, colIndex) => row.rowIndex,
    },
    {
      dataField: "firstName",
      text: "FirstName",
      filter: textFilter({
        placeholder: "Search...",
      }),
    },
    {
      dataField: "lastName",
      text: "LastName",
      filter: textFilter({
        placeholder: "Search...",
      }),
    },
    {
      dataField: "email",
      text: "Email",
      filter: textFilter({
        placeholder: "Search...",
      }),
    },
    {
      dataField: "city",
      text: "City",
      filter: textFilter({
        placeholder: "Search...",
      }),
    },
    {
      dataField: "state",
      text: "State",
      filter: textFilter({
        placeholder: "Search...",
      }),
    },
    {
      dataField: "zipcode",
      text: "Zipcode",
      filter: textFilter({
        placeholder: "Search...",
      }),
    },
    {
      dataField: "status",
      text: "Status",
      filter: textFilter({
        placeholder: "Search...",
      }),
    },
    {
      dataField: "signupDate",
      text: "Signup Date",
      filter: textFilter({
        placeholder: "Search...",
      }),
    },
    {
      dataField: "accountVerified",
      text: "AccountVerified",
      formatter: (cell, row, rowIndex, colIndex) =>
        row.accountVerified ? (
          <CheckCircleTwoTone size={10} twoToneColor="#52c41a" />
        ) : (
          <Popconfirm
            title={`Are you sure to verified the ${row.firstName}?`}
            onConfirm={() => {
              handleUserVerified(row?.id);
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">Click</a>
          </Popconfirm>
        ),
      filter: textFilter({
        placeholder: "true/false",
      }),
    },
    {
      dataField: "status",
      text: "Action",
      formatter: (cell, row, rowIndex, colIndex) => (
        <Switch loading={toggleLoading} checked={row?.status === "active"} onChange={() => updateStatus(cell)} />
      ),
      filter: textFilter({
        placeholder: "active/inactive",
      }),
    },
  ];
  const content = () =>
    users.length > 0 ? (
      <Card body className="shadow p-2">
        <BootstrapTable
          keyField="id"
          data={users}
          columns={columns}
          pagination={paginationFactory(options)}
          callBack={() => {}}
          striped
          bordered={false}
          wrapperClasses="table-responsive"
          filter={filterFactory()}
          classes="customBootStrapClasses"
        />
      </Card>
    ) : (
      noData()
    );
  const loader = () => (
    <div
      className="d-flex justify-content-center align-items-center text-success"
      style={{ height: "70vh", width: "auto" }}
    >
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  const noData = () => <Container className="d-flex justify-content-center">No data Found</Container>;

  return (
    <DashboardContainer>
      <PageTitle title={"User List"} />
      {loading ? loader() : content()}
    </DashboardContainer>
  );
}

export default UserList;
