import { Container } from "react-bootstrap";
import { CONTACT_QUERY, CONTACT_QUERY_LIST, USER_LIST } from "../../../config/endpoints";
import useTable from "../../../hooks/useTable";

function ContactQuery() {
  const [Tbl, data] = useTable(CONTACT_QUERY_LIST);

  return (
    <>
      <h4>Contact queries</h4>
      <hr />
      <Tbl>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Subject</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, i) => (
              <tr key={i}>
                <td>{i}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.subject}</td>
                <td>{item.message}</td>
                <td>
                  {/* <Link to={"/admin/user/add/" + item.id } ><span className="fas fa-pencil-alt"></span></Link> */}
                </td>
              </tr>
            ))}
        </tbody>
      </Tbl>
    </>
  );
}

export default ContactQuery;
