import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import WebContext from "../../../context/web-context";
import { LOGGED_USER } from "../../../config/constants";

function LogoutUser() {
  const { authUser, setAuthUser } = useContext(WebContext);

  if (!authUser) {
    return <Navigate to="/" replace />;
  }

  localStorage.removeItem(LOGGED_USER);
  localStorage.removeItem("params");
  sessionStorage.removeItem(LOGGED_USER);
  setAuthUser(null);
  toast.info("Logged out successfully");
  return <Navigate to="/" replace />;
}

export default LogoutUser;
