import { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import myAxios from "../../../config/my-axios";
import MySpinner from "../../common/MySpinner";
import { toast } from "react-toastify";
import MerchantDashboardContainer from "../components/MerchantDashboardContainer";
import MerchantPageTitle from "../components/MerchantPageTitle";
import { MERCHANT_DETAIL, STATE_LIST, UPDATE_MERCHANT } from "../../../config/endpoints";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ImageCropper from "../../common/ImageCropper";
import "cropperjs/dist/cropper.css";
import MerchantContext from "../../../context/merchant-context";
import AddMerchantKeywords from "./AddMerchantKeywords";
import MerchantWorkingHours from "./MerchantWorkingHours";

function MerchantProfile() {
  const { authMerchant } = useContext(MerchantContext);
  const [mode, setMode] = useState("add");

  let initObj = {
    name: "",
    // businessName: "",
    // description: "",
    phone: "",
    email: "",
    address2: "",
    zipcode: "",
    city: "",
    state: "",
    logo: null,
    logoUrl: "",
    finalImage: null,
    finalImageUrl: "",
  };
  // const { id } = useParams();
  const id = authMerchant?.merchant?._id;
  const navigate = useNavigate();
  const [form, setForm] = useState(initObj);
  const [error, setError] = useState({ ...initObj });
  const [load, setLoad] = useState(true);
  const [modal, showModal] = useState(false);

  const [data, setData] = useState({
    states: [],
  });

  const loading = () => (
    <div
      className="d-flex justify-content-center align-items-center mb-5"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  function handleForm(event) {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  }

  function handleZip(e) {
    if (isNaN(e.target.value) || e.target.value.length > 8) return;
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }

  function handlePhone(e) {
    let value = e.target.value;
    if (isNaN(value) || value.length > 14) return;
    setForm({
      ...form,
      [e.target.name]: value,
    });
  }

  function submitForm(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${authMerchant?.token}`,
        "content-type": "multipart/form-data",
      },
    };
    let formData = new FormData();
    formData.append("name", form.name);
    // formData.append("description", form.description);
    // formData.append("businessName", form.businessName);
    formData.append("address2", form.address2);
    formData.append("city", form.city);
    formData.append("zipcode", form.zipcode);
    formData.append("state", form.state);
    formData.append("phone", form.phone);
    formData.append("email", form.email);
    if (id) formData.append("id", id);
    // if (form.logo) {
    //   // formData.append("logo", form.logo, form.logo.name);
    //   formData.append("logo", form.finalImage, "logo.png");
    // }

    setLoad(true);
    let url = id && UPDATE_MERCHANT;
    axios
      .post(url, formData, config)
      .then((res) => {
        let data = res.data;
        //
        if (data.success) {
          toast.success("Updateded successfully");
          if (!id) {
            // navigate("/admin/merchants", { replace: true });
          } else {
            setError({ ...initObj });
            setLoad(false);
          }
        } else {
          setError({
            ...initObj,
            ...data.data,
          });
          toast.error(data.message);
          setLoad(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoad(false);
      });
  }

  useEffect(() => {
    axios
      .get(STATE_LIST)
      .then((res) => {
        if (res.data.success) {
          setData({
            ...data,
            states: res.data.data.states,
          });
          loadMerchant();
        } else {
          toast.error(res.data.message);
          setLoad(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoad(false);
      });
  }, []);
  function loadMerchant() {
    if (id) {
      myAxios
        .post(MERCHANT_DETAIL, { id })
        .then((res) => {
          let data = res.data;

          if (data.success) {
            let merchant = data.data;
            setForm({
              ...merchant,
              logo: null,
              logoUrl: "",
              finalImageUrl: merchant.logo,
            });
            setLoad(false);
          } else {
            toast.error(data.message);
            setLoad(false);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setLoad(false);
        });
    } else {
      setLoad(false);
    }
  }
  function handleCroppedImage(croppedImage) {
    setForm({
      ...form,
      finalImage: croppedImage,
      finalImageUrl: URL.createObjectURL(croppedImage),
    });
    showModal(false);
  }

  return (
    <MerchantDashboardContainer>
      <MerchantPageTitle
        title={"My Profile"}
        rightSide={
          <Button
            type="submit"
            form="merchantProfileForm"
            variant="secondary"
            className="btn btn-success "
            style={{ float: "right" }}
          >
            Update
          </Button>
        }
      />
      <Card body className="shadow py-2">
        {load ? (
          loading()
        ) : (
          <Form onSubmit={submitForm} id="merchantProfileForm">
            <Row>
              <Col md="6" xl="4">
                <Card bg="light" className="mb-4">
                  <Card.Body>
                    <Form.Group className="mb-3">
                      <label className="text-muted">Contact Name</label>
                      <Form.Control size="sm" name="name" value={form.name} onChange={handleForm} />
                      <label className="text-error">{error.name}</label>
                    </Form.Group>

                    {/* <Form.Group className="mb-3">
                      <label className="text-muted">Description</label>
                      <Form.Control
                        as="textarea"
                        rows="2"
                        size="sm"
                        name="description"
                        value={form.description}
                        onChange={handleForm}
                      />
                      <label className="text-error">{error.description}</label>
                    </Form.Group> */}

                    <Form.Group className="mb-3">
                      <label className="text-muted">Phone</label>
                      <InputGroup className="mb-3">
                        {/* <InputGroup.Text id="basic-addon1">+1</InputGroup.Text> */}
                        <Form.Control size="sm" type="number" name="phone" value={form.phone} onChange={handlePhone} />
                      </InputGroup>

                      <label className="text-error">{error.phone}</label>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <label className="text-muted">Email Address</label>
                      <Form.Control size="sm" type="email" name="email" value={form.email} onChange={handleForm} />
                      <label className="text-error">{error.email}</label>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </Col>

              <Col md="6" xl="4">
                <Card bg="light" className="mb-4">
                  <Card.Body>
                    <Form.Group className="mb-3">
                      <label className="text-muted">Address line 1</label>
                      <Form.Control
                        as="textarea"
                        rows="2"
                        size="sm"
                        name="address2"
                        value={form.address}
                        onChange={handleForm}
                      />
                      <label className="text-error">{error.address}</label>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <label className="text-muted">Address line 2</label>
                      <Form.Control
                        as="textarea"
                        rows="2"
                        size="sm"
                        name="address2"
                        value={form.address2}
                        onChange={handleForm}
                      />
                      <label className="text-error">{error.address2}</label>
                    </Form.Group>
                    {/* <Form.Group className="mb-3">
                      <label className="text-muted">Address line</label>
                      <Form.Control
                        size="sm"
                        name="address"
                        value={form.address}
                        onChange={handleForm}
                      />
                      <label className="text-error">{error.address}</label>
                    </Form.Group> */}

                    <Form.Group className="mb-3">
                      <label className="text-muted">Zip code</label>
                      <Form.Control size="sm" name="zipcode" value={form.zipcode} onChange={handleZip} />
                      <label className="text-error">{error.zipcode}</label>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <label className="text-muted">City</label>
                      <Form.Control size="sm" name="city" value={form.city} onChange={handleForm} />
                      <label className="text-error">{error.city}</label>
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <label className="text-muted">State</label>
                      <Form.Select size="sm" name="state" value={form.state} onChange={handleForm}>
                        <option value="">-- select state--</option>
                        {data.states.map((state, i) => {
                          return (
                            <option key={i} value={state.id}>
                              {state.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <label className="text-error">{error.state}</label>
                    </Form.Group>

                    {/* <Form.Group className="">
                      <label className="text-muted">
                        Featured / Logo image
                      </label>
                      <Form.Control
                        type="file"
                        size="sm"
                        name="logo"
                        onChange={handleImage}
                      />
                      <Form.Text className="text-danger">
                        Please upload image of resolution (170 x 120)
                      </Form.Text>
                      <label className="text-error">{error.logo}</label>
                    </Form.Group> */}
                  </Card.Body>
                </Card>
              </Col>

              {/* <Col md="6" xl="4">
                <div className="text-center">
                  <p>Final image preview</p>
                  <img
                    src={form.finalImageUrl ? form.finalImageUrl : ""}
                    alt="final image"
                    style={{ width: "80%", height: "auto" }}
                  />
                </div>
              </Col> */}

              <Col md="12" xl="12">
                {form.logoUrl && (
                  <>
                    <ImageCropper
                      image={form.logoUrl}
                      width={170}
                      height={120}
                      onCrop={handleCroppedImage}
                      modal={modal}
                      showModal={showModal}
                    />
                  </>
                )}
              </Col>
              {/* <Col sm="12">
                <Form.Group>
                  <Button type="submit" className="px-3">
                    Update
                  </Button>
                </Form.Group>
              </Col> */}
            </Row>
          </Form>
        )}
      </Card>
    </MerchantDashboardContainer>
  );
}

export default MerchantProfile;
