import { React, useContext, useEffect, useState } from "react";
import { Button, Card, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./DealsListCard.module.css";
import { Rating } from "react-simple-star-rating";
import WebContext from "../../../context/web-context";

function formateNumber(number) {
  let f_val = number.replace(/\D[^\.]/g, "");
  let formatted =
    f_val.slice(0, 3) + "-" + f_val.slice(3, 6) + "-" + f_val.slice(6);
  return formatted;
}

function DealsListCardForSmallScreen({ deals, image }) {
  const { windowDimensions } = useContext(WebContext);
  const [showShadow, setShowShadow] = useState(false);
  return (
    <Card
      className={`py-4 my-4 bg-white border-0 merchantsListCard ${
        showShadow && "shadow"
      } `}
      style={{
        height: windowDimensions.width <= 768 ? null : 200,
        transition: ".2s ease",
      }}
      onMouseOver={() => setShowShadow(true)}
      onMouseOut={() => setShowShadow(false)}
    >
      <section className="contact-sec container bg-transparent">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-3 ">
                  <div className="row justify-content-center">
                    <div className="col-10 p-1 mb-1">
                      <div
                        className="
                      p-1 text-center"
                      >
                        <Link to="/Deals">
                          <img
                            style={{
                              width: "67",
                              minHeight: 48,
                              maxHeight: 48,
                              objectFit: "contain",
                            }}
                            src={
                              image
                                ? image
                                : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"
                            }
                            alt="Card image cap"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-5">
                  <div className="row">
                    <div className="col-12 fw-bold" style={{ fontSize: 12 }}>
                      {deals?.heading}
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-12 text-secondary fs-6"
                      style={{ fontSize: 11 }}
                    >
                      {deals?.address}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <a
                        className="text-secondary  fs-6"
                        href={`tel:${deals.phone}`}
                        style={{ textDecoration: "none", fontSize: 11 }}
                      >
                        {formateNumber(deals?.phone)}
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-2">
                      <Rating
                        fillColor={"#FBFF2D"}
                        readonly
                        initialValue={deals?.ratings}
                        size={20}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`col-4 d-flex flex-column justify-content-end align-itens-end ${
                    windowDimensions.width <= 768 &&
                    windowDimensions.width > 512 &&
                    "px-4"
                  }`}
                >
                  <Button size="sm" variant="success" style={{ fontSize: 12 }}>
                    More Info
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Card>
  );
}

export default DealsListCardForSmallScreen;
