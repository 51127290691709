import { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { Cropper } from "react-cropper";

function ImageCropper({ image, width, height, onCrop, showModal, modal, size }) {
  const cropperRef = useRef(null);

  const onCropLocal = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    cropper.getCroppedCanvas().toBlob((blob) => {
      onCrop(blob);
    });

    // let finalImage = cropper.getCroppedCanvas().toDataURL()
    // onCrop(finalImage)
  };
  return (
    <Modal show={modal} backdrop="static" onHide={() => showModal(false)} keyboard={false} size={size || "md"}>
      <Modal.Header closeButton>
        <Modal.Title>Image cropper</Modal.Title>
      </Modal.Header>
      <p
        style={{
          margin: 0,
          padding: 0,
          fontSize: 15,
          fontWeight: 500,
          textAlign: "center",
        }}
      >
        We can upload with max {width} width and {height} height.
      </p>
      <Modal.Body>
        <div className="d-flex flex-column justify-content-center align-items-center mt-4">
          <Cropper
            src={image}
            style={{ height: 400, width: "100%" }}
            // Cropper.js options
            initialAspectRatio={width / height}
            guides={false}
            dragMode="move"
            ref={cropperRef}
            cropBoxResizable={false}
            background={false}
            responsive={true}
            viewMode={1}
            toggleDragModeOnDblclick={false}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClickCapture={onCropLocal}>
          Crop image
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImageCropper;
