import React, { useContext, useEffect, useState } from "react";
import styles from "./MerchantLogin.module.css";
import MerchantContext from "../../../context/merchant-context";
import ForgotPassword from "../../web/Pages/ForgotPassword";

function MerchantResetPassword() {
  const { authMerchant, setAuthMerchant } = useContext(MerchantContext);

  return (
    <div className={`${styles.login} my-0 py-0`}>
      <ForgotPassword reserMerchant={true} />
    </div>
  );
}

export default React.memo(MerchantResetPassword);
