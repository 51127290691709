import React, { useContext, useState } from "react";
import WebContext from "../../../context/web-context";
import { useNavigate } from "react-router-dom";
import MySpinner from "../../common/MySpinner";
import { defaultLat, defaultLnt } from "../../common/googlePlaceApiKey";
import styles from "./Categories.module.css";

function Categories({ categories }) {
  const {
    windowDimensions,
    setMerchantLoader,
    authUser,
    userLocationAddress,
    selectedKeyword,
    selectedLocation,
    location,
    setSelectedKeyword,
  } = useContext(WebContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const cardStyle = windowDimensions.width <= 768 ? "small px-2 mx-2" : "p-4  my-2 mx-2 border border-1 rounded shadow";

  const loader = () => (
    <div
      className="d-flex justify-content-center align-items-center text-success"
      style={{ height: "40vh", width: "auto" }}
    >
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );

  const handleCategoryClick = async (keyword) => {
    // const params = {
    //   latitude: authUser
    //     ? authUser?.location?.latitude
    //     : userLocationAddress?.lat
    //     ? userLocationAddress?.lat
    //     : defaultLat,
    //   longitude: authUser
    //     ? authUser?.location?.longitude
    //     : userLocationAddress?.lng
    //     ? userLocationAddress?.lng
    //     : defaultLnt,
    // };
    const params = {
      keyword: keyword,
      searchString: location,
      latitude: "",
      longitude: "",
    };
    setSelectedKeyword(keyword);
    localStorage.setItem("params", JSON.stringify(params));
    setMerchantLoader(true);
    setLoading(true);
    // if (authUser?.location && !useUserLocation && useLoggedInuserLocation) {
    //   params = {
    //     keyword: keyword,
    //     latitude: authUser?.location?.latitude,
    //     longitude: authUser?.location?.longitude,
    //   };
    // } else if (useUserLocation) {
    //   params = {
    //     keyword: keyword,
    //     latitude: userLat,
    //     longitude: userLng,
    //   };
    // } else {
    //   if (Object.keys(queryData).length !== 0 && isPlaceSelected) {
    //     params = {
    //       keyword: keyword,
    //       latitude: queryData.lat(),
    //       longitude: queryData.lng(),
    //     };
    //   }
    // }

    // const data = await commonService.getCategories(params);
    // if (data?.success) {
    //   setSearchMerchant(data.data);
    //   navigate("/MerchantList", { state: { fromSearchBar: true } });
    // } else {
    //   toast.error(data?.message);
    // }
    navigate(
      `/MerchantList?lat=${params.latitude}&lng=${params.longitude}&keyword=${params.keyword}&searchString=${params.searchString}`
    );

    setMerchantLoader(false);
    setLoading(false);
  };

  const categoriesListCardsForSmallScreen = () => {
    return loading
      ? loader()
      : categories?.map((category) => {
          return (
            category?.status === "active" && (
              <div
                key={category?._id}
                onClick={() => handleCategoryClick(category?.name)}
                style={{
                  textAlign: "center",
                  padding: "0px 5px",
                }}
              >
                {/* <img
                  style={{
                    width: 30,
                    minHeight: 70,
                    maxHeight: 70,
                    objectFit: "contain",
                  }}
                  src={category?.image ? category?.image : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"}
                  alt="Card image cap"
                /> */}
                <div className={`${styles.categoryTextMobile}`}>{category?.name}</div>
              </div>
            )
          );
        });
  };
  const categoriesListCards = () => {
    return loading
      ? loader()
      : categories?.map((category) => {
          return (
            category.status === "active" && (
              // <div
              //   key={category?._id}
              //   className={` ${cardStyle} ${styles.CategoriesCard}`}
              //   style={{ cursor: "pointer", textAlign: "center" }}
              //   onClick={() => handleCategoryClick(category?.name)}
              // >
              //   <img
              //     style={{
              //       width: windowDimensions.width <= 768 ? 30 : 50,
              //       minHeight: windowDimensions.width <= 768 ? 150 : 100,
              //       maxHeight: windowDimensions.width <= 768 ? 150 : 100,
              //       objectFit: "contain",
              //     }}
              //     src={category?.image ? category?.image : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"}
              //     alt="Card image cap"
              //   />
              //   <p className="px-2" style={{ color: "#0044B6" }}>
              //     {category?.name}
              //   </p>
              // </div>
              <div
                key={category?._id}
                onClick={() => handleCategoryClick(category?.name)}
                className={`${styles.categoryText}`}
              >
                <div
                  className="px-5"
                  style={{ color: "#00446B", fontSize: 22, whiteSpace: "nowrap", paddingBottom: 20 }}
                >
                  {category?.name}
                </div>
              </div>
            )
          );
        });
  };

  return (
    <>
      <section className={`bg-full ${styles.banner} mt-5`}>
        <div className="container justify-content-center d-flex text-success fw-bold fs-4 mb-3">Categories</div>
        {/* <div className="container justify-content-center d-flex flex-wrap px-0 "> */}
        <div className={`container ${styles.categoryContainer}`}>
          {categories?.length > 0 && windowDimensions.width <= 768 ? (
            <div
              className={`d-flex mx-4`}
              style={{
                cursor: "pointer",
                overflowY: "hidden",
                overflowX: "scroll",
              }}
            >
              {categoriesListCardsForSmallScreen()}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                paddingBottom: 10,
              }}
            >
              {categoriesListCards()}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Categories;
