import { useState } from "react";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import { Link } from "react-router-dom";


function CustomToggle({ children, eventKey, onClick }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        onClick()
    );

    return (
        <a className='text-white text-capitalize text-decoration-none hover-cursor d-flex align-items-center' onClick={decoratedOnClick}>
            {children}
        </a>
    );
}


function SidebarAccordian(props) {
  const { title, icon, eventKey, data } = props
  const [open, setOpen] = useState(false)
  return (
    <li className="sidebar-link">
      <Accordion>
        <CustomToggle eventKey={eventKey} onClick={() => setOpen(!open)}>
          <span className={icon}></span>
          <span className="flex-grow-1">{title}</span>
          { open ? <span className="fas fw fa-chevron-up"></span> : <span className="fas fw fa-chevron-down"></span> }
        </CustomToggle>
        <Accordion.Collapse eventKey={eventKey}>
          <Card className="mt-1 bg-transparent border-0" body>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {
                data && Array.isArray(data) && data.map(item => (
                  <li className="mb-3" key={item.to}>
                    <Link
                      className="text-dark text-capitalize text-decoration-none d-flex link-hover align-items-center"
                      to={item.to}
                    >
                      <span className="fas fa-fw fa-circle" style={{ fontSize: 8 }}></span>
                      <span>{item.title}</span>
                    </Link>
                  </li>
                ))
              }
            </ul>
          </Card>
        </Accordion.Collapse>
      </Accordion>
    </li>
  );
}

export default SidebarAccordian;
