import React, { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
// //Admin
import AdminLayout from "./components/admin/Layout/AdminLayout";
import LogoutUser from "./components/web/Pages/LogoutUser";
import AdminDashboard from "./components/admin/Pages/DashboardPage";
import AdminLogin from "./components/admin/Pages/AdminLogin";
import AdminContext from "./context/admin-context";
import { LOGGED_ADMIN, LOGGED_MERCHANT } from "./config/constants";
import AdminLogout from "./components/admin/Pages/AdminLogout";
import UserList from "./components/admin/user/UserList";
import ContactQuery from "./components/admin/Pages/ContactQuery";
import AdminMerchantList from "./components/admin/merchant/AdminMerchantList";
import AccountApproved from "./components/web/Pages/VerifyAccount";
import AddMerchant from "./components/admin/merchant/AddMerchant";
import MerchantDetail from "./components/admin/merchant/MerchantDetail";
import MerchantImages from "./components/admin/merchant/MerchantImages";
import Test from "./components/web/Test";
import AdminAddCategories from "./components/admin/Pages/AdminAddCategories";
//Merchant
import MerchantLayout from "./components/merchant/Layout/MerchantLayout";
import MerchantLogin from "./components/merchant/Pages/MerchantLogin";
import MerchantDashboard from "./components/merchant/Pages/DashboardPage";
import MerchantLogout from "./components/merchant/Pages/MerchantLogout";
import MySpinner from "./components/common/MySpinner";
import MerchantContext from "./context/merchant-context";

import MainLayout from "./components/web/Layout/MainLayout";
import Section from "./components/web/Layout/Section";
import Aboutus from "./components/web/Pages/Aboutus";
import MemberBenefits from "./components/web/Pages/MemberBenefits";
import Contactus from "./components/web/Pages/Contactus";
import Giveaways from "./components/web/Pages/Giveaways";
import SignIn from "./components/web/Pages/SignIn";
import SignUp from "./components/web/Pages/SignUp";
import Privacy from "./components/web/Pages/Privacy";
import MerchantList from "./components/web/Pages/MerchantList";
import Deals from "./components/web/Pages/Deals";
import Faqs from "./components/web/Pages/Faqs";
import TermsAndConditions from "./components/web/Pages/TermsAndConditions";
import MyAccount from "./components/web/Pages/MyAccount";
import ForgotPassword from "./components/web/Pages/ForgotPassword";
import MerchantSignUp from "./components/web/Pages/MerchantSignUp";
import Nopagefound from "./components/web/Pages/Nopagefound";
import MerchantProfile from "./components/merchant/Pages/MerchantProfile";
import MerchantProfileInfo from "./components/web/Pages/MerchantProfileInfo";
import MerchantBenefits from "./components/web/Pages/MerchantBenefits";
import AdminAddSubCategories from "./components/admin/Pages/AdminAddSubCategories";
import AdminAddSubSubCategories from "./components/admin/Pages/AdminAddSubSubCategories";
import AdminCategoryHierarchy from "./components/admin/Pages/AdminCategoryHierarchy";
import MerchantBusinessProfile from "./components/merchant/Pages/MerchantBusinessProfile";
import ManageMerchantKeywords from "./components/merchant/Pages/ManageMerchantKeywords";
import ManageBusinessHours from "./components/merchant/Pages/ManageBusinessHours";
import MerchantResetPassword from "./components/merchant/Pages/MerchantResetPassword";
import Benefits from "./components/web/Pages/Benefits";
import Configuration from "./components/admin/Pages/Configuration";
import { CommonContext } from "./store/commonStore";
import commonService from "./services/common/commonService";
import Review from "./components/web/Pages/Review";

function RoueConfig() {
  const user = sessionStorage.getItem(LOGGED_ADMIN);
  const iUser = user ? JSON.parse(user) : null;
  const [authAdmin, setAuthAdmin] = useState(iUser);
  const merchant = localStorage.getItem(LOGGED_MERCHANT) || sessionStorage.getItem(LOGGED_MERCHANT);
  const mUser = merchant ? JSON.parse(merchant) : null;
  const [authMerchant, setAuthMerchant] = useState(mUser);

  const [commonCtx, dispatchCommon] = CommonContext();
  useEffect(() => {
    commonService.getConfig().then((data) => {
      dispatchCommon({ type: "UPDATE_POOL_DATA", payload: data.data.pool });
    });
  }, []);

  return (
    <Suspense
      fallback={
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
          <MySpinner height={"40px"} width={"40px"} />
        </div>
      }
    >
      <AdminContext.Provider
        value={{
          authUser: authAdmin,
          setAuthUser: setAuthAdmin,
        }}
      >
        <MerchantContext.Provider
          value={{
            authMerchant: authMerchant,
            setAuthMerchant: setAuthMerchant,
          }}
        >
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Section />} />
              <Route path="home" element={<Section />} />
              <Route path="logout" element={<LogoutUser />} />
              <Route path="Aboutus" element={<Aboutus />} />
              <Route path="MemberBenefits" element={<MemberBenefits />} />
              <Route path="Benefits" element={<Benefits />} />
              <Route path="MerchantBenefits" element={<MerchantBenefits />} />
              <Route path="Contactus" element={<Contactus />} />
              <Route path="MerchantProfile/:id" element={<MerchantProfileInfo />} />
              <Route path="Giveaways" element={<Giveaways />} />
              <Route path="SignIn" element={<SignIn />} />
              <Route path="SignUp" element={<SignUp />} />
              <Route path="MerchantList" element={<MerchantList />} />
              <Route path="Deals" element={<Deals />} />
              <Route path="Faqs" element={<Faqs />} />
              <Route path="Privacy" element={<Privacy />} />
              <Route path="TermsAndConditions" element={<TermsAndConditions />} />
              <Route path="MyAccount" element={<MyAccount />} />
              <Route path="ForgotPassword" element={<ForgotPassword />} />
              <Route path="MerchantSignUp" element={<MerchantSignUp />} />
              <Route path="verify-email/:token" element={<AccountApproved />} />
              <Route path="test" element={<Test />} />
              <Route path="review/:merchantId" element={<Review />} />
            </Route>

            <Route path="/admin" element={<AdminLayout />}>
              <Route index element={<AdminDashboard />} />
              <Route path="categories" element={<AdminAddCategories />} />
              <Route path="subCategories" element={<AdminAddSubCategories />} />
              <Route path="subSubCategories" element={<AdminAddSubSubCategories />} />
              <Route path="categoryHierarchy" element={<AdminCategoryHierarchy />} />
              <Route path="logout" element={<AdminLogout />} />
              <Route path="users">
                <Route index element={<UserList />} />
              </Route>
              <Route path="merchants">
                <Route index element={<AdminMerchantList />} />
                <Route path="add" element={<AddMerchant />} />
                <Route path="add/:id" element={<AddMerchant />} />
                <Route path=":id" element={<MerchantDetail />} />
                <Route path="images/:id" element={<MerchantImages />} />
              </Route>
              <Route path="contact-queries" element={<ContactQuery />} />
              <Route path="configuration" element={<Configuration />} />
            </Route>

            <Route path="/merchant" element={<MerchantLayout />}>
              <Route index element={<MerchantDashboard />} />
              <Route path="merchant-business-profile" element={<MerchantBusinessProfile />} />
              <Route path="business-hours" element={<ManageBusinessHours />} />
              <Route path="manage-keywords" element={<ManageMerchantKeywords />} />
              <Route path="merchant-profile" element={<MerchantProfile />} />
              <Route path="logout" element={<MerchantLogout />} />
              {/* <Route path="users">
            <Route index element={<UserList />} />
          </Route>

          <Route path="merchants">
            <Route index element={<AdminMerchantList />} />
            <Route path="add" element={<AddMerchant />} />
            <Route path="add/:id" element={<AddMerchant />} />
            <Route path=":id" element={<MerchantDetail />} />
            <Route path="images/:id" element={<MerchantImages />} />
          </Route>
          <Route path="contact-queries" element={<ContactQuery />} /> */}
            </Route>

            <Route path="merchant-reset-password" element={<MerchantResetPassword />} />
            <Route path="merchant-login" element={<MerchantLogin />} />
            <Route path="admin-login" element={<AdminLogin />} />
            <Route path="*" element={<Nopagefound />} />
          </Routes>
        </MerchantContext.Provider>
      </AdminContext.Provider>
    </Suspense>
  );
}

export default RoueConfig;
