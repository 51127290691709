import React from "react";
import SidebarAccordian from "./SidebarAccordian";
import SidebarLink from "./SidebarLink";
import sidebarImage from "../../../assets/images/dashboardBG.png";

function SidebarLabel({ title }) {
  return "";
  // return <span className="w-100 text-white px-3 mb-3">{title}</span>;
}

function Sidebar({ user, sidebar, closeSidebar }) {
  let classd = sidebar ? "" : "d-none";
  return (
    <>
      <div
        id="sidebar"
        className={` px-0 py-0 text-white ${classd} d-md-block position-fixed shadow`}
        style={{
          width: "270px",
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: 1000,
          backgroundImage: `url(${sidebarImage})`,
        }}
      >
        <div className={`sidebarContent`}>
          <div className="text-center">
            <img src="/logo.png" style={{ height: "34px", width: "auto" }} />
            <p className="text-center text-white mt-2">
              Welcome {user?.merchant?.name} <span className="fas fa-fw fa-grin"></span>{" "}
            </p>
          </div>
          <ul className="p-0" style={{ listStyleType: "none" }}>
            <SidebarLabel title="Basic setup" />

            <section className="px-4">
              <SidebarLink
                closeSidebar={closeSidebar}
                title="Dashboard"
                icon="fas fa-fw fa-tachometer-alt"
                to="/merchant"
              />
              <SidebarLink
                closeSidebar={closeSidebar}
                title="Business Hours"
                icon="fas fa-fw fa-clock"
                to="/merchant/business-hours"
              />
              <SidebarLink
                closeSidebar={closeSidebar}
                title="Keywords"
                icon="fas fa-fw fa-font"
                to="/merchant/manage-keywords"
              />
              <SidebarLink
                closeSidebar={closeSidebar}
                title="My Business Profile"
                icon="fas fa-fw fa-business-time"
                to="/merchant/merchant-business-profile"
              />
              <SidebarLink
                closeSidebar={closeSidebar}
                title="My Profile"
                icon="fas fa-fw fa-user"
                to="/merchant/merchant-profile"
              />

              {/* <SidebarLink
                closeSidebar={closeSidebar}
                title="Users"
                icon="fas fa-fw fa-user-alt"
                to="/admin/users"
              />

              <SidebarLink
                closeSidebar={closeSidebar}
                title="Merchants"
                icon="fas fa-fw fa-user-alt"
                to="/admin/merchants"
              />

              <SidebarLink
                closeSidebar={closeSidebar}
                title="Contact queries"
                icon="fas fa-fw fa-file-alt"
                to="/admin/contact-queries"
              /> */}
              <SidebarLink title="Logout" icon="fas fa-fw fa-sign-out-alt" to="/merchant/logout" />
            </section>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
