import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Card,
  Container,
  Accordion,
  Table,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import styles from "./MyAccount.module.css";

function MyAccount() {
  return (
    <Card className="py-5 rounded-0 border-0 border-top bg-grey">
      <section className="contact-sec container bg-white py-3 px-3">
        <Container style={{ minHeight: "60vh", height: "auto" }}>
          <Row className="gy-5 pt-5">
            <Col
              xs={12}
              sm={12}
              md={3}
              lg={3}
              className=" mx-auto mx-md-0  d-flex align-items-start justify-content-start justify-content-center  justify-content-md-center  "
            >
              <FontAwesomeIcon size="6x" icon={faUser} />
              {/* <Image
                // src={}
                fluid
                roundedCircle
              /> */}
            </Col>
            <Col xs={12} sm={12} md={9} lg={9}>
              <div>
                <p class="h4 text-success text-capitalize">User Name</p>
              </div>

              <div>
                <p class="h6 text-muted">General Information</p>
              </div>
              <Row>
                <Col xs={10} sm={8} md={8} lg={6}>
                  <Row>
                    <Col xs={6} className>
                      <span className="text-capitalize fw-bold ">Gender :</span>
                    </Col>
                    <Col xs={6}>Male</Col>
                  </Row>
                  <Row>
                    <Col xs={6} className>
                      <span className="text-capitalize fw-bold ">
                        Birthday :
                      </span>
                    </Col>
                    <Col xs={6}>16 May 2001</Col>
                  </Row>
                </Col>
              </Row>

              <div>
                <p class="h6 text-muted mt-2">Contact Information</p>
              </div>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Row>
                        <Col xs={6}>
                          <span className="text-capitalize fw-bold ">
                            Phone :
                          </span>
                        </Col>
                        <Col xs={6}>987-654-3210</Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <span className="text-capitalize fw-bold ">
                            Address :
                          </span>
                        </Col>
                        <Col xs={6}>1717 E Calumet St Appleton WI 54915</Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Row>
                        <Col xs={6}>
                          <span className="text-capitalize fw-bold ">
                            Email :
                          </span>
                        </Col>
                        <Col xs={6}>abc@gmail.com</Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <span className="text-capitalize fw-bold ">
                            Website :
                          </span>
                        </Col>
                        <Col xs={6}>www.abc.com</Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </Card>
  );
}

export default MyAccount;
