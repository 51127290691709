import { Button, Card, Container } from "react-bootstrap";
import React, { useContext, useEffect } from "react";
import SeachbarAndPoolBarForSmallScreen from "./SeachbarAndPoolBarForSmallScreen";
import SeachbarAndPoolBarForBigScreen from "./SeachbarAndPoolBarForBigScreen";
import WebContext from "../../../context/web-context";
import { useLocation, useNavigate } from "react-router-dom";
import MembersBG from "../../../assets/images/Rectangle for members.png";
import MerchantsBG from "../../../assets/images/Rectangle for merchants.png";
import TravelocityGiveaway from "../../../assets/images/travelocity.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import MyScrollToTop from "../../common/MyScrollToTop";

const data = [
  {
    title: "For Members",
    description: "Register Now For Your FREE Lifetime Premium Membership",
    image: MembersBG,
    color: "green",
    buttonName: "Signup Now",
    to: "/Signup",
    more: "/MerchantSignup",
    benefits: [
      "Money saving deals from area Merchants",
      "Loyalty Points to your favorite Merchants all on one platform",
      "Exclusive Premium Members Only discounts",
      "Weekly, Monthly and Quarterly giveaways",
      "Weekly, Monthly and Quarterly giveaways",
      "Points for your activity on eazyer",
      "Redeem points for gift cards and more",
      "Credits for every Deal purchase",
    ],
  },
  {
    title: "For Merchants",
    description: "Register Now & We Will Create Your  Listing & Profile Page With No Obligation or Commitments",
    image: MerchantsBG,
    color: "#00446B",
    buttonName: "Signup Now",
    to: "/MerchantSignup",
    more: "/MerchantSignup",
    benefits: [
      "Dedicated AD-FREE Listing and Profile Page",
      "Do it on your own or we will update your content for you",
      "Offer Loyalty Points using our system",
      "Get 90% back on Deals to attract new customers",
      "Respond to reviews posted on your Profile Page",
      "Reach out directly to Members using our Member Analytics tool.",
      "Your Account Manager will call/visit you every 2 months to discuss page performance, update content and help increase traffic",
    ],
  },
];

function Benefits() {
  const { windowDimensions } = useContext(WebContext);
  let navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const data = () => {
      <MyScrollToTop />;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    data();
    // window.scroll(0, 0);
  }, [pathname]);
  function handleRoute(route = null) {
    if (route) {
      navigate(route);
    }
  }

  const cardForMembers = () => {
    return (
      <>
        <Card
          onClick={(e) => {
            // handleRoute("/MemberBenefits");
          }}
          className="mb-3 bg-white shadow"
          style={{ width: "30rem", cursor: "pointer" }}
        >
          <div className="card-img-overlay text-white text-center">
            <div className="fs-5 mb-3">For Members</div>
            <div className="fs-6">Register Now For Your FREE Lifetime Premium Membership</div>
          </div>
          <Card.Img
            className=""
            variant="top"
            style={{ height: 150, objectFit: "cover" }}
            src={MembersBG ? MembersBG : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"}
          />
          <Card.Body className="d-flex justify-content-between flex-column">
            {data[0].benefits.map((data, ind) => (
              <Card.Text key={data.to}>
                <div className="row ">
                  <div className="col-1">
                    <FontAwesomeIcon
                      color={data.color}
                      icon={faPaperPlane}
                      style={{ transform: "rotate(60deg)" }}
                      className=""
                    />
                  </div>
                  <div className="col-10">{data}</div>
                </div>
              </Card.Text>
            ))}
          </Card.Body>
        </Card>
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            handleRoute("/Signup");
          }}
          variant="secondary"
          className="bg-success"
        >
          Signup Now
        </Button>
      </>
    );
  };
  return (
    <>
      <SeachbarAndPoolBarForSmallScreen />
      <SeachbarAndPoolBarForBigScreen />
      <div className={`pt-1 overflow-hidden ${windowDimensions.width > 768 && "pt-5 mt-5"}`}>
        <section className="my-5">
          <Container style={{ minHeight: 500 }} className="d-flex justify-content-evenly align-items-stretch flex-wrap">
            {data.map((data) => (
              <div className="d-flex flex-column mb-4 justify-content-between ">
                <Card
                  onClick={(e) => {
                    handleRoute(data.more);
                  }}
                  key={data.title}
                  className="mb-3 bg-white shadow"
                  style={{
                    width: windowDimensions.width > 768 ? "30rem" : "22rem",
                    cursor: "pointer",
                    height: "100%",
                  }}
                >
                  <div className="card-img-overlay text-white text-center">
                    <div className="fs-5 mb-3">{data.title}</div>
                    <div className="fs-6">{data.description}</div>
                  </div>
                  <Card.Img
                    className=""
                    variant="top"
                    style={{ height: 150, objectFit: "cover" }}
                    src={data.image ? data.image : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"}
                  />
                  <Card.Body className="d-flex justify-content-between flex-column">
                    {data.benefits.map((data, ind) => (
                      <Card.Text key={data.to}>
                        <div className="row ">
                          <div className="col-1">
                            <FontAwesomeIcon
                              color={data.color}
                              icon={faPaperPlane}
                              style={{ transform: "rotate(60deg)" }}
                              className=""
                            />
                          </div>
                          <div className="col-10">{data}</div>
                        </div>
                      </Card.Text>
                    ))}
                    <Container className="mt-3 d-flex justify-content-center">
                      <Button
                        // onClick={(e) => {
                        //   handleRoute(data.to);
                        // }}
                        variant="secondary"
                        style={{
                          background: data.color,
                        }}
                        className={windowDimensions.width > 768 ? "" : "w-100"}
                      >
                        {data.buttonName}
                      </Button>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Container>
        </section>
      </div>
    </>
  );
}

export default Benefits;
