import React, { useContext, useState, useEffect, useLayoutEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, Col, Container, Form, InputGroup, Nav, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import MySpinner from "../../common/MySpinner";
import axios from "axios";
import { MERCHANT_SIGNUP, STATE_LIST, USER_SIGNUP } from "../../../config/endpoints";
import { Navigate } from "react-router-dom";
import WebContext from "../../../context/web-context";
import { LOGGED_USER } from "../../../config/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import styles from "./SignUp.module.css";
import { validateEmail, validatePhone } from "../../../config/helper";
import { usePlacesWidget } from "react-google-autocomplete";
//import e from "cors";
import commonService from "../../../services/common/commonService";
import { SingUpContext } from "../../../store/signUpStore";
import { Button } from "antd";
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const PWD_DIGIT_REGEX = /(?=.*\d)/; //should contain atleast 1 digit
const PWD_LOWER_REGEX = /(?=.*[a-z])/; //should contain atleast 1 lowercase
const PWD_UPPER_REGEX = /(?=.*[A-Z])/; //should contain atleast 1 uppercase
const PWD_SPCHAR_REGEX = /[$@$!%*?&]/; //should contain atleast 1 Special Character

function MerchantSignUp() {
  let initObj = {
    businessName: "",
    address: "",
    phone: "",
    name: "",
    lastName: "",
    email: "",
    dob: "",
    password: "",
    city: "",
    state: "",
    zipcode: "",
    checked: true,
  };
  const [submitted, setSubmitted] = useState(false);
  const { authUser, setAuthUser } = useContext(WebContext);
  const navigate = useNavigate();
  // const [form, setForm] = useState(initObj);
  const [load, setLoad] = useState(false);
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [chkDigit, setChkDigit] = useState(false);
  const [chkLower, setChkLower] = useState(false);
  const [chkUpper, setChkUpper] = useState(false);
  const [chkSpecialChar, setChkSpecialChar] = useState(false);
  const [chkChar, setChkChar] = useState(false);
  const [{ merchantFormData: form }, dispatchSignUp] = SingUpContext();
  const location = useLocation();
  const [error, setError] = useState("");

  const [data, setData] = useState({
    states: [],
  });

  function handleForm(e) {
    const name = e.target.name;
    const value = e.target.value;
    // setForm({
    //   ...form,
    //   [e.target.name]: e.target.value,
    // });
    dispatchSignUp({ type: "FILL_MERCHANT_FORM", payload: { name, value } });
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChangeEmail = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (!isValidEmail(value)) {
      setError("Email is invalid");
    } else {
      setError(null);
    }
    // setForm({
    //   ...form,
    //   [e.target.name]: e.target.value,
    // });
    dispatchSignUp({ type: "FILL_MERCHANT_FORM", payload: { name, value } });
  };
  const handleCheckbox = (name) => {
    const value = !form.checked;
    dispatchSignUp({ type: "FILL_MERCHANT_FORM", payload: { name, value } });
  };

  // useEffect(() => {
  //   setValidPwd(PWD_REGEX.test(form.password));
  //   setChkDigit(PWD_DIGIT_REGEX.test(form.password));
  //   setChkLower(PWD_LOWER_REGEX.test(form.password));
  //   setChkUpper(PWD_UPPER_REGEX.test(form.password));
  //   setChkSpecialChar(PWD_SPCHAR_REGEX.test(form.password));
  //   form.password.length > 8 ? setChkChar(true) : setChkChar(false);
  // }, [form.password]);

  // function handleDate(e) {
  //   setForm({
  //     ...form,
  //     dob: e.target.value
  //       .replace(/^(\d\d)(\d)$/g, "$1/$2")
  //       .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
  //       .replace(/[^\d\/]/g, ""),
  //   });
  // }

  const submitForm = async (e) => {
    e.preventDefault();
    if (!form.checked) {
      toast.error("Please accept the terms and conditions");
      return false;
    }
    if (error) {
      toast.error(error);
    } else {
      setLoad(true);

      const data = await commonService.merchantSignUp(form);
      if (data?.success) {
        toast.success(data.message);
        dispatchSignUp({ type: "RESET_MERCHANT_FORM" });
      } else {
        toast.error(data.message);
      }
      setLoad(false);
    }
  };

  function handleZip(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (isNaN(value) || value.length > 8) return;
    // setForm({
    //   ...form,
    //   [e.target.name]: e.target.value,
    // });
    dispatchSignUp({ type: "FILL_MERCHANT_FORM", payload: { name, value } });
  }

  function handlePhone(e) {
    const name = e.target.name;
    let value = e.target.value;
    if (isNaN(value) || value.length > 14) return;
    // setForm({
    //   ...form,
    //   [e.target.name]: value,
    // });
    dispatchSignUp({ type: "FILL_MERCHANT_FORM", payload: { name, value } });
  }

  useEffect(() => {
    const fetchStates = async () => {
      const data = await commonService.getAllStates();
      if (data.success) {
        setData({
          ...data,
          states: data.data.states,
        });
      } else {
        toast.error(data.message);
      }
      setLoad(false);
    };
    fetchStates();
  }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location]);

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  //
  if (authUser && authUser.token) {
    return <Navigate to="/" replace={true} />;
  }

  const merchantFormSubmitted = () => {
    return <Navigate to="/MerchantBenefits" replace={true} />;
  };

  const loader = () => (
    <div className="d-flex  justify-content-center ">
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );

  return (
    <section className="log-sec signup-section">
      <Container
        fluid
        className="d-flex justify-content-center align-items-center w-100 p-md-5 p-2"
        style={{ minHeight: "inherit" }}
      >
        {submitted ? (
          merchantFormSubmitted()
        ) : (
          <Col md="8" lg="7">
            <Card className={[styles.cardHeight, "shadow py-3"].join(" ")}>
              {load && loader()}
              <Card.Body>
                {/* <Nav justify variant="tabs" className="mb-4">
                  <Nav.Item>
                    <Nav.Link>
                      <Link className="text-muted text-decoration-none" to="/Signup" replace>
                        Member Signup
                      </Link>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="active">
                      <Link className="text-dark text-decoration-none" to="/MerchantSignup" replace>
                        Merchant Signup
                      </Link>
                    </Nav.Link>
                  </Nav.Item>
                </Nav> */}
                <div className="register-tabs">
                  <Button size="large" onClick={() => navigate("/Signup")}>
                    Member Signup
                  </Button>
                  <Button className="activeButton" size="large" onClick={() => navigate("/MerchantSignup")}>
                    Merchant Signup
                  </Button>
                </div>

                <Form onSubmit={submitForm}>
                  <div className="rounded">
                    <div className="gy-4 my-2">
                      <div className="text-center">
                        <h4 style={{ color: "#218C44" }}>
                          Sign Up Now and We Will Create Your AD FREE Listing and Profile Page With No Obligation or
                          Commitments
                        </h4>
                      </div>
                    </div>
                    <div className="row gy-4 pt-3 px-md-4">
                      <div className="col-12 col-md-6">
                        <input
                          value={form.name}
                          name="name"
                          onChange={handleForm}
                          className="rounded form-control"
                          placeholder="Contact Name*"
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <input
                          value={form.businessName}
                          name="businessName"
                          onChange={handleForm}
                          className="rounded form-control"
                          placeholder="Business Name*"
                        />
                      </div>
                    </div>
                    <div className="row gy-4 pt-3 px-md-4">
                      <div className="col-12 col-md-6">
                        <InputGroup className="">
                          {/* <InputGroup.Text id="basic-addon1">
                            +1
                          </InputGroup.Text> */}

                          <input
                            type="number"
                            inputMode="numeric"
                            name="phone"
                            value={form.phone}
                            onChange={handlePhone}
                            className="form-control"
                            placeholder="Phone number*"
                          />
                        </InputGroup>
                      </div>

                      <div className="col-12 col-md-6">
                        <input
                          type="email"
                          name="email"
                          value={form.email}
                          onChange={handleChangeEmail}
                          className="form-control"
                          placeholder="Email*"
                        />
                      </div>
                    </div>
                    <div className="row gy-4 pt-3 px-md-4">
                      <div className="col-12 col-md-6">
                        <input
                          name="address"
                          value={form.address}
                          onChange={handleForm}
                          className="form-control"
                          placeholder="Address*"
                        />
                      </div>

                      <div className="col-12 col-md-6">
                        <input
                          type="number"
                          pattern="[0-9]*"
                          inputMode="numeric"
                          name="zipcode"
                          value={form.zipcode}
                          onChange={handleZip}
                          className="form-control"
                          placeholder="Zip code*"
                        />
                      </div>
                    </div>

                    <div className="row gy-4 pt-3 px-md-4">
                      <div className="col-12 col-md-6">
                        <input
                          name="city"
                          value={form.city}
                          onChange={handleForm}
                          className="form-control"
                          placeholder="City*"
                        />
                      </div>

                      <div className="col-12 col-md-6">
                        <Form.Select name="state" value={form.state} onChange={handleForm}>
                          <option value="" className={styles.formTextColors}>
                            -- select state --
                          </option>
                          {data.states.map((state, i) => {
                            return (
                              <option key={i} value={state.id} className={styles.formTextColors}>
                                {/* {state.name} */}
                                {state.abbreviation}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                    </div>

                    <div className="row gy-4 pt-3 px-md-4 mb-2">
                      <div className="col-12 col-md-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            value="checked"
                            checked={form.checked}
                            onChange={(e) => {
                              handleCheckbox(e.target.name);

                              // setForm({ ...form, checked: !form.checked });
                            }}
                            type="checkbox"
                            id="checkRemember"
                          />
                          <label className="form-check-label" htmlFor="checkRemember">
                            <Link
                              to="/TermsAndConditions"
                              // className="text-black text-muted"
                              style={{ textDecoration: "none" }}
                            >
                              Terms and Conditions
                            </Link>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row gy-4 pt-3 px-md-4">
                      <div
                        id="pwdnote"
                        className={`row ${pwdFocus && !validPwd ? styles.instructions : styles.offscreen}`}
                      >
                        <div className="row">
                          <div className="col-12 col-lg-6 col-md-12">
                            Must include Uppercase letters.{" "}
                            {chkUpper ? (
                              <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                            ) : (
                              <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                            )}
                          </div>
                          <div className="col-12 col-lg-6 col-md-12">
                            Must include lowercase letters.{" "}
                            {chkLower ? (
                              <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                            ) : (
                              <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-lg-6 col-md-12">
                            At Least 1 number.{" "}
                            {chkDigit ? (
                              <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                            ) : (
                              <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                            )}
                          </div>
                          <div className="col-12 col-lg-6 col-md-12">
                            Allowed special characters: <span aria-label="exclamation mark">!</span>{" "}
                            <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span>{" "}
                            <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>{" "}
                            {chkSpecialChar ? (
                              <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                            ) : (
                              <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-lg-6 col-md-12">
                            Minimum 8 characters.{" "}
                            {chkChar ? (
                              <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                            ) : (
                              <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <span className="text-primary">
                      Password shoud contain one uppercase, numeric and special character
                      </span> */}
                      <div className="col-12 text-left">
                        <button type="submit" className="register-now btn btn-success" style={{ lineHeight: 1 }}>
                          Register Now{" "}
                        </button>
                      </div>
                      {/* <div className="col-12 text-center">
                        <label className="fs-6">
                          If you have any questions, please feel free to{" "}
                          <Link to="/home#contactus">Contact Us</Link> anytime.
                        </label>
                      </div> */}
                    </div>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Container>
    </section>
  );
}

export default MerchantSignUp;

// <>
// <div>
//   <h2 className="text-center text-white fw-bold mb-3">
//     Thank you for submitting your information.
//   </h2>
//   <h4 className="text-light text-center mb-3">
//     {" "}
//     We will start creating your page and be in touch with you soon.
//   </h4>
//   <h5 className="text-center text-light">
//     If you have any questions, please feel free to{" "}
//     <Link smooth to="/#contactus">
//       Contact Us
//     </Link>{" "}
//     anytime.
//   </h5>
// </div>
// </>
