import React, { useContext, useEffect, useState } from "react";
import SeachbarAndPoolBarForBigScreen from "./SeachbarAndPoolBarForBigScreen";
import WebContext from "../../../context/web-context";
import { Button, Card, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faClock, faCloud, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebookF, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import SeachbarAndPoolBarForSmallScreen from "./SeachbarAndPoolBarForSmallScreen";
import { defaultLat, defaultLnt, GOOGLE_API_KEY } from "../../common/googlePlaceApiKey";
import GoogleMapReact from "google-map-react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ReactPhotoCollage } from "react-photo-collage";
import bookOnline from "./bookOnline.jpg";
import Menu from "./menu.jpg";
import facebook from "./facebook.png";

import pin from "./pin.png";
import merchantService from "../../../services/merchant/merchantService";
import Loader from "../../common/Loader";
import { Skeleton } from "antd";
import { toast } from "react-toastify";
import "./merchantProfileInfo.css";
import "./Contactus.css";

const timings = [
  {
    id: 1,
    day: "Monday",
  },
  {
    id: 2,
    day: "Tuesday",
  },
  {
    id: 3,
    day: "Wednesday",
  },
  {
    id: 4,
    day: "Thursday",
  },
  {
    id: 5,
    day: "Friday",
  },
  {
    id: 6,
    day: "Saturday ",
  },
  {
    id: 0,
    day: "Sunday",
  },
];

function MerchantProfileInfo() {
  const navigate = useNavigate();

  let { id } = useParams();
  const { windowDimensions } = useContext(WebContext);
  const [merchant, setMerchant] = useState([]);
  const [loading, setLoading] = useState(true);
  const addStyle = `col-1 mx-0 d-flex  align-items-center ${
    windowDimensions.width <= 768 ? "justify-content-center" : "justify-content-center"
  }`;
  const [mapProps, setMapProps] = useState();
  const defaultProps = {
    center: {
      lat: defaultLat,
      lng: defaultLnt,
    },
    zoom: 11,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!id) navigate("/");
  }, []);

  useEffect(() => {
    if (id) {
      merchantService
        .getMerchant(id)
        .then((data) => {
          setMerchant(data?.data);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  useEffect(() => {
    if (merchant?.location?.coordinates?.length) {
      setMapProps({
        lat: merchant?.location?.coordinates[1],
        lng: merchant?.location?.coordinates[0],
      });
    }
  }, [merchant?.location?.coordinates]);

  const workingDay = (day) => {
    return timings.map((val) => {
      return val.id === day && val.day;
    });
  };
  const workingHours = (workingHours) => {
    if (workingHours?.length > 0) {
      return workingHours.map((hour, index) => {
        return (
          <div className="row" key={index}>
            <div className="col-12 " style={{ minWidth: 250 }}>
              <div className="row">
                <div className="col-12 ">
                  <div className="d-flex justify-content-between">
                    <div className="fw-bold">{workingDay(hour?.day)}</div>
                    <div style={{ whiteSpace: "pre-wrap" }}>{hour?.hours}</div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        );
      });
    }
  };
  const showWorkingHour = (hour) => {
    var dateInNumber = new Date().getDay();
    const currentWeekDay = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][
      new Date().getDay()
    ];
    const current = hour?.filter((day) => day.day === dateInNumber)[0];
    return !!current?.hours.length && `${currentWeekDay} \n ${current?.hours || ""}`;
  };

  function formateNumber(number) {
    let f_val = number?.replace(/\D[^\.]/g, "");
    let formatted = f_val?.slice(0, 3) + "-" + f_val?.slice(3, 6) + "-" + f_val?.slice(6);
    return formatted;
  }
  const popover = (
    <Popover id="popover-basic" style={{ marginLeft: "15px" }}>
      <Popover.Body>{workingHours(merchant?.workingHours)}</Popover.Body>
    </Popover>
  );
  const handleReview = (merchant) => {
    if (merchant?.tripAdvisor.num_reviews.length) {
      navigate(`/review/${merchant?.id}`, { state: { merchantLogo: merchant.logo } });
    } else {
      toast.error("You don't have reviews");
    }
  };
  const renderFirstRow = () => {
    return (
      <div className="row upper-container">
        <div className="col-sm-12 col-md-12 col-lg-2 profile-image-cont">
          <img
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",
              border: "4px #00446b solid",
            }}
            src={merchant?.logo ? merchant?.logo : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"}
            alt="Card  cap"
          />
        </div>
        <div className="col-sm-12  col-md-12 col-lg-5">
          {loading ? (
            <Skeleton active={true} style={{ marginTop: "10px" }} paragraph={{ rows: 5 }} />
          ) : (
            <>
              <div className="profile-business-name">{merchant?.businessName}</div>
              <div className={`text-secondary  ${windowDimensions.width > 768 ? "" : "small"} `}>
                {/* <div className={`row mx-0 ${windowDimensions.width > 768 ? "w-50" : "w-100"}  `}> */}
                <div className={`row  `}>
                  <div className={`${addStyle}`}>
                    <FontAwesomeIcon
                      fontSize={windowDimensions.width <= 768 ? "smaller" : 15}
                      className={`text-success `}
                      icon={faLocationDot}
                    />
                  </div>
                  <div className="col-10 px-0">
                    <div className="row">
                      <div style={{ width: "300px" }}>{merchant?.address}</div>
                      <div className="col-12 text-secondary text-capitalize">
                        {merchant?.address2 ? merchant?.address2 : ""}
                      </div>
                      <div className="col-12 text-secondary text-capitalize">
                        {merchant?.city}, {merchant?.state} {merchant?.zipcode}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className={`row mx-0 ${windowDimensions.width > 768 ? "w-50" : "w-100"}  `}> */}
                <div className={`row   `}>
                  <div className={`${addStyle} `}>
                    <FontAwesomeIcon
                      fontSize={windowDimensions.width <= 768 ? "smaller" : 15}
                      className={`text-success `}
                      icon={faPhone}
                    />
                  </div>
                  <div className="col-10 px-0">{formateNumber(merchant?.phone)}</div>
                </div>

                {/* <div className={`row mx-0 ${windowDimensions.width > 768 ? "w-50" : "w-100"}  `}> */}
                {!!merchant?.workingHours?.length && (
                  <div className={`row   `}>
                    <div className={`${addStyle}  `}>
                      <FontAwesomeIcon
                        fontSize={windowDimensions.width <= 768 ? "smaller" : 15}
                        className={`text-success `}
                        icon={faClock}
                      />
                    </div>
                    <div className="col-10 px-0" style={{ padding: "5px" }}>
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        // show={true}
                        placement={windowDimensions.width <= 768 ? "bottom" : "right"}
                        overlay={popover}
                      >
                        <span style={{ whiteSpace: "pre-line" }}>
                          {showWorkingHour(merchant?.workingHours) || "NA"}
                          <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={faCaretDown} />
                        </span>
                      </OverlayTrigger>
                    </div>
                  </div>
                )}
                {merchant?.website && (
                  // <div className={`row mx-0 ${windowDimensions.width > 768 ? "w-50" : "w-100"}  `}>
                  <div className={`row`}>
                    <div className={`${addStyle}  `}>
                      <FontAwesomeIcon
                        fontSize={windowDimensions.width <= 768 ? "smaller" : 15}
                        className={`text-success `}
                        icon={faCloud}
                      />
                    </div>
                    <a
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      href={merchant?.website.includes("http") ? merchant?.website : "https://" + merchant?.website}
                      className="text-secondary col-10 px-0"
                    >
                      Website
                    </a>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className={`col-sm-12 col-md-12 col-lg-4  d-flex justify-content-md-end`}>
          <div
            className="row d-block"
            style={{
              flex: 1,
              textAlign: windowDimensions.width <= 768 ? "start" : "end",
            }}
          >
            <div className={`mb-2 mt-1 review`} onClick={() => handleReview(merchant)}>
              {!!merchant?.tripAdvisor?.rating_image_url?.length && (
                <>
                  <img src={merchant?.tripAdvisor?.rating_image_url} alt="trip advisor" />
                  <span>({merchant?.tripAdvisor?.num_reviews || 0})</span>
                </>
              )}
            </div>
            <div className={`mb-2`}>
              {/* {merchant?.socialLinks?.facebook.toLowerCase().includes("facebook") && ( */}
              {!!merchant?.socialLinks?.facebook.length && (
                <a
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  href={merchant?.socialLinks?.facebook}
                  className="text-secondary col-10 px-0"
                  rel="noreferrer"
                >
                  {/* <FontAwesomeIcon
                    fontSize={windowDimensions.width <= 768 ? "smaller" : 15}
                    className={`me-2 p-1 text-white rounded-circle`}
                    enableBackground
                    style={{ height: 15, width: 15, backgroundColor: "#00446B" }}
                    icon={faFacebookF}
                  /> */}
                  <img src={facebook} style={{ width: "6%", height: "20%" }} />
                </a>
              )}
              {/* menu */}
              {/* {merchant?.socialLinks?.twitter.toLowerCase().includes("twitter") && ( */}
              {!!merchant?.socialLinks?.twitter.length && (
                <a
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  href={merchant?.socialLinks?.twitter}
                  className="text-secondary col-10 px-0"
                  rel="noreferrer"
                >
                  {/* <FontAwesomeIcon
                    fontSize={windowDimensions.width <= 768 ? "smaller" : 15}
                    className={`me-2 p-1 text-white rounded-circle`}
                    enableBackground
                    style={{ height: 15, width: 15, backgroundColor: "#00446B" }}
                    icon={faTwitter}
                  /> */}
                  <img src={bookOnline} style={{ width: "10%", height: "20%" }} />
                </a>
              )}
              {/* book online */}
              {/* {merchant?.socialLinks?.linkedin.toLowerCase().includes("linkedin") && ( */}
              {!!merchant?.socialLinks?.linkedin.length && (
                <a
                  style={{ textDecoration: "none" }}
                  href={merchant?.socialLinks?.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-secondary col-10 px-0"
                >
                  {/* <FontAwesomeIcon
                    fontSize={windowDimensions.width <= 768 ? "smaller" : 15}
                    className={`me-2 p-1 text-white rounded-circle`}
                    enableBackground
                    style={{ height: 15, width: 15, backgroundColor: "#00446B" }}
                    icon={faLinkedinIn}
                  /> */}
                  <img src={Menu} style={{ width: "10%", height: "20%" }} />
                </a>
              )}
            </div>
            {/* <div className={`mb-2`}>
              <div className="row ">
                <div className="d-flex justify-content-end">
                  <Button className="text-white btn-sm w-100 me-1" variant="secondary">
                    Add Photo
                  </Button>
                  <Button
                    variant="secondary"
                    className="text-white btn-sm w-100 ms-1"
                    style={{ backgroundColor: "#00446B" }}
                  >
                    Write A Review
                  </Button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  };
  const markerStyle = {
    position: "absolute",
    top: "100%",
    left: "50%",
    width: "28px",
    height: "28px",
    transform: "translate(-50%, -100%)",
  };
  const imageLength = merchant?.images?.length;
  const setting1 = {
    width: "auto",
    height: windowDimensions.width < 480 ? ["122px", "60px"] : ["220px", "110px"],
    layout: [1],
    photos: merchant?.images?.map((image) => ({ source: image?.originalImage || image?.image })),
  };
  const setting2 = {
    width: "auto",
    // height: windowDimensions.width < 480 ? ["60px", "60px"] : imageLength === 1 ? ["220px", "110px"] : ["110px", "110px"],
    height:
      windowDimensions.width < 992
        ? windowDimensions.width < 768
          ? ["100px", "70px"]
          : ["120px", "80px"]
        : ["180px", "130px"],
    // layout: imageLength === 1 ? [1] : imageLength === 2 ? [1, 1] : imageLength === 3 ? [2, 1] : [1, 4],
    layout: imageLength > 3 ? [2, 3] : [3],
    photos:
      merchant && Array.isArray(merchant.images)
        ? merchant.images.map((image) => ({ source: image.originalImage || image.image }))
        : [],
    showNumOfRemainingPhotos: true,
  };

  const RenderSecondRow = () => {
    return (
      <div className="main-container">
        <div className="map-container ">
          {mapProps && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: GOOGLE_API_KEY, language: "en" }}
              defaultCenter={mapProps}
              defaultZoom={defaultProps?.zoom}
            >
              <div key={12} lat={mapProps?.lat} lng={mapProps?.lng}>
                <img style={markerStyle} src={pin} alt="pin" />
              </div>
            </GoogleMapReact>
          )}
        </div>
        <div className="profile-container">
          <div className="profile-title">
            <h4>Gallery</h4>
            <Button variant="secondary" style={{ backgroundColor: "#00446B" }} className="px-3">
              {merchant?.images?.length}
            </Button>
          </div>

          <div style={{ display: "flex" }}>
            {merchant?.images?.length > 0 ? (
              <>
                {/* <div className="image1">
                  <ReactPhotoCollage {...setting1} />
                </div> */}
                <div className="image2">
                  <ReactPhotoCollage {...setting2} />
                </div>
              </>
            ) : (
              <h3>No More Images</h3>
            )}
          </div>
          <div className="profile-about">
            <h4>About</h4>
            <div style={{ whiteSpace: "pre-wrap" }}>{merchant?.description}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <SeachbarAndPoolBarForSmallScreen />
      <SeachbarAndPoolBarForBigScreen />
      <section className={` overflow-hidden ${windowDimensions.width > 768 ? "mt-5" : "mt-0"}`}>
        <div className={`container ${windowDimensions.width > 768 ? "pt-2 my-5" : "my-4"}`}>
          <Card className={`bg-white p-4 shadow`}>
            {renderFirstRow()}
            {loading ? <Loader /> : RenderSecondRow()}
          </Card>
        </div>
      </section>
    </>
  );
}

export default MerchantProfileInfo;
