import { useContext, useEffect, useRef, useState } from "react";
import { Badge, Button, Card, Col, Container, Form, Image, OverlayTrigger, Popover, Row } from "react-bootstrap";

import MySpinner from "../../common/MySpinner";
import { toast } from "react-toastify";
import DashboardContainer from "../components/DashboardContainer";
import PageTitle from "../components/PageTitle";
import AdminContext from "../../../context/admin-context";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useNavigate } from "react-router-dom";
import adminService from "../../../services/admin/adminServices";

const options = {
  onSizePerPageChange: (sizePerPage, page) => {},
  onPageChange: (page, sizePerPage) => {},
};

function AdminAddCategories() {
  const { authUser } = useContext(AdminContext);
  const categoryImageRef = useRef();
  const [categoryList, setCategoryList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [category, setCategory] = useState("");
  const [categoryImage, setCategoryImage] = useState(null);
  const [mode, setMode] = useState("add");
  const [imagePreview, setImagePreview] = useState(false);
  const navigate = useNavigate();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const makeInactive = async (e, id) => {
    e.preventDefault();
    const data = await adminService.deactivateCategory(id);
    if (data.success) {
      getCategoriesList();
      toast.success("Category updated successfully");
    } else {
      toast.error(data.message);
    }
  };

  const columns = [
    {
      dataField: "rowIndex",
      text: "#",
      formatter: (cell, row, rowIndex, colIndex) => `${cell}`,
    },
    {
      text: "Category Name",
      formatter: (cell, row, rowIndex, colIndex) => <span>{row?.name}</span>,
    },
    {
      dataField: "image",
      text: "Image",
      formatter: (cell, row, rowIndex, colIndex) => <img src={row?.image} style={{ height: 40, width: "auto" }} />,
    },
    {
      text: "Status",
      dataField: "status",
      formatter: (cell, row, rowIndex, colIndex) => (
        <span>
          <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose={true}
            overlay={
              <Popover>
                <Popover.Body>
                  <a href="#" onClickCapture={(e) => makeInactive(e, row?.id)}>
                    make {row.status == "active" ? "inactive" : "active"}
                  </a>
                </Popover.Body>
              </Popover>
            }
          >
            <Badge className="hover-cursor" bg={row?.status == "active" ? "success" : "danger"}>
              {row?.status}
            </Badge>
          </OverlayTrigger>
        </span>
      ),
    },
    {
      text: "Action",
      formatter: (cell, row, rowIndex, colIndex) => (
        <span
          style={{ cursor: "pointer" }}
          className="fas fa-pencil-alt"
          onClick={() => handleEditClick(row?.id, row?.name, row?.image)}
        ></span>
      ),
    },
  ];

  const handleEditClick = (id, name, image) => {
    setMode("edit");
    setImagePreview(true);
    setCategoryId(id);
    setCategory(name);
    setCategoryImage(image);
  };
  const noData = () => <Container className="d-flex justify-content-center">No data Found</Container>;
  const handleReset = () => {
    setMode("add");
    setLoader(false);
    setCategory("");
    categoryImageRef.current.value = "";
    setImagePreview(false);
    setCategoryImage(null);
  };
  const saveCategories = async () => {
    setLoader(true);
    const params = new FormData();
    params.append("category_level", "level0");
    params.append("name", category);
    params.append("image", categoryImage);
    const data = await adminService.addCategory(params);
    if (data.success) {
      toast.success(data.message);
      getCategoriesList();
    } else {
      toast.error(data.message);
    }
    setLoader(false);
  };
  const updateCategories = async () => {
    setLoader(true);
    const params = new FormData();
    params.append("id", categoryId);
    params.append("name", category);
    params.append("image", categoryImage);
    const data = await adminService.updateCategory(params);
    if (data.success) {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
    setLoader(false);
  };
  const loading = () => (
    <div
      className="d-flex justify-content-center align-items-center mb-5"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  const handleInputChange = (e) => {
    setCategory(e.target.value.replace(/[^a-zA-Z0-9-\-\ \']/g, ""));
  };
  const handleCategoryImageChange = (e) => {
    e.preventDefault();
    setImagePreview(false);
    let files = e.target.files[0];
    if (files) {
      setCategoryImage(files);
    }
  };
  const categoryFormSection = () => (
    <form onSubmit={handleFormSubmit}>
      <Row className="g-2 justify-content-center align-items-center">
        <Col sm={12} md={4} lg={4}>
          <input
            type="text"
            className="form-control"
            placeholder="Category Name"
            onChange={handleInputChange}
            value={category}
          />
        </Col>
        <Col sm={12} md={4} lg={4} className="d-flex ">
          <Form.Group>
            <input
              ref={categoryImageRef}
              accept="image/*"
              type="file"
              className="form-control"
              onChange={handleCategoryImageChange}
            />
          </Form.Group>
          {mode == "edit" && imagePreview && (
            <img className="mx-4" src={categoryImage} style={{ height: 40, width: "auto" }} />
          )}
        </Col>
        <Col sm={12} md={4} lg={4}>
          <button disabled={loader} type="submit" className="btn btn-success">
            {mode == "edit" ? "Update Category" : "Add Category"}
          </button>
          {mode == "edit" && (
            <button disabled={loader} className="ms-4 btn btn-secondary" onClick={handleReset}>
              Cancel
            </button>
          )}
        </Col>
      </Row>
    </form>
  );

  const categoryListSection = () => {
    return categoryList.length > 0 ? (
      <BootstrapTable
        keyField="_id"
        data={categoryList}
        columns={columns}
        pagination={paginationFactory({ page: currentPageNumber, onPageChange: setCurrentPageNumber })}
        striped
        bordered={false}
        wrapperClasses="table-responsive"
      />
    ) : (
      noData()
    );
  };

  const getCategoriesList = async () => {
    setListLoader(true);
    const allCategories = await adminService.getCategories(0);
    if (allCategories.success)
      setCategoryList(allCategories.data.map((item, index) => ({ ...item, rowIndex: index + 1 })));
    else toast.error(allCategories.message);
    setListLoader(false);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (mode === "add") {
      category && saveCategories();
    } else if (mode === "edit") {
      category && categoryImage && updateCategories();
    }
  };
  useEffect(() => {
    getCategoriesList();
    return setCategoryList([]);
  }, []);

  return (
    <DashboardContainer>
      <PageTitle title={"Parent Categories"} />
      <Card body className="shadow py-2">
        {loader && loading()}
        {categoryFormSection()}
      </Card>
      <Card body className="shadow py-2 mt-4">
        {listLoader ? loading() : categoryListSection()}
      </Card>
    </DashboardContainer>
  );
}

export default AdminAddCategories;
