/* global google */
import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import {
  ADD_MERCHANT,
  BASE_URL,
  CATEGORY_LIST_ADMIN,
  MERCHANT_CHANGE_PASSWORD_ADMIN,
  MERCHANT_DETAIL,
  STATE_LIST,
  UPDATE_MERCHANT,
} from "../../../config/endpoints";
import myAxios from "../../../config/my-axios";
import MySpinner from "../../common/MySpinner";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ImageCropper from "../../common/ImageCropper";
import "cropperjs/dist/cropper.css";
import { loadGMaps } from "../../../config/helper";
import AddMerchantKeywords from "./AddMerchantKeywords";
import DashboardContainer from "../components/DashboardContainer";
import PageTitle from "../components/PageTitle";
import MerchantWorkingHours from "../../merchant/Pages/MerchantWorkingHours";
import styles from "../../web/Pages/ForgotPassword.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Input, Select } from "antd";
import commonService from "../../../services/common/commonService";
import MerchantImages from "./MerchantImages";
const { Option } = Select;

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

export default function AddMerchant() {
  let initObj = {
    name: "",
    businessName: "",
    description: "",
    phone: "",
    email: "",
    locationAddress: "",
    address: "",
    address2: "",
    zipcode: "",
    city: "",
    state: "",
    logo: null,
    logoUrl: "",
    finalImage: null,
    finalImageUrl: "",
    category: "",
    childCategory: "",
    website: "",
    categories: [],
    Sunday: "",
    Monday: "",
    Tuesday: "",
    Wednesday: "",
    Thursday: "",
    Friday: "",
    Saturday: "",
  };
  let initResetPasswordObj = {
    newPassword: "",
    confirmPassword: "",
  };
  const { id } = useParams();
  const addressRef = useRef();
  const navigate = useNavigate();
  const [resetForm, setResetForm] = useState(initResetPasswordObj);
  const [form, setForm] = useState(initObj);
  const [address, setAddress] = useState({
    lat: "",
    lng: "",
    formatted_address: "",
  });
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [chkDigit, setChkDigit] = useState(false);
  const [chkLower, setChkLower] = useState(false);
  const [chkUpper, setChkUpper] = useState(false);
  const [chkSpecialChar, setChkSpecialChar] = useState(false);
  const [chkChar, setChkChar] = useState(false);
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [error, setError] = useState({ ...initObj });
  const [load, setLoad] = useState(true);
  const [modal, showModal] = useState(false);
  const [activeTab, setActiveTab] = useState("merchantBusinessProfile");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [WebsitePrefix, setWebsitePrefix] = useState("http://");
  const [facebookPrefix, setFacebookPrefix] = useState("http://");
  const [twitterPrefix, setTwitterPrefix] = useState("http://");
  const [linkedInPrefix, setLinkedInPrefix] = useState("http://");
  const [data, setData] = useState({
    states: [],
    categories: [],
  });
  const loading = () => (
    <div
      className="d-flex justify-content-center align-items-center mb-5"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  function handleForm(event) {
    if (event.target.name === "businessName") {
      setForm({
        ...form,
        [event.target.name]: event.target.value.replace(/[^a-zA-Z0-9-\-\ \']/g, ""),
      });
    } else {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      });
    }
  }
  function handleWebsite(event) {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  }

  function initMaps() {
    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
      // types: ["establishment"],
      types: [],
      componentRestrictions: {
        country: "us",
      },
    };

    const autocomplete = new google.maps.places.Autocomplete(addressRef.current, options);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      setAddress({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        formatted_address: place.formatted_address,
      });
    });

    // setForm({
    //   ...form,
    //   locationAddress: placeData,
    // });
  }

  function handleImage(e) {
    setForm({
      ...form,
      logo: e.target.files[0],
      logoUrl: URL.createObjectURL(e.target.files[0]),
    });
    showModal(true);
  }

  function handleZip(e) {
    if (isNaN(e.target.value) || e.target.value.length > 8) return;
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }

  function handlePhone(e) {
    let value = e.target.value;
    if (isNaN(value) || value.length > 14) return;
    setForm({
      ...form,
      [e.target.name]: value,
    });
  }

  function submitForm(e) {
    e.preventDefault();
    const config = { headers: { "content-type": "multipart/form-data" } };
    let formData = new FormData();
    formData.append("name", form.name);
    formData.append("description", form.description);
    formData.append("businessName", form.businessName);
    formData.append("address", form.address);
    formData.append("address2", form.address2);
    if (address.formatted_address) formData.append("locationAddress", address.formatted_address);
    if (address.lat) formData.append("latitude", address.lat);
    if (address.lng) formData.append("longitude", address.lng);
    formData.append("city", form.city);
    formData.append("zipcode", form.zipcode);
    formData.append("state", form.state);
    formData.append("phone", form.phone);
    formData.append("email", form.email);
    if (form.website) formData.append("website", WebsitePrefix + form.website);
    if (form.facebook) formData.append("facebook", facebookPrefix + form.facebook);
    if (form.twitter) formData.append("twitter", twitterPrefix + form.twitter);
    if (form.linkedin) formData.append("linkedin", linkedInPrefix + form.linkedin);
    if (form.category) formData.append("category", form.category.id);
    if (form.childCategory) formData.append("childCategory", form.childCategory.id);
    if (form.categories) formData.append("categories", JSON.stringify(form.categories.map((el) => el?.id)));
    if (id) formData.append("id", id);
    if (form.logo) {
      // formData.append("logo", form.logo, form.logo.name);
      formData.append("logo", form.finalImage, "logo.png");
    }

    let workingHours = [
      {
        day: 6,
        hours: form.Sunday,
      },
      {
        day: 0,
        hours: form.Monday,
      },
      {
        day: 1,
        hours: form.Tuesday,
      },
      {
        day: 2,
        hours: form.Wednesday,
      },
      {
        day: 3,
        hours: form.Thursday,
      },
      {
        day: 4,
        hours: form.Friday,
      },
      {
        day: 5,
        hours: form.Saturday,
      },
    ];
    if (workingHours) formData.append("workingHours", JSON.stringify(workingHours));

    if (!form?.logo && !form.finalImageUrl) {
      return toast.error("Please provide logo.");
    }
    if (!form?.category) {
      return toast.error("Please provide categories.");
    }
    if (!address.formatted_address) {
      return toast.error("Please insert location address in userProfile section.");
    }
    setLoad(true);
    let url = id ? UPDATE_MERCHANT : ADD_MERCHANT;
    myAxios
      .post(url, formData, config)
      .then((res) => {
        let data = res.data;

        if (data.success) {
          toast.success("Merchant saved successfully");
          if (!id) {
            navigate("/admin/merchants", { replace: true });
          } else {
            setError({ ...initObj });
            setLoad(false);
          }
        } else {
          setError({
            ...initObj,
            ...data.data,
          });
          toast.error(data.message);
          setLoad(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoad(false);
      });
  }

  function loadCategories(states) {
    let filter = { child: true, active: true };
    myAxios
      .post(CATEGORY_LIST_ADMIN, { filter })
      .then((r) => {
        let res = r.data;
        if (res.success) {
          setData({
            ...data,
            states,
            categories: res.data,
          });
        }
      })
      .catch((er) => {});
  }
  // const loadCategories = async () => {
  //   const data = await commonService.getHierarchyCategories();
  // };

  useEffect(() => {
    loadGMaps(() => {
      initMaps();
    });
    axios
      .get(STATE_LIST)
      .then((res) => {
        if (res.data.success) {
          loadCategories(res.data.data.states);
          loadMerchant();
        } else {
          toast.error(res.data.message);
          setLoad(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoad(false);
      });
  }, []);

  function loadMerchant() {
    if (id) {
      let populate = ["categories"];
      myAxios
        .post(MERCHANT_DETAIL, { id, populate })
        .then((res) => {
          let data = res.data;

          if (data.success) {
            let merchant = data.data;
            setForm({
              ...merchant,
              logo: null,
              logoUrl: "",
              finalImageUrl: merchant.logo,
              facebook: merchant.socialLinks.facebook.replace("https://", "").replace("http://", "") || "",
              twitter: merchant.socialLinks.twitter.replace("https://", "").replace("http://", "") || "",
              linkedin: merchant.socialLinks.linkedin.replace("https://", "").replace("http://", "") || "",
              website: merchant?.website?.replace("https://", "").replace("http://", ""),
              // categories: merchant.categories.map((el) => ({
              //   label: el.name,
              //   value: el.id,
              // })),
            });

            merchant.website.length && setWebsitePrefix(new URL(merchant.website).protocol + "//");
            merchant?.socialLinks?.facebook.length &&
              merchant?.socialLinks?.facebook.includes("http") &&
              setFacebookPrefix(new URL(merchant?.socialLinks?.facebook).protocol + "//");
            merchant?.socialLinks?.linkedin.length &&
              merchant?.socialLinks?.linkedin.includes("http") &&
              setLinkedInPrefix(new URL(merchant?.socialLinks?.linkedin).protocol + "//");
            merchant?.socialLinks?.twitter.length &&
              merchant?.socialLinks?.twitter.includes("http") &&
              setTwitterPrefix(new URL(merchant?.socialLinks?.twitter).protocol + "//");

            setAddress({
              lat: merchant?.location?.coordinates[1],
              lng: merchant?.location?.coordinates[0],
              formatted_address: merchant.locationAddress,
            });
            setLoad(false);
          } else {
            toast.error(data.message);
            setLoad(false);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setLoad(false);
        });
    } else {
      setLoad(false);
    }
  }
  function handleCroppedImage(croppedImage) {
    setForm({
      ...form,
      finalImage: croppedImage,
      finalImageUrl: URL.createObjectURL(croppedImage),
    });
    showModal(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await commonService.getHierarchyCategories();
      if (data.length) {
        setCategories(data.filter((item) => item.status === "active"));
      }
    };
    fetchData();
  }, []);

  const merchantBusinessProfileForm = () => {
    const websiteBefore = (
      <Select value={WebsitePrefix} className="select-before" onChange={(e) => setWebsitePrefix(e)}>
        <Option value="http://">http://</Option>
        <Option value="https://">https://</Option>
      </Select>
    );
    const linkedInBefore = (
      <Select value={linkedInPrefix} className="select-before" onChange={(e) => setLinkedInPrefix(e)}>
        <Option value="http://">http://</Option>
        <Option value="https://">https://</Option>
      </Select>
    );
    const facebookBefore = (
      <Select value={facebookPrefix} className="select-before" onChange={(e) => setFacebookPrefix(e)}>
        <Option value="http://">http://</Option>
        <Option value="https://">https://</Option>
      </Select>
    );
    const twitterBefore = (
      <Select value={twitterPrefix} className="select-before" onChange={(e) => setTwitterPrefix(e)}>
        <Option value="http://">http://</Option>
        <Option value="https://">https://</Option>
      </Select>
    );
    return (
      <Form id="merchantForm" onSubmit={submitForm}>
        <Row>
          {/* merchant Business profile card 1 */}
          <Col md="6">
            <Card body className="mb-4">
              <Form.Group className="mb-3">
                <label className="text-muted">Category</label>
                <Select
                  // mode="multiple"
                  // allowClear
                  style={{ width: "100%" }}
                  value={form?.category?.name}
                  placeholder="Please select Category"
                  onChange={(e, data, val) => {
                    setSubCategories(data?.data?.children.filter((item) => item.status === "active"));
                    setForm({
                      ...form,
                      category: { id: data.value, name: data.children },
                      childCategory: "",
                      categories: [],
                    });
                  }}
                >
                  {categories.map((category, index) => (
                    <Option data={category} key={category._id}>
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <label className="text-muted">Sub Categories</label>
                <Select
                  disabled={!subCategories?.length}
                  // allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select subCategory"
                  value={form?.childCategory?.name}
                  onChange={(e, data, val) => {
                    setSubSubCategories(data?.data?.children.filter((item) => item.status === "active"));

                    setForm({
                      ...form,
                      childCategory: { id: data.value, name: data.children },
                      categories: [],
                    });
                  }}
                >
                  {subCategories.map((category, index) => (
                    <Option data={category} key={category._id}>
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <label className="text-muted">Sub Sub Categories</label>
                <Select
                  mode="multiple"
                  // allowClear
                  disabled={!subSubCategories?.length}
                  style={{ width: "100%" }}
                  placeholder="Please select sub subCategories"
                  value={form?.categories?.map((item) => item?.name)}
                  onChange={(e, data, val) => {
                    setForm({
                      ...form,
                      categories: [...form.categories, { id: data[0].value, name: data[0].children } || ""],
                    });
                  }}
                >
                  {subSubCategories.map((category, index) => (
                    <Option data={category} key={category._id}>
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </Form.Group>

              {/* <Form.Group className="mb-3">
            <label className="text-muted">Contact Name</label>
            <Form.Control
              size="sm"
              name="name"
              value={form.name}
              onChange={handleForm}
            />
            <label className="text-error">{error.name}</label>
          </Form.Group> */}

              <Form.Group className="mb-3">
                <label className="text-muted">Business Name</label>
                <Form.Control size="sm" name="businessName" value={form.businessName} onChange={handleForm} />
                <label className="text-error">{error.businessName}</label>
              </Form.Group>

              <Form.Group className="mb-3">
                <label className="text-muted">Description</label>
                <Form.Control
                  as="textarea"
                  rows="2"
                  size="sm"
                  name="description"
                  value={form.description}
                  onChange={handleForm}
                />
                <label className="text-error">{error.description}</label>
              </Form.Group>

              <Form.Group className="mb-3">
                <label className="text-muted">Website url</label>
                <Input
                  addonBefore={websiteBefore}
                  name="website"
                  value={form?.website?.replace("https://", "").replace("http://", "")}
                  onChange={handleWebsite}
                />
                <Form.Text className="text-danger">Example: www.eazyer.com</Form.Text>
                <label className="text-error">{error.website}</label>
              </Form.Group>

              <Form.Group className="mb-3">
                <label className="text-muted">Facebook</label>
                {/* <Form.Control size="sm" name="facebook" value={form.facebook || ""} onChange={handleForm} /> */}
                <Input
                  addonBefore={facebookBefore}
                  name="facebook"
                  value={form?.facebook?.replace("https://", "").replace("http://", "")}
                  onChange={handleWebsite}
                />
                <Form.Text className="text-danger">Example: www.facebook.com/abc</Form.Text>
                <label className="text-error">{error.facebook}</label>
              </Form.Group>

              <Form.Group className="mb-3">
                {/* <label className="text-muted">Linkedin</label> */}
                <label className="text-muted">Book Online</label>
                <Input
                  addonBefore={linkedInBefore}
                  name="linkedin"
                  value={form?.linkedin?.replace("https://", "").replace("http://", "")}
                  onChange={handleWebsite}
                />
                {/* <Form.Text className="text-danger">Example: www.linkedin.com/abc</Form.Text> */}
                <Form.Text className="text-danger">Example: www.example.com/abc</Form.Text>
                <label className="text-error">{error.linkedin}</label>
              </Form.Group>

              <Form.Group className="mb-3">
                {/* <label className="text-muted">Twitter</label> */}
                <label className="text-muted">Menu</label>
                <Input
                  addonBefore={twitterBefore}
                  name="twitter"
                  value={form?.twitter?.replace("https://", "").replace("http://", "")}
                  onChange={handleWebsite}
                />
                {/* <Form.Text className="text-danger">Example: www.twitter.com/abc</Form.Text> */}
                <Form.Text className="text-danger">Example: www.example.com/abc</Form.Text>
                <label className="text-error">{error.twitter}</label>
              </Form.Group>
            </Card>
          </Col>
          {/* merchant Business profile card 2 */}

          <Col md="6">
            <Card body className="mb-4">
              {/* <Form.Group className="mb-3">
                <label className="text-muted">
                  location Address
                  <span className="text-info">( Necessory for search merchant )</span>
                </label>
                <input
                  defaultValue={form.locationAddress || ""}
                  size="sm"
                  name="locationAddress"
                  className="form-control form-control-sm"
                  id="locationAddress"
                  ref={addressRef}
                />
                <label className="text-error">{error.locationAddress}</label>
                {form.location && form.location.coordinates && (
                  <span className="text-info">
                    {form.location.coordinates[1]} , {form.location.coordinates[0]}
                  </span>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <label className="text-muted">Address line 1</label>
                <Form.Control
                  as="textarea"
                  rows="2"
                  size="sm"
                  name="address"
                  value={form.address}
                  onChange={handleForm}
                />
                <label className="text-error">{error.address}</label>
              </Form.Group>

              <Form.Group className="mb-3">
                <label className="text-muted">Address line 2</label>
                <Form.Control
                  as="textarea"
                  rows="2"
                  size="sm"
                  name="address2"
                  value={form.address2}
                  onChange={handleForm}
                />
                <label className="text-error">{error.address2}</label>
              </Form.Group> */}

              {/* <Form.Group className="mb-3">
            <label className="text-muted">Zip code</label>
            <Form.Control
              size="sm"
              name="zipcode"
              value={form.zipcode}
              onChange={handleZip}
            />
            <label className="text-error">{error.zipcode}</label>
          </Form.Group>

          <Form.Group className="mb-3">
            <label className="text-muted">City</label>
            <Form.Control
              size="sm"
              name="city"
              value={form.city}
              onChange={handleForm}
            />
            <label className="text-error">{error.city}</label>
          </Form.Group>

          <Form.Group className="mb-4">
            <label className="text-muted">State</label>
            <Form.Select
              size="sm"
              name="state"
              value={form.state}
              onChange={handleForm}
            >
              <option value="">-- select state--</option>
              {data.states.map((state, i) => {
                return (
                  <option key={i} value={state.id}>
                    {state.name}
                  </option>
                );
              })}
            </Form.Select>
            <label className="text-error">{error.state}</label>
          </Form.Group> */}

              <Form.Group className="mb-4">
                <label className="text-muted">Featured / Logo image</label>
                <Form.Control type="file" size="sm" name="logo" onChange={handleImage} />
                <Form.Text className="text-danger">Please upload image of resolution (170 x 120)</Form.Text>
                <label className="text-error">{error.logo}</label>
              </Form.Group>

              <div className="text-center">
                <p>Final image preview</p>
                <img
                  src={form.finalImageUrl ? form.finalImageUrl : ""}
                  alt="final image"
                  style={{ width: "50%", height: "auto" }}
                />
              </div>
            </Card>
          </Col>

          <Col md="12" xxl="12">
            {form.logoUrl && (
              <>
                <ImageCropper
                  image={form.logoUrl}
                  width={170}
                  height={120}
                  onCrop={handleCroppedImage}
                  modal={modal}
                  showModal={showModal}
                />
              </>
            )}
          </Col>
          <Col sm="12">
            <Form.Group>
              <Button type="submit" variant="secondary" className="bg-success px-3 mt-3">
                Save
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    );
  };

  const merchantUserProfileForm = () => {
    return (
      <Form id="merchantForm" onSubmit={submitForm}>
        <Row>
          <Col md="6">
            <Card body className="mb-4">
              <Form.Group className="mb-3">
                <label className="text-muted">Contact Name</label>
                <Form.Control size="sm" name="name" value={form.name} onChange={handleForm} />
                <label className="text-error">{error.name}</label>
              </Form.Group>

              <Form.Group className="mb-3">
                <label className="text-muted">Phone</label>
                <InputGroup className="mb-3">
                  <Form.Control size="sm" type="number" name="phone" value={form.phone} onChange={handlePhone} />
                </InputGroup>

                <label className="text-error">{error.phone}</label>
              </Form.Group>

              <Form.Group className="mb-3">
                <label className="text-muted">Email Address</label>
                <Form.Control size="sm" type="email" name="email" value={form.email} onChange={handleForm} />
                <label className="text-error">{error.email}</label>
              </Form.Group>

              <Form.Group className="mb-3">
                <label className="text-muted">Zip code</label>
                <Form.Control size="sm" name="zipcode" value={form.zipcode} onChange={handleZip} />
                <label className="text-error">{error.zipcode}</label>
              </Form.Group>

              <Form.Group className="mb-3">
                <label className="text-muted">City</label>
                <Form.Control size="sm" name="city" value={form.city} onChange={handleForm} />
                <label className="text-error">{error.city}</label>
              </Form.Group>

              <Form.Group className="mb-4">
                <label className="text-muted">State</label>
                <Form.Select size="sm" name="state" value={form.state} onChange={handleForm}>
                  <option value="">-- select state--</option>
                  {data.states.map((state, i) => {
                    return (
                      <option key={i} value={state.id}>
                        {/* {state.name} */}
                        {state.abbreviation}
                      </option>
                    );
                  })}
                </Form.Select>
                <label className="text-error">{error.state}</label>
              </Form.Group>
            </Card>
          </Col>
          <Col md="6">
            <Card body className="mb-4">
              <Form.Group className="mb-3">
                <label className="text-muted">
                  location Address
                  <span className="text-info">( Necessary for search merchant )</span>
                </label>
                <input
                  defaultValue={form.locationAddress || ""}
                  size="sm"
                  name="locationAddress"
                  className="form-control form-control-sm"
                  id="locationAddress"
                  ref={addressRef}
                />
                <label className="text-error">{error.locationAddress}</label>
                {form.location && form.location.coordinates && (
                  <span className="text-info">
                    {form?.location?.coordinates[1]} , {form?.location?.coordinates[0]}
                  </span>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <label className="text-muted">Address line 1</label>
                <Form.Control
                  as="textarea"
                  rows="2"
                  size="sm"
                  name="address"
                  value={form.address}
                  onChange={handleForm}
                />
                <label className="text-error">{error.address}</label>
              </Form.Group>

              <Form.Group className="mb-3">
                <label className="text-muted">Address line 2</label>
                <Form.Control
                  as="textarea"
                  rows="2"
                  size="sm"
                  name="address2"
                  value={form.address2}
                  onChange={handleForm}
                />
                <label className="text-error">{error.address2}</label>
              </Form.Group>

              {/* <Form.Group className="mb-3">
                <label className="text-muted">Zip code</label>
                <Form.Control size="sm" name="zipcode" value={form.zipcode} onChange={handleZip} />
                <label className="text-error">{error.zipcode}</label>
              </Form.Group> */}
              {/* 
              <Form.Group className="mb-3">
                <label className="text-muted">City</label>
                <Form.Control size="sm" name="city" value={form.city} onChange={handleForm} />
                <label className="text-error">{error.city}</label>
              </Form.Group> */}

              {/* <Form.Group className="mb-4">
                <label className="text-muted">State</label>
                <Form.Select size="sm" name="state" value={form.state} onChange={handleForm}>
                  <option value="">-- select state--</option>
                  {data.states.map((state, i) => {
                    return (
                      <option key={i} value={state.id}>
                        {state.name}
                      </option>
                    );
                  })}
                </Form.Select>
                <label className="text-error">{error.state}</label>
              </Form.Group> */}
            </Card>
          </Col>

          <Col md="12" xxl="12">
            {form.logoUrl && (
              <>
                <ImageCropper
                  image={form.logoUrl}
                  width={170}
                  height={120}
                  onCrop={handleCroppedImage}
                  modal={modal}
                  showModal={showModal}
                />
              </>
            )}
          </Col>
          <Col sm="12">
            <Form.Group>
              <Button type="submit" variant="secondary" className="bg-success px-3 mt-3">
                Save
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    );
  };

  function resetPassword() {
    const v1 = PWD_REGEX.test(resetForm.newPassword);
    const v2 = PWD_REGEX.test(resetForm.confirmPassword);
    if (!v1 || !v2) {
      toast.error("Please enter your new password");
      return;
    }
    const params = {
      password: resetForm?.newPassword,
      confirm: resetForm?.confirmPassword,
      merchantId: id,
    };
    setLoad(true);
    let url = MERCHANT_CHANGE_PASSWORD_ADMIN;
    myAxios
      .post(url, params)
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          setResetForm(initResetPasswordObj);
          setLoad(false);
        } else {
          toast.error(res.data.message);
          setLoad(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoad(false);
      });
  }

  const handlePwdVal = (e) => {
    setResetForm({
      ...resetForm,
      [e.target.name]: e.target.value,
    });
  };

  const submitNewPassWord = () => (
    <>
      <div className="row gy-4 pt-3 px-4">
        <div className="col-12">
          <input
            autoComplete="off"
            type="password"
            name="newPassword"
            value={resetForm.newPassword}
            onChange={handlePwdVal}
            className="form-control"
            placeholder="New Password*"
            aria-invalid={validPwd ? "false" : "true"}
            aria-describedby="pwdnote"
            onFocus={() => setPwdFocus(true)}
            onBlur={() => setPwdFocus(false)}
          />
        </div>
      </div>
      <div className="row gy-4 pt-3 px-4">
        <div className="col-12">
          <input
            autoComplete="off"
            type="password"
            name="confirmPassword"
            value={resetForm.confirmPassword}
            onChange={handlePwdVal}
            className="form-control"
            placeholder="Confirm Password*"
            aria-invalid={validPwd ? "false" : "true"}
            aria-describedby="cnfpwdnote"
            onFocus={() => setMatchFocus(true)}
            onBlur={() => setMatchFocus(false)}
          />
        </div>
      </div>
      <div className="row gy-4 py-4 px-4 text-center">
        <div id="pwdnote" className={`row ${pwdFocus && !validPwd ? styles.instructions : styles.offscreen}`}>
          <div className="row">
            <div className="col-12 col-md-12">
              Must include Uppercase letters.{" "}
              {chkUpper ? (
                <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
              ) : (
                <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
              )}
            </div>
            <div className="col-12 col-md-12">
              Must include lowercase letters.{" "}
              {chkLower ? (
                <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
              ) : (
                <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              At Least 1 number.{" "}
              {chkDigit ? (
                <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
              ) : (
                <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
              )}
            </div>
            <div className="col-12 col-md-12">
              Allowed special characters: <span aria-label="exclamation mark">!</span>{" "}
              <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span>{" "}
              <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>{" "}
              {chkSpecialChar ? (
                <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
              ) : (
                <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              Minimum 8 characters.{" "}
              {chkChar ? (
                <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
              ) : (
                <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
              )}
            </div>
            <div className={`col-12 col-md-12 ${validMatch ? "" : styles.offscreen}`}>
              Password Match.{" "}
              {validMatch ? (
                <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
              ) : (
                <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
              )}
            </div>
          </div>
        </div>
        <div id="cnfpwdnote" className={`row ${matchFocus && !validMatch ? styles.instructions : styles.offscreen}`}>
          <div className="row">
            <div className="col-12 col-md-12">
              Must match the first password input field.{" "}
              {validMatch ? (
                <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
              ) : (
                <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
              )}
            </div>
          </div>
        </div>
        <div className="col-12 text-center">
          <button type="button" onClickCapture={resetPassword} className="register-now btn btn-success">
            Reset
          </button>
        </div>
      </div>
    </>
  );

  const merchantResetPassword = () => {
    return (
      <div className="row">
        <div className="col-12 col-md-8">
          <Card body className="mt-2 ">
            {submitNewPassWord()}
          </Card>
        </div>
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <Tabs
        defaultActiveKey="merchantBusinessProfile"
        id="merchantProfile"
        className="mb-3 d-flex align-items-center"
        style={{
          backgroundColor: "none",
          borderColor: "none",
          flex: 1,
          overflow: "hidden",
          overflowX: "auto",
        }}
        onSelect={(k) => setActiveTab(k)}
      >
        <Tab
          eventKey="merchantBusinessProfile"
          title={
            <p className={activeTab == "merchantBusinessProfile" ? `btn btn-success` : `text-success`}>
              Merchant Business Profile
            </p>
          }
        >
          {merchantBusinessProfileForm()}
        </Tab>
        <Tab
          eventKey="merchantUserProfile"
          title={
            <p className={activeTab == "merchantUserProfile" ? `btn btn-success` : `text-success`}>
              Merchant User Profile
            </p>
          }
        >
          {merchantUserProfileForm()}
        </Tab>
        <Tab
          eventKey="merchantBusinessHours"
          title={
            <p className={activeTab == "merchantBusinessHours" ? `btn btn-success` : `text-success `}>
              Merchant Working Hours
            </p>
          }
        >
          <MerchantWorkingHours
            merchantId={form?.id}
            customStyle={true}
            loadMerchant={loadMerchant}
            workingHours={form?.workingHours}
          />
        </Tab>
        <Tab
          eventKey="gallery"
          title={<p className={activeTab == "gallery" ? `btn btn-success` : `text-success`}>Manage Gallery</p>}
        >
          <MerchantImages merchantId={form?.id} />
        </Tab>

        <Tab
          eventKey="merchantKeywords"
          title={
            <p className={activeTab == "merchantKeywords" ? `btn btn-success` : `text-success`}>Manage Keywords</p>
          }
        >
          <AddMerchantKeywords merchantId={form?.id} keywordString={form?.keywordString} />
        </Tab>

        <Tab
          eventKey="merchantResetPassword"
          title={
            <p className={activeTab == "merchantResetPassword" ? `btn btn-success` : `text-success`}>Reset Password</p>
          }
        >
          {merchantResetPassword()}
        </Tab>
      </Tabs>
    );
  };

  return (
    <DashboardContainer>
      <PageTitle
        title={id ? "Edit merchant" : "Add merchant"}
        rightSide={
          <>
            <div className="d-flex  align-items-start">
              <Link to={`/admin/merchants`}>
                <Button size="sm" variant="secondary" className="btn-success px-3 ">
                  go back
                </Button>
              </Link>
            </div>
          </>
        }
      />
      <div>
        {load && loading()}
        <div className="d-flex flex-column justify-content-center">{renderTabs()}</div>
      </div>
    </DashboardContainer>
  );
}
