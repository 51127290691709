import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import WebContext from "../../../context/web-context";
import Footer from "./Footer";
import NavBar from "./NavBar";
import { LOGGED_USER } from "../../../config/constants";
import MyScrollToTop from "../../common/MyScrollToTop";
import { toast } from "react-toastify";
import {
  COUNTRY_TO_SEARCH,
  defaultLat,
  defaultLnt,
  DEFAULT_LOCATION,
  GOOGLE_API_KEY,
} from "../../common/googlePlaceApiKey";
import Autocomplete from "react-google-autocomplete";
import { Button, Modal } from "react-bootstrap";
import { faLocation, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import merchantService from "../../../services/merchant/merchantService";
import "./MainLayout.css";

function MainLayout() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const user = localStorage.getItem(LOGGED_USER) || sessionStorage.getItem(LOGGED_USER);
  const iUser = user ? JSON.parse(user) : null;
  const [authUser, setAuthUser] = useState(iUser);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [merchantLoader, setMerchantLoader] = useState(false);
  const [searchMerchant, setSearchMerchant] = useState([]);
  const [userLat, setLat] = useState(null);
  const [userLng, setLng] = useState(null);
  const [userLocationStatus, setStatus] = useState(false);
  const [categoryInput, setCategoryInput] = useState("");

  const [categoryLoading, setCategoryLoading] = useState(false);
  const [useUserLocation, setUseUserLocation] = useState(false);
  const [useLoggedInuserLocation, setUseLoggedInuserLocation] = useState(authUser ? true : false);
  const [poolValue, setPoolValue] = useState({});

  const [queryData, setQueryData] = useState({
    lat: () => defaultLat,
    lng: () => defaultLnt,
  });
  const [isPlaceSelected, setIsPlaceSelected] = useState(false);
  const [showUserLocationButton, setShowUserLocationButton] = useState(true);
  const [showSearchBarModal, setShowSearchBarModal] = useState(false);
  const navigate = useNavigate();

  const handleSearchBarModalClose = () => setShowSearchBarModal(false);
  const handleSearchBarModalShow = () => setShowSearchBarModal(true);

  const handleSearchBarField = () => {
    // if (userLocationStatus && showUserLocationButton) {
    if (isAllow) {
      return <div className="col-10">{autoCompleteInputField("", false)}</div>;
    } else {
      return <div className="col-12">{autoCompleteInputField("", false)}</div>;
    }
  };

  const handleSearchBarFieldLocationButton = () => {
    // if (userLocationStatus && showUserLocationButton) {
    if (isAllow) {
      return (
        <div className="col-2 d-flex justify-content-center align-items-center">
          <span style={{ cursor: "pointer" }} onClick={() => handleSetCurrentLocation()}>
            <FontAwesomeIcon fontSize={20} icon={faLocation} />
          </span>
        </div>
      );
    }
  };

  const searchBarModal = () => {
    return (
      <Modal show={showSearchBarModal} onHide={handleSearchBarModalClose}>
        <form action="" onSubmit={handleSearchBarFormSubmit}>
          <Modal.Body>
            {handleCategorySearchBarField()}
            <div className="row gx-2">
              {handleSearchBarField()}
              {/* {handleSearchBarClearLocationFieldButton()} */}
              {handleSearchBarFieldLocationButton()}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSearchBarModalClose}>
              Close
            </Button>
            <Button variant="secondary" className="btn btn-success" type="submit">
              Search
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  };

  // const [value, setValue] = useState();
  // useEffect(() => {
  //   setValue(
  //     authUser?.location
  //       ? authUser?.location?.address
  //       : isPlaceSelected
  //       ? selectedPlaceName
  //       : userLocationStatus
  //       ? "Current Location"
  //       : DEFAULT_LOCATION
  //   );
  // }, [authUser, isPlaceSelected, userLocationStatus]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const handleUpdateDropdownValue = () => {
    function initAutocomplete() {
      document.addEventListener("DOMNodeInserted", function (event) {
        var target = event.target;
        if (target?.classList?.contains("pac-item")) {
          target.innerHTML = target.innerHTML.replace(/, USA<\/span>$/, "</span>");
        }
        //Todo
        // else {
        //   target.innerHTML = target.innerHTML.replace(/USA<\/span>$/, "</span>");
        // }
      });
    }
    initAutocomplete();
  };

  const [keyword, setKeyword] = useState("");
  const [selectedKeyword, setSelectedKeyword] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [location, setLocation] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locationAddress, setLocationAddress] = useState({ lat: "", lng: "" });
  const [userLocationAddress, setUserLocationAddress] = useState({ lat: "", lng: "" });
  const [isAllow, setIsAllow] = useState(false);

  const handleSetCurrentLocation = () => {
    setLocation("Current Location");
    setSelectedLocation("Current Location");
    setLocationAddress(userLocationAddress);
  };

  useEffect(() => {
    const data = JSON.parse(localStorage?.getItem("params")) || {};

    if (!!authUser) {
      // debugger;
      if (Object?.keys(data)?.length) {
        setLocation(data?.searchString);
        setSelectedLocation(data?.searchString);
        setLocationAddress({ lat: data?.latitude, lng: data?.longitude });
        setSelectedKeyword(data?.keyword);
      } else {
        //if user login then selectedLocation is user own address
        setLocation(authUser?.location?.address);
        setSelectedLocation(authUser?.location?.address);
        setLocationAddress({ lat: authUser?.location?.latitude, lng: authUser?.location?.longitude });
      }
    } else if (isAllow && userLocationAddress) {
      if (Object?.keys(data)?.length) {
        setLocation(data?.searchString ? data?.searchString : "Current Location");
        setSelectedLocation(data?.searchString ? data?.searchString : "Current Location");
        setLocationAddress({ lat: userLocationAddress.lat, lng: userLocationAddress.lng });
        setSelectedKeyword(data?.keyword);
      } else {
        // if user allow location access
        setLocation("Current Location");
        setSelectedLocation("Current Location");
        setLocationAddress({ lat: userLocationAddress.lat, lng: userLocationAddress?.lng });
      }
    } else {
      if (Object?.keys(data)?.length) {
        // debugger;
        setLocation(data?.searchString);
        setSelectedLocation(data?.searchString);
        setLocationAddress({ lat: data?.latitude, lng: data?.longitude });
        setSelectedKeyword(data?.keyword);
      } else {
        // debugger;
        setLocation(DEFAULT_LOCATION);
        setSelectedLocation(DEFAULT_LOCATION);
        // setLocationAddress({ lat: defaultLat, lng: defaultLat });
        setLocationAddress({ lat: "", lng: "" });
      }
    }
  }, [authUser, isAllow, userLocationAddress]);

  const handleSearchCategory = async (query) => {
    setCategoryLoading(true);
    const params = {
      keyword: query,
    };
    const data = await merchantService.getSearchByCategoryKeyword(params);
    if (data.success) {
      let options = [];
      data?.data?.keywords?.map((data) =>
        options.push({
          id: data._id,
          label: data.keyword,
        })
      );
      setCategoryOptions(options);
    } else {
      // toast.error(data?.message);
    }
    setCategoryLoading(false);
  };
  const searchCategory = (category) => {
    if (category.length >= 3) {
      handleSearchCategory(category);
    }
    setCategoryInput(category);
    setKeyword(category);
  };
  const handleCategorySearchBarField = (className = "mb-2") => (
    <AsyncTypeahead
      id="category-typeahead"
      className={`${className}`}
      delay={200}
      isLoading={categoryLoading}
      minLength={3}
      defaultInputValue={selectedKeyword}
      placeholder="What can we help you find today?"
      promptText="Searching..."
      value={selectedKeyword}
      onInputChange={(e) => {
        searchCategory(e);
        setSelectedKeyword(e);
      }}
      options={categoryOptions}
      onChange={(query) => {
        query && setCategoryInput(query[0]?.label);
        query && setSelectedKeyword(query[0]?.label);
      }}
    />
  );

  const autoCompleteInputField = (styleClass, applyStyle = true, useApiKey = true) => (
    <Autocomplete
      textInputProps={{
        onChangeText: (text) => {},
      }}
      options={{
        types: ["(regions)"],
        componentRestrictions: {
          country: COUNTRY_TO_SEARCH,
        },
      }}
      enablePoweredByContainer={false}
      value={location}
      className={`${applyStyle ? "py-3" : ""} form-control ${styleClass}`}
      style={{
        backgroundColor: applyStyle ? "transparent" : "",
        border: applyStyle ? "none" : "",
      }}
      apiKey={useApiKey ? GOOGLE_API_KEY : null}
      onPlaceSelected={(place) => {
        setSelectedLocation(place.formatted_address.replace(", USA", ""));
        setLocation(place.formatted_address.replace(", USA", ""));
        setLocationAddress({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
      }}
      // placeholder={useUserLocation ? "Current Location" : "Search By Location"}
      onChange={(e) => {
        setLocation(e.target.value);
        // setLocationAddress({ lat: "", lng: "" }); //set blank lat lng every insert but when we select any result in dropdown then it will be set
        setSelectedLocation(""); //even single back selectedLocation goes to blank
        handleUpdateDropdownValue(); // This function is used to remove USA in dropdown.
      }}
      onFocusCapture={() => {
        handleUpdateDropdownValue(); // This function is used to remove USA in dropdown when we click on input first time.
        setShowUserLocationButton(false);
      }}
      onBlurCapture={() => setShowUserLocationButton(true)}
    />
  );
  const handleSearchBarFormSubmit = async (e) => {
    // debugger;
    e.preventDefault();
    handleSearchBarModalClose();
    let params = {};
    setMerchantLoader(true);
    if (!!authUser) {
      if (!!authUser && !selectedKeyword && selectedLocation) {
        //if user login, Not select any category bydefault they have own address in case they don't have any location then show appleton.
        params = {
          keyword: "",
          latitude: locationAddress.lat || defaultLat,
          longitude: locationAddress.lng || defaultLnt,
        };
      } else if (!!authUser && selectedKeyword && !selectedLocation) {
        //if user login, Select any category but not select any location
        params = {
          // keyword: escape(selectedKeyword),
          keyword: selectedKeyword,
          latitude: "",
          longitude: "",
        };
      } else if (!!authUser && !selectedKeyword && !selectedLocation) {
        //if user login, Don't select category and location
        params = {
          keyword: "",
          latitude: "",
          longitude: "",
        };
      } else if (!!authUser && selectedKeyword && selectedLocation) {
        //if user login, Select category and location
        params = {
          // keyword: escape(selectedKeyword),
          keyword: selectedKeyword,
          latitude: locationAddress.lat,
          longitude: locationAddress.lng,
        };
      }
    } else {
      if (!selectedKeyword && selectedLocation && !isAllow) {
        // if user not select category and select location in case if bydefault appleton select then lat,lng pass of appleton
        // debugger;
        params = {
          keyword: "",
          // latitude: locationAddress.lat || defaultLat,
          // longitude: locationAddress.lng || defaultLnt,
          latitude: locationAddress.lat,
          longitude: locationAddress.lng,
        };
      } else if (selectedKeyword && selectedLocation && !isAllow) {
        //if user select category and location
        params = {
          // keyword: escape(selectedKeyword),
          keyword: selectedKeyword,
          latitude: locationAddress.lat,
          longitude: locationAddress.lng,
        };
      } else if (!selectedKeyword && !selectedLocation && !isAllow) {
        //if user do not select category and location
        params = {
          keyword: "",
          latitude: "",
          longitude: "",
        };
      } else if (selectedKeyword && !selectedLocation && !isAllow) {
        //if user select category but not select any location

        params = {
          // keyword: escape(selectedKeyword),
          keyword: selectedKeyword,
          latitude: "",
          longitude: "",
        };
      } else if (!selectedKeyword && selectedLocation && !isAllow) {
        //if user don't select category but select any location
        params = {
          keyword: "",
          latitude: locationAddress.lat,
          longitude: locationAddress.lng,
        };
      } else if (!selectedKeyword && !selectedLocation && isAllow) {
        //if user don't select category and location but they have access location
        params = {
          keyword: "",
          latitude: "",
          longitude: "",
        };
      } else if (!selectedKeyword && selectedLocation && isAllow) {
        //if user don't select category but they have access location
        // params = {
        //   keyword: "",
        //   latitude: userLocationAddress.lat,
        //   longitude: userLocationAddress.lng,
        // };
        params = {
          keyword: "",
          latitude: locationAddress.lat,
          longitude: locationAddress.lng,
        };
      } else if (selectedKeyword && !selectedLocation && isAllow) {
        //if user select category and they have access location
        params = {
          // keyword: escape(selectedKeyword),
          keyword: selectedKeyword,
          latitude: "",
          longitude: "",
        };
      } else if (selectedKeyword && selectedLocation && isAllow) {
        //if user select category and they have access location
        // params = {
        //   keyword: selectedKeyword,
        //   latitude: userLocationAddress.lat,
        //   longitude: userLocationAddress.lng,
        // };
        params = {
          // keyword: escape(selectedKeyword),
          keyword: selectedKeyword,
          latitude: locationAddress.lat,
          longitude: locationAddress.lng,
        };
      }
    }
    // localStorage.removeItem("params");
    localStorage.setItem("params", JSON.stringify({ ...params, searchString: location }));
    navigate(
      `/MerchantList?lat=${params.latitude}&lng=${params.longitude}&keyword=${params.keyword}&searchString=${location}`
    );

    // const data = await merchantService.getSearchMerchant(params);
    // if (data.success) {
    //   setSearchMerchant(data.data);
    //   navigate(`/MerchantList?lat=${params.latitude}&lng=${params.longitude}&keyword=${categoryInput}`, {
    //     state: { fromSearchBar: true, keyword: categoryInput },
    //   });
    // } else {
    //   toast.error(data.message);
    // }
    setMerchantLoader(false);
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //useEffect for access user location
  useEffect(() => {
    getLocation();

    return () => {
      setShowSearchBar(false);
      localStorage.removeItem("params");
    };
  }, []);

  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus(true);
          // setValue("Current Location");
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);

          ///////////////////////
          setUserLocationAddress({ lat: position.coords.latitude, lng: position.coords.longitude });
          setIsAllow(true);
        },
        () => {
          setStatus(false);
        }
      );
    }
  };
  return (
    <WebContext.Provider
      value={{
        authUser,
        windowDimensions,
        setAuthUser,
        showSearchBar,
        setShowSearchBar,
        searchMerchant,
        setSearchMerchant,
        merchantLoader,
        setMerchantLoader,
        getLocation,
        userLocationStatus,
        handleSearchBarFormSubmit,
        setQueryData,
        categoryInput,
        setCategoryInput,
        useUserLocation,
        setUseUserLocation,
        showUserLocationButton,
        setShowUserLocationButton,
        autoCompleteInputField,
        searchBarModal,
        handleSearchBarModalShow,
        queryData,
        useLoggedInuserLocation,
        handleCategorySearchBarField,
        userLng,
        userLat,
        isPlaceSelected,
        poolValue,
        setPoolValue,
        handleSetCurrentLocation,
        isAllow,
        userLocationAddress,
        selectedKeyword,
        selectedLocation,
        location,
        setSelectedKeyword,
      }}
    >
      {/* <ScrollToTop /> */}
      <MyScrollToTop />
      <div>
        <NavBar />

        {/* <Header /> */}
        <Outlet />
        <Footer />
      </div>
    </WebContext.Provider>
  );
}

export default MainLayout;
