import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import React from "react";
import DashboardContainer from "../components/DashboardContainer";
import PageTitle from "../components/PageTitle";

function MerchantDashboard() {
  return (
    <DashboardContainer>
      <PageTitle title={"Admin Dashboard"} />
      <Card body className="shadow">
        <Container>
          <Row>
            <Col xs={12} md={5} lg={5}>
              <p className="fs-5">Filters:</p>
            </Col>
            <Col xs={12} md={7} lg={7}>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <Row>
                    <Col xs={8} md={8} lg={8}>
                      <p className="fs-6 fw-bold text-success">
                        New Member Signup:
                      </p>
                    </Col>
                    <Col xs={4} md={4} lg={4}>
                      <p className="text-success"> 287</p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <Row>
                    <Col xs={8} md={8} lg={8}>
                      <p className="fs-6 fw-bold text-success">
                        New Member Signup:
                      </p>
                    </Col>
                    <Col xs={4} md={4} lg={4}>
                      <p className="text-success"> 287</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <Container>
          <Form>
            <Row>
              <Col xs={12} md={12} lg={10}>
                <Row>
                  <Col xs={12} md={12} lg={4}>
                    <Form.Group className="mb-3" controlId="years">
                      <Form.Label column sm={2}>
                        Years:
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Select aria-label="Years">
                          <option>2022</option>
                          <option value="1">2021</option>
                          <option value="2">2020</option>
                          <option value="3">2019</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12} lg={4}>
                    <Form.Group className="mb-3" controlId="years">
                      <Form.Label column sm={2}>
                        State:
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control type="text" placeholder="State" />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12} lg={4}>
                    <Form.Group className="mb-3" controlId="years">
                      <Form.Label column sm={2}>
                        City:
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control type="text" placeholder="City" />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                <Button type="submit" variant="success" className="mb-2">
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </Card>
    </DashboardContainer>
  );
}

export default MerchantDashboard;
