import { React, useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import MerchantListCard from "./MerchantListCard";
import MyScrollToTop from "../../common/MyScrollToTop";
import SeachbarAndPoolBarForSmallScreen from "./SeachbarAndPoolBarForSmallScreen";
import SeachbarAndPoolBarForBigScreen from "./SeachbarAndPoolBarForBigScreen";
import WebContext from "../../../context/web-context";
import MerchantListCardHomePage from "./MerchantListCardHomePage";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
import merchantService from "../../../services/merchant/merchantService";
import InfiniteScroll from "react-infinite-scroll-component";
import NotFoundPage from "./NotFoundPage";

function MerchantList() {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const params = new URLSearchParams(location.search);
  const obj = Object.fromEntries(params);
  const { windowDimensions, authUser } = useContext(WebContext);
  const [merchantLoading, setMerchantLoader] = useState(true);
  const [merchants, setMerchants] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const initialParams = {
    start: 0,
    end: true,
  };
  const [paginationParams, setPaginationParams] = useState(initialParams);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  useEffect(() => {
    const fetchData = async () => {
      const localData = JSON.parse(localStorage?.getItem("params")) || {};
      const params = {
        keyword: obj.keyword,
        latitude: obj.lat,
        longitude: obj.lng,
        location: localData.searchString,
      };
      const data = await merchantService.getSearchMerchant(params);
      setMerchants(data?.data || []);
      setMerchantLoader(false);
      if (data?.data?.length <= pageSize) {
        setPaginationParams({ ...paginationParams, end: false });
      }
    };
    fetchData();
  }, [location]);

  useEffect(() => {
    if (!Object.keys(obj).length) navigate("/");
  }, []);

  const fetchMerchant = () => {
    setTimeout(() => {
      setPageSize((prev) => prev + 10);
    }, 500);
    setPaginationParams({ ...paginationParams, end: !(pageSize >= merchants.length) });
  };

  const merchantContent = () => {
    return (
      <InfiniteScroll
        dataLength={+pageSize}
        next={fetchMerchant}
        hasMore={paginationParams.end}
        loader={
          <div className="resultLoadingWrapper">
            <Loader height="40vh" width="auto" />
          </div>
        }
      >
        <span>
          {merchants?.length > 0 &&
            merchants.slice(0, pageSize)?.map((merchant, index) => {
              return windowDimensions.width <= 768 ? (
                <MerchantListCardHomePage key={merchant?.id} merchant={merchant} />
              ) : (
                <MerchantListCard key={merchant?.id} merchant={merchant} />
              );
            })}
        </span>
      </InfiniteScroll>
    );
  };

  return (
    <div
      className="bg-grey py-2 overflow-hidden"
      style={{
        transition: ".3s ease-in-out",
      }}
    >
      <SeachbarAndPoolBarForSmallScreen />
      <SeachbarAndPoolBarForBigScreen />
      <Container className={`pt-4 ${windowDimensions.width > 768 && "mt-5"}`}>
        {merchantLoading ? (
          <Loader height="40vh" width="auto" />
        ) : merchants.length ? (
          merchantContent()
        ) : (
          <NotFoundPage />
        )}
      </Container>
      <MyScrollToTop />
    </div>
  );
}

export default MerchantList;
