import React, { useContext, useState, useEffect } from "react";
import SeachbarAndPoolBarForBigScreen from "./SeachbarAndPoolBarForBigScreen";
import WebContext from "../../../context/web-context";
import { Button, Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot, faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { CONTACT_QUERY } from "../../../config/endpoints";
import GoogleMapReact from "google-map-react";
import { defaultLat, defaultLnt, GOOGLE_API_KEY } from "../../common/googlePlaceApiKey";
import SeachbarAndPoolBarForSmallScreen from "./SeachbarAndPoolBarForSmallScreen";
import "./Contactus.css";
import styles from "../Sections/Contact.module.css";
import { useLocation } from "react-router-dom";

const defaultProps = {
  center: {
    lat: defaultLat,
    lng: defaultLnt,
  },
  zoom: 11,
};

function Contactus() {
  const { authUser, windowDimensions } = useContext(WebContext);
  const [submitted, setSubmitted] = useState(false);
  const { pathname } = useLocation();
  const [load, setLoad] = useState(false);
  const initiaalFormState = {
    name: authUser ? authUser.user.firstName + " " + authUser.user.lastName : "",
    email: authUser ? authUser.user.email : "",
    subject: "",
    message: "",
  };
  const [form, setForm] = useState(initiaalFormState);

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  function handleForm(e) {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  function submitForm(e) {
    e.preventDefault();

    if (!form.name || !form.email || !form.subject || !form.message) {
      toast.error("Please complete the form and then submit!!");
      return;
    }
    setLoad(true);
    axios
      .post(CONTACT_QUERY, form)
      .then((res) => {
        setForm(initiaalFormState);
        if (res.data.success) {
          // code to replace the form with greeting
          setSubmitted(true);
          setLoad(false);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
          setLoad(false);
        }
      })
      .catch((err) => {
        setForm(initiaalFormState);
        toast.error(err.message);
        setLoad(false);
      });
  }

  const containerLeftSide = () => {
    return (
      <div className={`container ${windowDimensions.width > 768 ? "m-5" : "my-4"}`}>
        <div className="fs-5 fw-bold text-white">CONTACT US</div>
        <div className="fs-6 text-white pe-3 mt-2">
          For all of your customer service needs, you may utilize our Contact Form and one of our Representatives will
          be happy to reach out to you. You may also contact us at 920-903-1010 Monday-Friday 8:00 am to 7:00 pm CST
          (excluding holidays).
        </div>
        <div className="row gx-1 mt-3">
          <div className="col-2 col-xs-2 col-sm-2 col-md-1 col-lg-1 text-left">
            <FontAwesomeIcon
              className="text-success"
              style={{
                background: "#fff",
                padding: 10,
                borderRadius: "50%",
                fontSize: 20,
              }}
              icon={faMobileScreen}
            />
          </div>
          <div className="col-10 col-xs-4 col-sm-10 col-md-11 col-lg-11">
            <div className="row">
              <div className="col-12 h5 text-white">
                <strong>Phone Number</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <a href="tel:920-903-1010" className="text-white text-decoration-none">
                  920-903-1010
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row gx-2 my-2">
          <div className="col-2 col-xs-2 col-sm-2 col-md-1 col-lg-1 text-left">
            <FontAwesomeIcon
              className="text-success"
              style={{
                background: "#fff",
                padding: 10,
                borderRadius: "50%",
                fontSize: 20,
              }}
              icon={faEnvelope}
            />
          </div>
          <div className="col-10 col-xs-4 col-sm-10 col-md-11 col-lg-11">
            <div className="row">
              <div className="col-12 h5 text-white">
                <strong>Email Address</strong>
              </div>
            </div>
            <div className="row ">
              <div className="col-12">
                <a href="mailto:eazyersupport@eazyer.com" className="text-white text-decoration-none">
                  eazyersupport@eazyer.com
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-2 text-white">
          <div className="col-2 col-xs-2 col-sm-2 col-md-1 col-lg-1 text-left">
            <FontAwesomeIcon
              className="text-success"
              style={{
                background: "#fff",
                padding: 10,
                borderRadius: "50%",
                fontSize: 20,
              }}
              icon={faLocationDot}
            />
          </div>
          <div className="col-10 col-xs-4 col-sm-10 col-md-11 col-lg-11">
            <div className="row">
              <div className="col-12 h5">
                <strong>Address</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <address>
                  <Row>
                    <Col>Rockin Rewards, LLC</Col>
                  </Row>
                  <Row>
                    <Col> 101 W Edison Ave.</Col>
                  </Row>
                  <Row>
                    <Col>Suite 234</Col>
                  </Row>
                  <Row>
                    <Col> Appleton, WI 54915</Col>
                  </Row>
                </address>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const containerRightSide = () => {
    return (
      <Card
        className="shadow d-flex justify-content-center align-items-center py-5 contactFormCard"
        style={{ flex: windowDimensions.width > 768 ? 0.6 : 1 }}
      >
        {!submitted ? (
          <form action="#" onSubmit={submitForm} className="w-75">
            <div className=" d-flex flex-column justify-content-center align-items-center">
              <input
                type="text"
                name="name"
                value={form.name}
                onChange={handleForm}
                className={[styles.inputBorder, "form-control"].join(" ")}
                placeholder="Name"
              />
              <input
                type="email"
                name="email"
                value={form.email}
                onChange={handleForm}
                className={[styles.inputBorder, " mt-3 form-control"].join(" ")}
                placeholder="Email "
              />
              <input
                type="text"
                name="subject"
                value={form.subject}
                onChange={handleForm}
                className={[styles.inputBorder, " mt-3 form-control"].join(" ")}
                placeholder="Subject"
              />
              <textarea
                className={`${styles.inputBorder} my-3 form-control mx-0 `}
                name="message"
                value={form.message}
                onChange={handleForm}
                placeholder="Message "
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>

              <div className="w-100 text-end my-3">
                <Button
                  size="sm"
                  type="submit"
                  variant="success"
                  className={`btn btn-success  ${windowDimensions.width > 768 ? "" : "w-100"}`}
                  style={{ borderRadius: 4 }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        ) : (
          <div
            className="d-flex container flex-column justify-content-center align-items-center w-100"
            style={{ height: "250px" }}
          >
            <h3 className={styles.thankyou}>Thank you</h3>
            <p style={{ color: "#434d56" }}>for contacting us</p>
            <p className="text-dark">We'll get back to you soon</p>
            <p className="text-dark">
              If you need immediate assistance with anything, please feel free to call us at 920-903-1010 and we will be
              happy to help you.
            </p>
          </div>
        )}
      </Card>
    );
  };

  return (
    <>
      <SeachbarAndPoolBarForSmallScreen />
      <SeachbarAndPoolBarForBigScreen />
      <section className={` overflow-hidden ${windowDimensions.width > 768 ? "m-5" : "mt-4"}`}>
        <div className="contactUsContainer bg-success">
          <div className="row">
            <div className="col-lg-6 col-sm-12 col-md-12">{containerLeftSide()}</div>
            <div
              className="col-lg-6 col-sm-12 col-md-12  d-flex justify-content-center align-items-center"
              style={{ flex: 1 }}
            >
              {containerRightSide()}
            </div>
          </div>
        </div>
      </section>
      <div className="contactUsMapContainer mt-5">
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_API_KEY, language: "en" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent lat={defaultLat} lng={defaultLnt} text="Appleton, Wisconsin, USA" />
        </GoogleMapReact>
      </div>
    </>
  );
}

export default Contactus;
