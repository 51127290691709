import { faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Accordion, Button, Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import offer1 from "../../../assets/images/offer1.png";
import WebContext from "../../../context/web-context";
import MembersBG from "../../../assets/images/Rectangle for members.png";
import MerchantsBG from "../../../assets/images/Rectangle for merchants.png";
import MoneyBackGuaranteeImg from "../../../assets/images/guarantee.png";

const BasicCardData = [
  {
    header: "Dedicated AD-FREE Listing and Profile Page",
    detail: `We will help you create and customize your AD FREE Listing and Profile Page. You will also have complete access to add or edit any of your content anytime you want from either the website our the eazyer.com® Mobile App.

  *Mobile App will be available in December of 2019`,
  },
  {
    header: "Update your content anytime from anywhere",
    detail: "",
  },
  {
    header: "Offer Loyalty Points using our system",
    detail: "",
  },
  {
    header: "Get 75% back on Deals to attract new customers",
    detail: "",
  },
  {
    header:
      "*Value added services like access to member analytics, content updates done for you, etc. can be purchased on an as needed basis. Contact us for details.",
    detail: "",
  },
];

const PremiumCardData = [
  {
    header: "Dedicated AD-FREE Listing and Profile Page",
    detail: `We will help you create and customize your AD FREE Listing and Profile Page. In addition to our Basic subscription self help tools, as a Premium Merchant you will have access to our Design Team anytime you want to add or edit any of your content. You tells us the changes you wish to make, we will make them for you and instantly update them to your live Listing and Profile Page.

    *Mobile App will be available in November of 2019.`,
  },
  {
    header: "Do it on your own or we will update content for you",
    detail: "",
  },
  {
    header: "Offer Loyalty Points using our system",
    detail: "",
  },
  {
    header: "Get 90% back on Deals to attract new customers",
    detail: "",
  },
];

const PlatinumCardData = [
  {
    header: "Dedicated AD-FREE Listing and Profile Page",
    detail: `We will help you create and customize your AD FREE Listing and Profile Page. In addition to our Basic subscription self help tools, as a Premium Merchant you will have access to our Design Team anytime you want to add or edit any of your content. You tells us the changes you wish to make, we will make them for you and instantly update them to your live Listing and Profile Page.

    *Mobile App will be available in November of 2019.`,
  },
  {
    header: "Do it on your own or we will update content for you",
    detail: "",
  },
  {
    header: "Offer Loyalty Points using our system",
    detail: "",
  },
  {
    header: "Get 90% back on Deals to attract new customers",
    detail: "",
  },
];

function MerchantBenefits() {
  const { windowDimensions } = useContext(WebContext);
  let navigate = useNavigate();

  const moneyBackCard = () => {
    return (
      <div className="row gx-0 my-2 py-2">
        <div className="col-3">
          <img
            className="ps-4"
            style={{ height: 50, objectFit: "content" }}
            src={
              MoneyBackGuaranteeImg
                ? MoneyBackGuaranteeImg
                : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"
            }
          />
        </div>
        <div className="col-9 ">
          <p className="text-success fw-bold" style={{ marginBottom: -2 }}>
            100% Money Back Guarantee
          </p>
          <p className="fs-6 fw-bold"> If you aren’t completely satisfied</p>
        </div>
      </div>
    );
  };
  const buyNowButton = () => (
    <Button
      variant="success"
      style={{ width: windowDimensions.width <= 768 ? "100%" : "50%" }}
      onClick={() => navigate("/MerchantSignup")}
    >
      Buy Now
    </Button>
  );

  const BasicCard = (
    <div className="d-flex align-items-center justify-content-between  flex-column mb-5 pb-5  pt-4 px-3  overflow-hidden">
      <Card
        className="mb-3 p-0 bg-white  shadow "
        style={{ width: "22rem", minHeight: "95%", height: "auto" }}
      >
        <div className="card-img-overlay text-white text-center">
          <div
            className="py-4 fs-4 fw-bold text-white d-flex justify-content-center align-items-center"
            style={{
              paddingRight: windowDimensions.width <= 768 ? "" : 150,
              paddingLeft: windowDimensions.width <= 768 ? "" : 150,
            }}
          >
            BASIC FREE
          </div>
        </div>
        <Card.Img
          className=""
          variant="top"
          style={{ height: 150, objectFit: "cover" }}
          src={
            MerchantsBG
              ? MerchantsBG
              : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"
          }
        />
        <Card.Body className="p-0 ">
          <div>
            <div className="">
              <Accordion defaultActiveKey="0">
                {BasicCardData.map((data, ind) => {
                  return (
                    <Accordion.Item key={ind} eventKey={`${ind}`}>
                      <Accordion.Header>
                        <FontAwesomeIcon
                          icon={faShare}
                          style={{
                            transform: "scaleY(-1)",
                          }}
                          className="text-success me-2 "
                        />
                        {data?.header ? data?.header : ""}
                      </Accordion.Header>
                      <Accordion.Body>
                        {data?.detail ? data?.detail : ""}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </Card.Body>
      </Card>
      {buyNowButton()}
    </div>
  );

  const PremiumCard = (
    <div className="d-flex align-items-center justify-content-between flex-column mb-5 pb-5   pt-4 px-3 overflow-hidden">
      <Card
        className="mb-3 p-0 bg-white  shadow "
        style={{ width: "22rem", minHeight: "95%", height: "auto" }}
      >
        <Card.Body className="p-0 ">
          <div className="card-img-overlay text-white text-center">
            <div className=" w-100 ">
              <div
                className="shadow px-4 py-2 fw-bold bg-light text-end text-dark rounded-3"
                style={{ position: "absolute", top: -20, right: -5 }}
              >
                50% OFF
              </div>
            </div>
            <div
              className="py-4 fs-4 fw-bold text-white d-flex justify-content-center align-items-center"
              style={{
                paddingRight: windowDimensions.width <= 768 ? "" : 150,
                paddingLeft: windowDimensions.width <= 768 ? "" : 150,
              }}
            >
              Premium $49/MO.
            </div>
          </div>
          <Card.Img
            className=""
            variant="top"
            style={{ height: 150, objectFit: "cover" }}
            src={
              MembersBG
                ? MembersBG
                : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"
            }
          />
          <div>
            <div className="">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  {PremiumCardData.map((data, ind) => {
                    return (
                      <Accordion.Item key={ind} eventKey={`${ind}`}>
                        <Accordion.Header>
                          <FontAwesomeIcon
                            icon={faShare}
                            style={{
                              transform: "scaleY(-1)",
                            }}
                            className="text-success me-2 "
                          />
                          {data?.header ? data?.header : ""}
                        </Accordion.Header>
                        <Accordion.Body>
                          {data?.detail ? data?.detail : ""}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                  {moneyBackCard()}
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </Card.Body>
      </Card>

      {buyNowButton()}
    </div>
  );

  const PlatinumCard = (
    <div className="d-flex  align-items-center justify-content-between flex-column mb-5 pb-5 pt-4 px-3 overflow-hidden">
      <Card
        className="mb-3 p-0 bg-white  shadow "
        style={{ width: "22rem", minHeight: "95%", height: "auto" }}
      >
        <Card.Body className="p-0 ">
          <div className="card-img-overlay text-white text-center">
            <div className=" w-100 ">
              <div
                className="shadow px-4 py-2 fw-bold bg-light text-end text-dark rounded-3"
                style={{ position: "absolute", top: -20, right: -5 }}
              >
                80% OFF
              </div>
            </div>
            <div
              className="py-4 fs-4 fw-bold text-white d-flex justify-content-center align-items-center"
              style={{
                paddingRight: windowDimensions.width <= 768 ? "" : 150,
                paddingLeft: windowDimensions.width <= 768 ? "" : 150,
              }}
            >
              Platinum $79/MO.
            </div>
          </div>
          <Card.Img
            className=""
            variant="top"
            style={{ height: 150, objectFit: "cover" }}
            src={
              MerchantsBG
                ? MerchantsBG
                : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"
            }
          />
          <div>
            <div className="">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  {PlatinumCardData.map((data, ind) => {
                    return (
                      <Accordion.Item key={ind} eventKey={`${ind}`}>
                        <Accordion.Header>
                          <FontAwesomeIcon
                            icon={faShare}
                            style={{
                              transform: "scaleY(-1)",
                            }}
                            className="text-success me-2 "
                          />
                          {data?.header ? data?.header : ""}
                        </Accordion.Header>
                        <Accordion.Body>
                          {data?.detail ? data?.detail : ""}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                  {moneyBackCard()}
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </Card.Body>
      </Card>

      {buyNowButton()}
    </div>
  );

  const benefitsCards = () => (
    <div
      style={{ minHeight: windowDimensions.width <= 768 ? "auto" : 450 }}
      className="d-flex justify-content-center justify-content-lg-around flex-wrap overflow-hidden"
    >
      {BasicCard}
      {PremiumCard}
      {PlatinumCard}
    </div>
  );

  return <div className="container mt-5 ">{benefitsCards()}</div>;
}

export default MerchantBenefits;
