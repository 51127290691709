import "bootstrap/dist/css/bootstrap.css";
import "./components/web/Styles/style.css";
import "./components/web/Styles/responsive.css";
import "./components/web/Styles/colors.css";
import RoutesConfig from "./RouteConfig";
import { SignUpProvider } from "./store/signUpStore";
import { GlobalProvider } from "./store/commonStore";

function App() {
  return (
    <GlobalProvider>
      <SignUpProvider>
        <RoutesConfig />
      </SignUpProvider>
    </GlobalProvider>
  );
}

export default App;
