/**
 * Eazyer Inc.
 *
 * Copyright (C) - 2021
 *
 * File Name: UserContext.js
 * Description: uses React Context API for passing data around app.
 */

import React, { createContext, useState } from "react";

export const UserContext = createContext();

// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {
  const [data, setData] = useState(undefined);

  return (
    <UserContext.Provider
      value={{
        data,
        setData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
