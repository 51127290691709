import React, { useContext, useEffect, useState } from "react";
import { Card, Container, Accordion, Button } from "react-bootstrap";
import WebContext from "../../../context/web-context";
import { Rating } from "react-simple-star-rating";
import { Input, Progress } from "antd";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import SeachbarAndPoolBarForBigScreen from "./SeachbarAndPoolBarForBigScreen";
import SeachbarAndPoolBarForSmallScreen from "./SeachbarAndPoolBarForSmallScreen";
import { useNavigate } from "react-router-dom";
import commonService from "../../../services/common/commonService";
import ThumbUp from "./thumbUp.png";
import ThumbDown from "./thumbDown.png";
import "./ReviewStyle.css";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { TextArea } = Input;

const Review = () => {
  const { windowDimensions } = useContext(WebContext);
  const navigate = useNavigate();
  const { merchantId } = useParams();
  const location = useLocation();
  const [reviews, setReviews] = useState([]);
  const [reviewDetail, setReviewDetail] = useState([]);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // window.scroll(0, 0);
  }, [pathname]);

  useEffect(() => {
    const getReview = async () => {
      const reviewDetail = await commonService.getMerchantReviewDetail(merchantId);
      const reviews = await commonService.getMerchantReview(merchantId);
      setReviews(reviews?.data);
      setReviewDetail({ ...reviewDetail?.data, ratingCount: Object.values(reviewDetail?.data?.review_rating_count) });
    };
    getReview();
  }, []);

  return (
    <div className="bg-grey">
      <SeachbarAndPoolBarForSmallScreen />
      <SeachbarAndPoolBarForBigScreen />
      <Container className="my-container">
        <Card className="">
          <div className="review-container">
            <div className="merchant_profile_container">
              <div className="merchant_profile">
                <img
                  style={{
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={
                    location?.state?.merchantLogo
                      ? location?.state?.merchantLogo
                      : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"
                  }
                  alt="Card  cap"
                />
              </div>
              <div className="review-merchant-info">
                <span className="merchant_profile_name">{reviewDetail?.name}</span>
                {/* <span className="merchant_profile_name">Pizza king</span> */}
                <span className="trip_image">
                  {/* <Rating
                    size={windowDimensions.width <= 768 ? 15 : 30}
                    fillColor={"#FBFF2D"}
                    readonly
                    initialValue={+reviewDetail?.rating || 0}
                  /> */}
                  <img
                    // style={{
                    //   width: 165,
                    //   height: 30,
                    // }}
                    src={reviewDetail?.rating_image_url}
                  />
                  <span className="review-count">{reviewDetail?.num_reviews && `(${reviewDetail?.num_reviews})`}</span>
                </span>
                {/* <div className="review-count">{reviewDetail?.num_reviews && `(${reviewDetail?.num_reviews})`}</div> */}
              </div>
            </div>
            {/* <div className="merchant_input_container">
              <TextArea
                showCount
                maxLength={100}
                className="review_inputBox"
                placeholder="Please write your reviews here."
                onChange={() => {}}
              />
              <span className="review_inputBelow_text">
                By submitting review you give us consent to publish and process personal information in accordance with
                <span className="inner_text" onClick={() => navigate("/TermsAndConditions")}>
                  Terms of Use
                </span>
                and
                <span className="inner_text" onClick={() => navigate("")}>
                  Privacy Policy
                </span>
                .
              </span>
              <div>
                <Button type="submit" variant="secondary" className="bg-success  mt-3">
                  Submit
                </Button>
              </div>
            </div> */}
            <div className="merchant_input_container">
              <div className="summary">
                <div className="d-flex">
                  <span className="summaryText">Excellent</span>
                  <Progress
                    // percent={!!reviewDetail?.ratingCount?.length && reviewDetail?.ratingCount[4] * 10}
                    percent={
                      !!reviewDetail?.ratingCount?.length &&
                      parseFloat((reviewDetail?.ratingCount[4] / reviewDetail?.num_reviews) * 100).toFixed(2)
                    }
                    size="small"
                  />
                </div>
                <div className="d-flex">
                  <span className="summaryText">Very Good</span>
                  <Progress
                    // percent={!!reviewDetail?.ratingCount?.length && reviewDetail?.ratingCount[3] * 10}
                    percent={
                      !!reviewDetail?.ratingCount?.length &&
                      parseFloat((reviewDetail?.ratingCount[3] / reviewDetail?.num_reviews) * 100).toFixed(2)
                    }
                    size="small"
                  />
                </div>
                <div className="d-flex">
                  <span className="summaryText">Average</span>
                  <Progress
                    // percent={!!reviewDetail?.ratingCount?.length && reviewDetail?.ratingCount[2] * 10}
                    percent={
                      !!reviewDetail?.ratingCount?.length &&
                      parseFloat((reviewDetail?.ratingCount[2] / reviewDetail?.num_reviews) * 100).toFixed(2)
                    }
                    size="small"
                  />
                </div>
                <div className="d-flex">
                  <span className="summaryText">Poor</span>
                  <Progress
                    // percent={!!reviewDetail?.ratingCount?.length && reviewDetail?.ratingCount[1] * 10}
                    percent={
                      !!reviewDetail?.ratingCount?.length &&
                      parseFloat((reviewDetail?.ratingCount[1] / reviewDetail?.num_reviews) * 100).toFixed(2)
                    }
                    size="small"
                  />
                </div>
                <div className="d-flex">
                  <span className="summaryText">Terrible</span>
                  <Progress
                    // percent={!!reviewDetail?.ratingCount?.length && reviewDetail?.ratingCount[0] * 10}
                    percent={
                      !!reviewDetail?.ratingCount?.length &&
                      parseFloat((reviewDetail?.ratingCount[0] / reviewDetail?.num_reviews) * 100).toFixed(2)
                    }
                    size="small"
                  />
                </div>
              </div>
              {reviews?.map((review) => (
                <div className="user-reviews">
                  <div className="review-user-rating">
                    <img src={review?.rating_image_url} />
                    <span>Reviewed {moment(review?.published_date).format("MMMM Do, YYYY")}</span>
                  </div>
                  <div className="review-merchant-name">
                    {/* <div className="review-user-rating">
                      {review?.rating}
                      <FontAwesomeIcon icon={faStar} />
                    </div> */}
                    {review?.user?.username}
                    {review?.user?.user_location?.name && `| ${review?.user?.user_location?.name}`}
                  </div>
                  <div className="review-response">{review?.title}</div>
                  <p>{review?.text}</p>
                  <div className="d-flex justify-content-between">
                    <div className="review-date">Written {review?.travel_date}</div>
                    {/* <div className="like">
                      <img style={{ height: 16, width: 16, margin: "0px 5px" }} src={ThumbUp} alt="No img" />0
                      <img style={{ height: 16, width: 16, margin: "0px 5px" }} src={ThumbDown} alt="No img" />0
                    </div> */}
                  </div>
                </div>
              ))}
              {!!reviews?.length && (
                <Button
                  href={reviewDetail?.web_url}
                  target="_blank"
                  type="submit"
                  variant="secondary"
                  className="bg-success  mt-3"
                >
                  See More
                </Button>
              )}
            </div>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default Review;
