import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { LOGGED_ADMIN, LOGGED_MERCHANT } from "../../../config/constants";
import { toast } from "react-toastify";
import AdminContext from "../../../context/admin-context";
import MerchantContext from "../../../context/merchant-context";

function MerchantLogout() {
  const { setAuthMerchant } = useContext(MerchantContext);

  localStorage.removeItem(LOGGED_MERCHANT);
  sessionStorage.removeItem(LOGGED_MERCHANT);
  setAuthMerchant(null);
  toast.info("Logged out successfully");
  return <Navigate to="/merchant-login" replace />;
}

export default MerchantLogout;
