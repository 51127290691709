import { useLocation } from "react-router-dom";
import { GOOGLE_API_KEY } from "../components/common/googlePlaceApiKey";

export function validatePhone(v) {
  return /^(\+)\d{10,14}$/.test(v);
}

export function validateEmail(v) {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    v
  );
}

export function getUrlLastSegment() {
  return window.location.pathname.split("/").pop();
}


export function addDashes(value) {
  var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
  value = value.replace(r, '');
  npa = value.substr(0, 3);
  nxx = value.substr(3, 3);
  last4 = value.substr(6, 4);
  return (npa + '-' + nxx + '-' + last4);
}

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

export function useQuery() {
  const enteries = new URLSearchParams(useLocation().search).entries();
  return paramsToObject(enteries);
}

export const loadGMaps = (callback) => {
  const existingScript = document.getElementById("googleMaps");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=${GOOGLE_API_KEY}`;
    script.id = "googleMaps";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};