import { useContext, useState } from "react";
import { Container } from "react-bootstrap";
import { Navigate, Outlet } from "react-router-dom";
import { LOGGED_MERCHANT } from "../../../config/constants";
import myAxios from "../../../config/my-axios";
import MerchantContext from "../../../context/merchant-context";
import "./MerchantLayout.css";
import MerchantNavbar from "./MerchantNavbar";
import Sidebar from "./Sidebar";

function MerchantLayout(props) {
  const { authMerchant } = useContext(MerchantContext);

  const [sidebar, setSidebar] = useState(false);

  if (!authMerchant) {
    return <Navigate to="/merchant-login" replace />;
  }

  function switchSidebar() {
    setSidebar(!sidebar);
  }

  function closeSidebar() {
    setSidebar(false);
  }

  myAxios.defaults.headers.common["Authorization"] = "Bearer " + authMerchant.token;

  return (
    <div className="Main position-relative">
      <Sidebar user={authMerchant} sidebar={sidebar} closeSidebar={closeSidebar} />
      <div className="d-none d-md-block bg-danger" style={{ width: "270px", minHeight: "100vh" }}></div>
      <div
        style={{
          position: "relative",
          flexGrow: 1,
          width: "calc(100% - 270px)",
        }}
      >
        <MerchantNavbar switchSidebar={switchSidebar} />
        <Container fluid className="py-3 px-md-4 px-3" style={{ backgroundColor: "#F1F4FC", minHeight: "100vh" }}>
          <Outlet />
        </Container>
      </div>
    </div>
  );
}

export default MerchantLayout;
