/* global google */
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import myAxios from "../../../config/my-axios";
import MySpinner from "../../common/MySpinner";
import { toast } from "react-toastify";
import MerchantDashboardContainer from "../components/MerchantDashboardContainer";
import MerchantPageTitle from "../components/MerchantPageTitle";
import { BASE_URL, CATEGORY_LIST_ADMIN, MERCHANT_DETAIL, STATE_LIST, UPDATE_MERCHANT } from "../../../config/endpoints";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ImageCropper from "../../common/ImageCropper";
import "cropperjs/dist/cropper.css";
import MerchantContext from "../../../context/merchant-context";
import MerchantWorkingHours from "./MerchantWorkingHours";
import { loadGMaps } from "../../../config/helper";
import { MultiSelect } from "react-multi-select-component";
import { Select } from "antd";
const { Option } = Select;

function MerchantBusinessProfile() {
  const { authMerchant } = useContext(MerchantContext);
  const [mode, setMode] = useState("add");
  const addressRef = useRef();

  let initObj = {
    // name: "",
    businessName: "",
    description: "",
    // phone: "",
    // email: "",
    address: "",
    address2: "",
    locationAddress: "",
    zipcode: "",
    city: "",
    state: "",
    logo: null,
    logoUrl: "",
    finalImage: null,
    finalImageUrl: "",
    category: "",
    childCategory: "",
    categories: [],
  };
  // const { id } = useParams();
  const id = authMerchant?.merchant?._id;
  const navigate = useNavigate();
  const [form, setForm] = useState(initObj);
  const [address, setAddress] = useState({});
  const [error, setError] = useState({ ...initObj });
  const [load, setLoad] = useState(true);
  const [modal, showModal] = useState(false);

  const [data, setData] = useState({
    states: [],
    categories: [],
  });

  const loading = () => (
    <div
      className="d-flex justify-content-center align-items-center mb-5"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  function handleForm(event) {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  }

  function handleImage(e) {
    setForm({
      ...form,
      logo: e.target.files[0],
      logoUrl: URL.createObjectURL(e.target.files[0]),
    });
    showModal(true);
  }

  function handleZip(e) {
    if (isNaN(e.target.value) || e.target.value.length > 8) return;
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }

  function handlePhone(e) {
    let value = e.target.value;
    if (isNaN(value) || value.length > 14) return;
    setForm({
      ...form,
      [e.target.name]: value,
    });
  }

  function submitForm(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${authMerchant?.token}`,
        "content-type": "multipart/form-data",
      },
    };
    let formData = new FormData();
    // formData.append("name", form.name);
    formData.append("description", form.description);
    formData.append("businessName", form.businessName);
    formData.append("address", form.address);
    formData.append("address2", form.address2);
    if (address.formatted_address) formData.append("locationAddress", address.formatted_address);
    if (address.lat) formData.append("latitude", address.lat);
    if (address.lng) formData.append("longitude", address.lng);
    formData.append("city", form.city);
    formData.append("zipcode", form.zipcode);
    formData.append("state", form.state);
    // formData.append("phone", form.phone);
    // formData.append("email", form.email);
    if (id) formData.append("id", id);
    if (form.logo) {
      // formData.append("logo", form.logo, form.logo.name);
      formData.append("logo", form.finalImage, "logo.png");
    }
    if (form.category) formData.append("category", form.category.id);
    if (form.childCategory) formData.append("childCategory", form.childCategory.id);
    if (form.categories) formData.append("categories", JSON.stringify(form.categories.map((el) => el?.id)));

    setLoad(true);
    let url = id && UPDATE_MERCHANT;
    axios
      .post(url, formData, config)
      .then((res) => {
        let data = res.data;
        //
        if (data.success) {
          toast.success("Updated successfully");
          if (!id) {
            // navigate("/admin/merchants", { replace: true });
          } else {
            setError({ ...initObj });
            setLoad(false);
          }
        } else {
          setError({
            ...initObj,
            ...data.data,
          });
          toast.error(data.message);
          setLoad(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoad(false);
      });
  }

  function initMaps() {
    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
      types: ["establishment"],
    };

    const autocomplete = new google.maps.places.Autocomplete(addressRef.current, options);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      setAddress({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        formatted_address: place.formatted_address,
      });
    });
  }

  function loadCategories(states) {
    let filter = { child: true, active: true };
    myAxios
      .post(CATEGORY_LIST_ADMIN, { filter })
      .then((r) => {
        let res = r.data;
        if (res.success) {
          setData({
            ...data,
            states,
            categories: res.data,
          });
        }
      })
      .catch((er) => {});
  }

  useEffect(() => {
    loadGMaps(() => {
      initMaps();
    });
    myAxios
      .get(STATE_LIST)
      .then((res) => {
        if (res.data.success) {
          loadCategories(res.data.data.states);
          setData({
            ...data,
            states: res.data.data.states,
          });
          loadMerchant();
        } else {
          toast.error(res.data.message);
          setLoad(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoad(false);
      });
  }, []);
  function loadMerchant() {
    if (id) {
      let populate = ["categories"];
      myAxios
        .post(MERCHANT_DETAIL, { id, populate })
        .then((res) => {
          let data = res.data;

          if (data.success) {
            let merchant = data.data;
            setForm({
              ...merchant,
              logo: null,
              logoUrl: "",
              finalImageUrl: merchant.logo,
              facebook: merchant.socialLinks.facebook || "",
              twitter: merchant.socialLinks.twitter || "",
              linkedin: merchant.socialLinks.linkedin || "",
              // categories: merchant.categories.map((el) => ({
              //   label: el.name,
              //   value: el.id,
              // })),
            });
            setLoad(false);
          } else {
            toast.error(data.message);
            setLoad(false);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setLoad(false);
        });
    } else {
      setLoad(false);
    }
  }
  function handleCroppedImage(croppedImage) {
    setForm({
      ...form,
      finalImage: croppedImage,
      finalImageUrl: URL.createObjectURL(croppedImage),
    });
    showModal(false);
  }

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);

  useEffect(() => {
    myAxios.post(BASE_URL + "category/hierarchy").then((res) => {
      let data = res.data;

      if (data.success) {
        setCategories(data.data);
      }
    });
  }, []);

  return (
    <MerchantDashboardContainer>
      <MerchantPageTitle
        title={"My Business Profile"}
        rightSide={
          <Button
            type="submit"
            form="merchantBusinessForm"
            variant="secondary"
            className="btn btn-success "
            style={{ float: "right" }}
          >
            Update
          </Button>
        }
      />
      <Card body className="shadow py-2">
        {load ? (
          loading()
        ) : (
          <Form onSubmit={submitForm} id="merchantBusinessForm">
            <Row>
              <Col md="6" xl="4">
                <Card bg="light" className="mb-4">
                  <Card.Body>
                    {/* <Form.Group className="mb-3">
                      <label className="text-muted">Contact Name</label>
                      <Form.Control
                        size="sm"
                        name="name"
                        value={form.name}
                        onChange={handleForm}
                      />
                      <label className="text-error">{error.name}</label>
                    </Form.Group> */}

                    <Form.Group className="mb-3">
                      <label className="text-muted">Business Name</label>
                      <Form.Control size="sm" name="businessName" value={form.businessName} onChange={handleForm} />
                      <label className="text-error">{error.businessName}</label>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <label className="text-muted">Description</label>
                      <Form.Control
                        as="textarea"
                        rows="2"
                        size="sm"
                        name="description"
                        value={form.description}
                        onChange={handleForm}
                      />
                      <label className="text-error">{error.description}</label>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <label className="text-muted">Category</label>
                      <Select
                        // mode="multiple"
                        // allowClear
                        style={{ width: "100%" }}
                        value={form?.category?.name}
                        placeholder="Please select Category"
                        onChange={(e, data, val) => {
                          setSubCategories(data?.data?.children);
                          // setForm({ ...form, categories: [...form.categories, { label: data.children, value: data.value }] });
                          setForm({
                            ...form,
                            category: { id: data.value, name: data.children },
                            childCategory: "",
                            categories: [],
                          });

                          // setForm({
                          //   ...form,
                          //   childCategory: { id: "", name: "" },
                          // });
                          // setForm({
                          //   ...form,
                          //   categories: [...form.categories, { id: "", name: "" }],
                          // });
                        }}
                      >
                        {categories.map((category, index) => (
                          <Option data={category} key={category._id}>
                            {category.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <label className="text-muted">Sub Categories</label>
                      <Select
                        disabled={!subCategories?.length}
                        // allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select subCategory"
                        value={form?.childCategory?.name}
                        onChange={(e, data, val) => {
                          setSubSubCategories(data?.data?.children);
                          setForm({
                            ...form,
                            childCategory: { id: data.value, name: data.children },
                            categories: [],
                          });
                        }}
                      >
                        {subCategories.map((category, index) => (
                          <Option data={category} key={category._id}>
                            {category.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <label className="text-muted">Sub Sub Categories</label>
                      <Select
                        mode="multiple"
                        // allowClear
                        disabled={!subSubCategories?.length}
                        style={{ width: "100%" }}
                        placeholder="Please select sub subCategories"
                        value={form?.categories?.map((item) => item?.name)}
                        onChange={(e, data, val) => {
                          setForm({
                            ...form,
                            categories: [...form.categories, { id: data[0].value, name: data[0].children } || ""],
                          });
                        }}
                      >
                        {subSubCategories.map((category, index) => (
                          <Option data={category} key={category._id}>
                            {category.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Group>

                    {/* <Form.Group className="mb-3">
                      <label className="text-muted">Phone</label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          size="sm"
                          type="number"
                          name="phone"
                          value={form.phone}
                          onChange={handlePhone}
                        />
                      </InputGroup>

                      <label className="text-error">{error.phone}</label>
                    </Form.Group> */}

                    {/* <Form.Group className="mb-3">
                      <label className="text-muted">Email Address</label>
                      <Form.Control
                        size="sm"
                        type="email"
                        name="email"
                        value={form.email}
                        onChange={handleForm}
                      />
                      <label className="text-error">{error.email}</label>
                    </Form.Group> */}
                  </Card.Body>
                </Card>
              </Col>

              <Col md="6" xl="4">
                <Card bg="light" className="mb-4">
                  <Card.Body>
                    <Form.Group className="mb-3">
                      <label className="text-muted">location Address</label>
                      <input
                        defaultValue={form.locationAddress || ""}
                        size="sm"
                        name="locationAddress"
                        className="form-control form-control-sm"
                        id="locationAddress"
                        ref={addressRef}
                      />
                      <label className="text-error">{error.locationAddress}</label>
                      {form.location && form.location.coordinates && (
                        <span className="text-info">
                          {form.location.coordinates[1]} , {form.location.coordinates[0]}
                        </span>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <label className="text-muted">Address line 1</label>
                      <Form.Control size="sm" name="address" value={form.address} onChange={handleForm} />
                      <label className="text-error">{error.address}</label>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <label className="text-muted">Address line 2</label>
                      <Form.Control size="sm" name="address" value={form.address2} onChange={handleForm} />
                      <label className="text-error">{error.address2}</label>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <label className="text-muted">Zipcode</label>
                      <Form.Control size="sm" name="zipcode" value={form.zipcode} onChange={handleForm} />
                      <label className="text-error">{error.zipcode}</label>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <label className="text-muted">City</label>
                      <Form.Control size="sm" name="city" value={form.city} onChange={handleForm} />
                      <label className="text-error">{error.city}</label>
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <label className="text-muted">State</label>
                      <Form.Select size="sm" name="state" value={form.state} onChange={handleForm}>
                        <option value="">-- select state--</option>
                        {data.states.map((state, i) => {
                          return (
                            <option key={i} value={state.id}>
                              {/* {state.name} */}
                              {state.abbreviation}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <label className="text-error">{error.state}</label>
                    </Form.Group>

                    {/* <Form.Group className="mb-3">
                      <label className="text-muted">Zip code</label>
                      <Form.Control
                        size="sm"
                        name="zipcode"
                        value={form.zipcode}
                        onChange={handleZip}
                      />
                      <label className="text-error">{error.zipcode}</label>
                    </Form.Group> */}

                    {/* <Form.Group className="mb-3">
                      <label className="text-muted">City</label>
                      <Form.Control
                        size="sm"
                        name="city"
                        value={form.city}
                        onChange={handleForm}
                      />
                      <label className="text-error">{error.city}</label>
                    </Form.Group> */}

                    {/* <Form.Group className="mb-4">
                      <label className="text-muted">State</label>
                      <Form.Select
                        size="sm"
                        name="state"
                        value={form.state}
                        onChange={handleForm}
                      >
                        <option value="">-- select state--</option>
                        {data.states.map((state, i) => {
                          return (
                            <option key={i} value={state.id}>
                              {state.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <label className="text-error">{error.state}</label>
                    </Form.Group> */}

                    <Form.Group className="">
                      <label className="text-muted">Featured / Logo image</label>
                      <Form.Control type="file" size="sm" name="logo" onChange={handleImage} />
                      <Form.Text className="text-danger">Please upload image of resolution (170 x 120)</Form.Text>
                      <label className="text-error">{error.logo}</label>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </Col>

              <Col md="6" xl="4">
                <div className="text-center">
                  <p>Final image preview</p>
                  <img
                    src={form.finalImageUrl ? form.finalImageUrl : ""}
                    alt="final image"
                    style={{ width: "80%", height: "auto" }}
                  />
                </div>
              </Col>

              <Col md="12" xl="12">
                {form.logoUrl && (
                  <>
                    <ImageCropper
                      image={form.logoUrl}
                      width={170}
                      height={120}
                      onCrop={handleCroppedImage}
                      modal={modal}
                      showModal={showModal}
                    />
                  </>
                )}
              </Col>
              {/* <Col sm="12">
                <Form.Group>
                  <Button type="submit" className="px-3">
                    Update
                  </Button>
                </Form.Group>
              </Col> */}
            </Row>
          </Form>
        )}
      </Card>
    </MerchantDashboardContainer>
  );
}

export default MerchantBusinessProfile;
