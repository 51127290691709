import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LOGGED_USER } from "../../../config/constants";
import { USER_AC_VERIFY } from "../../../config/endpoints";
import { getUrlLastSegment } from "../../../config/helper";
import WebContext from "../../../context/web-context";

function VerifyAccount() {
  const token = getUrlLastSegment();
  const { authUser, setAuthUser } = useContext(WebContext);
  const [verified, setVerified] = useState(null);
  const [text, setText] = useState("processing...");

  useEffect(() => {
    let url = USER_AC_VERIFY + `/${token}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.success) {
          sessionStorage.setItem(LOGGED_USER, JSON.stringify(res.data.data));
          setAuthUser(res.data.data);
          toast.success("Your account is verified!");
        } else {
          setText(res.data.message);
        }
      })
      .catch((err) => {
        setText(err.message);
      });
  }, []);

  if (authUser && authUser.token) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div className={`d-flex flex-column align-items-center justify-content-center`} style={{ minHeight: 400 }}>
      {verified ? (
        <>
          <h4>Hi {verified.user?.name}</h4>
          <h5>Your account is verified.</h5>
          <Link replace={true} to={`/`}>
            go back to home
          </Link>
        </>
      ) : (
        <>
          <p>{text}</p>
        </>
      )}
    </div>
  );
}

export default VerifyAccount;
