import React from "react";
import SidebarAccordian from "./SidebarAccordian";
import SidebarLink from "./SidebarLink";
import sidebarImage from "../../../assets/images/dashboardBG.png";

function SidebarLabel({ title }) {
  return "";
  // return <span className="w-100 text-white px-3 mb-3">{title}</span>;
}

function Sidebar({ user, sidebar, closeSidebar }) {
  let classd = sidebar ? "" : "d-none";
  return (
    <>
      <div
        id="sidebar"
        className={` px-0 py-0 text-white ${classd} d-md-block  position-fixed shadow`}
        style={{
          width: "270px",
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: 1000,
          backgroundImage: `url(${sidebarImage})`,
        }}
      >
        <div className={`sidebarContent`}>
          <div className="text-center">
            <img src="/logo.png" style={{ height: "34px", width: "auto" }} />
            <p className="text-center text-white mt-2">
              Welcome {user.user.name} <span className="fas fa-fw fa-grin"></span>{" "}
            </p>
          </div>
          <ul className="p-0" style={{ listStyleType: "none" }}>
            <SidebarLabel title="Basic setup" />

            <section className="px-4">
              <SidebarLink
                closeSidebar={closeSidebar}
                title="Dashboard"
                icon="fas fa-fw fa-tachometer-alt"
                to="/admin"
              />

              <SidebarAccordian
                eventKey="0"
                title="Categories"
                icon="fas fa-fw fa-file-alt"
                data={[
                  { to: "/admin/categories", title: "Parent Categories" },
                  {
                    to: "/admin/subCategories",
                    title: "Sub Categories",
                  },
                  {
                    to: "/admin/subSubCategories",
                    title: "Sub sub Categories",
                  },
                  {
                    to: "/admin/categoryHierarchy",
                    title: "Categories Hierarchy",
                  },
                ]}
              />

              <SidebarLink closeSidebar={closeSidebar} title="Users" icon="fas fa-fw fa-user-alt" to="/admin/users" />

              <SidebarAccordian
                eventKey="1"
                title="Merchants"
                icon="fas fa-fw fa-file"
                data={[
                  { to: "/admin/merchants", title: "Merchants" },
                  {
                    to: "/admin/merchants?as=pending",
                    title: "Pending merchants",
                  },
                ]}
              />

              <SidebarLink
                closeSidebar={closeSidebar}
                title="Configuration"
                icon="fas fa-fw fa-toolbox"
                to="/admin/configuration"
              />
              <SidebarLink
                closeSidebar={closeSidebar}
                title="Contact queries"
                icon="fas fa-fw fa-file-alt"
                to="/admin/contact-queries"
              />

              <SidebarLink title="Logout" icon="fas fa-fw fa-sign-out-alt" to="/admin/logout" />
            </section>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
