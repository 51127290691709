import axios from "axios";
import React, { useContext, useLayoutEffect, useState } from "react";
import { Card, Col, Container } from "react-bootstrap";
import { Link, Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { USER_LOGIN } from "../../../config/endpoints";
import WebContext from "../../../context/web-context";
import MySpinner from "../../common/MySpinner";
import { LOGGED_USER } from "../../../config/constants";

function SignIn() {
  const { authUser, setAuthUser, selectedKeyword } = useContext(WebContext);
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  let initObj = {
    email: "",
    password: "",
    checkRemember: false,
  };
  const [form, setForm] = useState(initObj);
  const [load, setLoad] = useState(false);

  function handleForm(e) {
    e.target.name == "checkRemember"
      ? setForm({
          ...form,
          [e.target.name]: e.target.checked,
        })
      : setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
  }

  function submitForm() {
    if (!form.email || !form.password) {
      toast.error("Please enter email and password first");
      return;
    }

    setLoad(true);

    axios
      .post(USER_LOGIN, form)
      .then((res) => {
        if (res.data.success && res.data.data.token) {
          if (form.checkRemember === true) {
            localStorage.setItem(LOGGED_USER, JSON.stringify(res.data.data));
          }
          sessionStorage.setItem(LOGGED_USER, JSON.stringify(res.data.data));
          const latitude = res?.data?.data?.location?.latitude;
          const longitude = res?.data?.data?.location?.longitude;
          const address = res?.data?.data?.location?.address;
          localStorage.removeItem("params");
          localStorage.setItem(
            "params",
            JSON.stringify({
              keyword: selectedKeyword,
              latitude: latitude,
              longitude: longitude,
              searchString: address,
            })
          );

          setAuthUser(res.data.data);
          toast.success("Logged in successfully");
        } else {
          toast.error(res.data.message);
          setLoad(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoad(false);
      });
  }

  if (authUser && authUser.token) {
    return <Navigate to="/" replace={true} />;
  }

  const loader = () => (
    <div className="d-flex  justify-content-center ">
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );

  return (
    <section className="log-sec">
      <Container
        fluid
        className="d-flex justify-content-center align-items-center w-100 p-md-5 p-3"
        style={{ minHeight: "inherit" }}
      >
        <Col xl="4" sm="6" className="mb-md-6 me-md-6 flex-grow-1 flex-md-grow-0 col-xl-4 col-sm-6">
          <Card className="rounded-3">
            <Card.Body>
              {load && loader()}
              <div className="rounded">
                <div className="gy-4 my-2">
                  <div className="text-center">
                    <h4 style={{ color: "#218C44" }}>
                      <b>LOGIN NOW</b>
                    </h4>
                    {/* <p>Enter your details for login</p> */}
                  </div>
                </div>
                <div className="row gy-4 pt-3 px-4">
                  <div className="col-12">
                    <input
                      type="text"
                      name="email"
                      value={form.email}
                      onChange={handleForm}
                      className="form-control"
                      placeholder="Email *"
                    />
                  </div>
                </div>
                <div className="row gy-4 pt-3 px-4">
                  <div className="col-12">
                    <input
                      type="password"
                      name="password"
                      value={form.password}
                      onChange={handleForm}
                      className="form-control"
                      placeholder="Password *"
                    />
                  </div>
                </div>
                <div className="row gy-4 pt-3 px-4 mb-2">
                  <div className="col-12 text-end">
                    <Link to="/forgotpassword" style={{ textDecoration: "none", color: "#878686" }}>
                      Forgot Password?
                    </Link>
                  </div>
                </div>
                <div className="row gy-4 pt-3 px-4 mb-2">
                  <div className="col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="checkRemember"
                        id="checkRemember"
                        onChange={handleForm}
                        checked={form.checkRemember}
                      />
                      <label className="form-check-label" htmlFor="checkRemember" style={{ color: "#878686" }}>
                        Remember me
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 pt-3 px-4 text-center">
                  <div className="col-12 text-center">
                    <button
                      type="button"
                      onClickCapture={submitForm}
                      className="register-now  btn btn-success"
                      style={{ lineHeight: 1 }}
                    >
                      <b>Login Now</b>
                    </button>
                  </div>
                  <div className="col-12 text-center">
                    <label style={{ textDecoration: "none", color: "#878686" }}>
                      Create a account?{" "}
                      <Link to="/signUp" style={{ textDecoration: "none", color: "#218C44" }}>
                        Sign Up
                      </Link>
                    </label>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    </section>
  );
}

export default SignIn;
