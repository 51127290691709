import { React, useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import WebContext from "../../../context/web-context";

function formateNumber(number) {
  let f_val = number.replace(/\D[^\.]/g, "");
  let formatted =
    f_val.slice(0, 3) + "-" + f_val.slice(3, 6) + "-" + f_val.slice(6);
  return formatted;
}

function DealsCard({ deals }) {
  const { windowDimensions } = useContext(WebContext);
  const [showShadow, setShowShadow] = useState(false);

  return (
    <>
      <Card
        className={`m-2 mt-4 bg-white  merchantsListCard ${
          showShadow && "shadow"
        } `}
        style={{
          minHeight: 370,
          height: "auto",
          transition: "all .35s ease",
          width: 350,
        }}
        onMouseOver={() => setShowShadow(true)}
        onMouseOut={() => setShowShadow(false)}
      >
        <Card.Img
          variant="top"
          style={{
            minHeight: windowDimensions.width <= 768 ? 200 : 200,
            maxHeight: windowDimensions.width <= 768 ? 200 : 200,
            objectFit: "contain",
          }}
          src={
            deals?.logo
              ? deals?.logo
              : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"
          }
        />
        <Card.Body>
          <div
            className="bg-white border px-2 py-1 small rounded"
            style={{
              position: "relative",
              top: -30,
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              color="success"
              fontSize={13}
              className={`text-success`}
              icon={faStar}
            />
            <span className="fw-bold ms-1"> 4.6</span>
            <span className="fw-bold mx-2 vr py-2"></span>
            <span className="fw-bold ms-1 text-success"> 70</span>
          </div>
          <div className="fw-bold fs-5 text-success">{deals?.name}</div>
          <Card.Title className="fw-bold">{deals?.businessName}</Card.Title>
          <Card.Text>
            <div className="row">
              <div className="col-12 text-secondary fs-6">{deals?.address}</div>
            </div>
            <div className="row">
              <div className="col-12">
                <a
                  className="text-secondary  fs-6"
                  // href={`tel:${merchant.phone}`}
                  style={{ textDecoration: "none" }}
                >
                  {formateNumber(deals?.phone)}
                </a>
              </div>
            </div>
            <div className=" ">
              <span className="fw-bold pe-2">$6</span>
              <span className="text-decoration-line-through pe-2"> $10</span>
              <span className="text-danger pe-2"> ($4 OFF)</span>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}

export default DealsCard;
