import {
  ADD_CATEGORY_ADMIN,
  BASE_URL,
  CATEGORY_LIST_ADMIN,
  CATGORY_ACTIVATE_DEACTIVATE,
  HOMESCREEN,
  SEARCH_MERCHANT,
  UPDATE_CATEGORY_ADMIN,
  UPDATE_USER_STATUS,
  USER_LIST,
} from "../../config/endpoints";
import http from "../httpService";
import { handleFailure } from "../../utils/handleFailure";
import { get_access_token } from "../../utils/apiUtils";

const adminService = {
  addCategory: async (params) => {
    const url = ADD_CATEGORY_ADMIN;
    const headerObj = {
      headers: {
        ...get_access_token().headers,
        "Content-type": "multipart/form-data",
      },
    };
    try {
      const response = await http.post(url, params, headerObj);
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  getCategories: async (level) => {
    const url = CATEGORY_LIST_ADMIN;
    const params = {
      sort: { desc: true },
      filter: {
        level: level,
      },
    };
    try {
      const response = await http.post(url, params, get_access_token());
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  deactivateCategory: async (categoryId) => {
    const url = CATGORY_ACTIVATE_DEACTIVATE;
    const payload = {
      categoryId,
    };
    try {
      const response = await http.post(url, payload, get_access_token());
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  updateCategory: async (payload) => {
    const url = UPDATE_CATEGORY_ADMIN;
    const headerObj = {
      headers: {
        ...get_access_token().headers,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const response = await http.post(url, payload, headerObj);
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  getUsers: async () => {
    const url = USER_LIST;
    try {
      const response = await http.post(url, null, get_access_token());
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  userVerified: async (userId) => {
    const url = BASE_URL + "/user/verify-account";
    const payload = {
      userId,
    };
    try {
      const response = await http.post(url, payload, get_access_token());
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  updateUserStatus: async (userId) => {
    const url = UPDATE_USER_STATUS;
    const payload = {
      userId,
    };
    try {
      const response = await http.post(url, payload, get_access_token());
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  getConfigList: async (userId) => {
    const url = BASE_URL + "configuration/list";
    try {
      const response = await http.post(url, null, get_access_token());
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
  updateConfig: async (configId, value) => {
    const url = BASE_URL + "configuration/update";
    const payload = {
      configId,
      value,
    };
    try {
      const response = await http.post(url, payload, get_access_token());
      return response.data;
    } catch (err) {
      handleFailure(err);
    } finally {
    }
  },
};

export default adminService;
