// export const BASE_URL = "http://eazyerapp-env.eba-ptdrj3pp.us-east-2.elasticbeanstalk.com/api/";
// export const BASE_URL =
//   process.env.NODE_ENV === "production" ? "https://eazyer.com/api/" : "https://dev.eazyer.com/api/";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const USER_SIGNUP = BASE_URL + "auth/user-register";
export const USER_LOGIN = BASE_URL + "auth/user-login";
export const UPDATE_USER_STATUS = BASE_URL + "user/toggle-status";
// export const USER_FORGOT_PASSWORD = BASE_URL + "auth/forgot-password";
export const USER_FORGOT_PASSWORD = BASE_URL + "user-change-password";
export const USER_FORGOT_PASSWORD_GET_OTP = BASE_URL + "/user-forgot-otp";
export const USER_FORGOT_PASSWORD_VERIFY_OTP = BASE_URL + "/user-forgot-otp-verify";

export const MERCHANT_FORGOT_PASSWORD = BASE_URL + "merchant-change-password";
export const MERCHANT_FORGOT_PASSWORD_GET_OTP = BASE_URL + "/merchant-forgot-otp";
export const MERCHANT_FORGOT_PASSWORD_VERIFY_OTP = BASE_URL + "/merchant-forgot-otp-verify";

export const MERCHANT_SIGNUP = BASE_URL + "auth/merchant-register";
export const ADD_MERCHANT = BASE_URL + "merchant/store";
export const MERCHANT_DETAIL = BASE_URL + "merchant";
export const UPDATE_MERCHANT = BASE_URL + "merchant/update";
export const ADD_IMAGE_MERCHANT = BASE_URL + "merchant/add-image";
export const REMOVE_IMAGE_MERCHANT = BASE_URL + "merchant/delete-image";
export const MERCHANT_CHANGE_PASSWORD_ADMIN = BASE_URL + "merchant/change-password";
export const MERCHANT_SAVE_LOCATION_ADMIN = BASE_URL + "merchant/update-location";

export const CONTACT_QUERY = BASE_URL + "contact-query";
export const CONTACT_QUERY_LIST = BASE_URL + "contact-query-list";

export const USER_LIST = BASE_URL + "users";

export const MERCHANT_WORKING_HOURS = BASE_URL + "merchant/save-working-hours";
export const MERCHANT_STATUS = BASE_URL + "merchant/activate-deactivate";
export const MERCHANT_LIST = BASE_URL + "merchants";
export const MERCHANT_SAVE_KEYWORDS = BASE_URL + "merchant/save-keywords";
export const MERCHANTS_SLIDER = BASE_URL + "merchants-slider";
export const MERCHANTS_WEB = BASE_URL + "merchants-web";
export const STATE_LIST = BASE_URL + "states";
export const GET_USER_CONFIG = BASE_URL + "user/config";
export const TRIP_ADVISOR_DETAILS = BASE_URL + "merchant-tripadvisor-details";
export const MERCHANT_REVIEW = BASE_URL + "merchant-tripadvisor-reviews";

export const USER_AC_VERIFY = BASE_URL + "verify-email";
export const MERCHANT_AC_VERIFY = BASE_URL + "verify-merchant-email";

export const HOMESCREEN = BASE_URL + "user/homescreen";
export const SEARCH_MERCHANT = BASE_URL + "merchant-search";

export const SEARCH_CATEGORY_KEYWORD = BASE_URL + "keyword-search";
export const GET_MERCHANT = BASE_URL + "user/merchant-detail";
export const SAVE_CATEGORY = BASE_URL + "merchant/save-category";
export const ADD_CATEGORY_ADMIN = BASE_URL + "category/add";
export const UPDATE_CATEGORY_ADMIN = BASE_URL + "category/update";
export const CATEGORY_LIST_ADMIN = BASE_URL + "category/list";
export const CATEGORY_LIST_HIERARCHY_ADMIN = BASE_URL + "category/hierarchy";
export const CATGORY_ACTIVATE_DEACTIVATE = BASE_URL + "category/activate-deactivate";

export const MERCHANT_ACTIVATE = BASE_URL + "merchant/activate-deactivate";
export const MERCHANT_APPROVE = BASE_URL + "merchant/approve";
