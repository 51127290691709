import React, { useContext, useEffect, useState } from "react";
import WebContext from "../../../context/web-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import styles from "../Sections/Banner.module.css";
import { CommonContext } from "../../../store/commonStore";

const SeachbarAndPoolBarForSmallScreen = () => {
  const { windowDimensions, poolValue, searchBarModal } = useContext(WebContext);
  const { showSearchBar, setShowSearchBar } = useContext(WebContext);
  const buttonFontSize = windowDimensions.width <= 768 ? `${styles.memberPoolFontSize}` : "fs-4";
  const pollIconMargin = windowDimensions.width <= 768 ? 4 : 20;
  const [commonCtx] = CommonContext();

  const toggleVisible = () => {
    if (windowDimensions.width <= 768) {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 80) {
        setShowSearchBar(true);
      } else if (scrolled <= 50) {
        setShowSearchBar(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => {
      window.removeEventListener("scroll", toggleVisible);
      setShowSearchBar(false);
    };
  }, []);

  return (
    <>
      {searchBarModal()}

      {windowDimensions.width <= 768 && (
        <div
          className={`container-fluid align-items-center bg-full sticky-top ${!showSearchBar && "mt-4"}`}
          style={{
            backgroundColor: "#00446B",
            height: "50px",
            lineHeight: "50px",
            position: showSearchBar && "fixed",
            top: showSearchBar && 110,
            transition: ".3s ease-in-out",
          }}
        >
          <div className="container px-1 d-flex justify-content-center align-items-center  ">
            <div className={`${styles.poolContainer}`}>
              <div className={`text-white ${buttonFontSize}  ${styles.pool}`}>
                <div>
                  <span
                    style={{
                      marginRight: pollIconMargin,
                    }}
                  >
                    <FontAwesomeIcon
                      fontSize={windowDimensions.width <= 768 ? "smaller" : 20}
                      className={`text-white ${buttonFontSize} `}
                      icon={faUser}
                    />
                  </span>
                  <span
                    style={{
                      fontSize: windowDimensions.width <= 768 ? "smaller" : 12.5,
                    }}
                  >
                    Members Pool ${commonCtx?.pool?.member}
                  </span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className={`text-white fw-bold ${buttonFontSize} vr mx-1`}></div>
              </div>
              <div className={`text-white ${buttonFontSize} ${styles.pool}`}>
                <div>
                  <span
                    style={{
                      marginRight: pollIconMargin,
                    }}
                  >
                    <FontAwesomeIcon
                      fontSize={windowDimensions.width <= 768 ? "smaller" : 20}
                      className={`text-white ${buttonFontSize} `}
                      icon={faUsers}
                    />
                  </span>
                  <span
                    style={{
                      fontSize: windowDimensions.width <= 768 ? "smaller" : 12.5,
                    }}
                  >
                    Community Pool ${commonCtx?.pool?.community}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SeachbarAndPoolBarForSmallScreen;
