import React from "react";
import { useContext, useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { MERCHANT_WORKING_HOURS } from "../../../config/endpoints";
import MySpinner from "../../common/MySpinner";
import { toast } from "react-toastify";
import MerchantContext from "../../../context/merchant-context";
import myAxios from "../../../config/my-axios";

const daysName = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

function MerchantWorkingHours({
  merchantId = false,
  customStyle = false,
  loadMerchant,
  workingHours,
  saveButton = true,
}) {
  let initObj = {
    Sunday: "",
    Monday: "",
    Tuesday: "",
    Wednesday: "",
    Thursday: "",
    Friday: "",
    Saturday: "",
  };

  const [form, setForm] = useState(initObj);
  const [error, setError] = useState({ ...initObj });
  const { authMerchant } = useContext(MerchantContext);
  const [loader, setLoader] = useState(false);

  const saveWorkingHours = (e) => {
    e.preventDefault();
    let payload = {};
    payload = {
      merchantId: merchantId ? merchantId : "",
      open247: false,
      workingHours: [
        {
          day: 0,
          hours: form.Sunday,
        },
        {
          day: 1,
          hours: form.Monday,
        },
        {
          day: 2,
          hours: form.Tuesday,
        },
        {
          day: 3,
          hours: form.Wednesday,
        },
        {
          day: 4,
          hours: form.Thursday,
        },
        {
          day: 5,
          hours: form.Friday,
        },
        {
          day: 6,
          hours: form.Saturday,
        },
      ],
    };

    const config = {
      headers: {
        Authorization: `Bearer ${authMerchant?.token}`,
        "content-type": "application/json",
      },
    };

    setLoader(true);
    myAxios
      .post(MERCHANT_WORKING_HOURS, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          let data = res.data;
          if (data.success) {
            toast.success(data.message);
            loadMerchant();
          } else {
            toast.error(data.message);
          }
        } else {
          toast.error("something went wrong");
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.message);
      });
  };
  const loading = () => (
    <div
      className="d-flex justify-content-center align-items-center mb-5"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  const availableAndSaveButton = () => (
    <>
      <Row className="g-2 d-flex justify-content-start  align-items-center">
        <Col sm={6} md={6} lg={6}>
          <label className="form-check-label">Eg. 9:30AM - 5:30 PM</label>
        </Col>
        {saveButton && (
          <Col sm={6} md={6} lg={6} className=" d-flex justify-content-md-start justify-content-end ">
            <button
              disabled={loader}
              onClick={saveWorkingHours}
              type="submit"
              className="btn btn-success"
              form="workinhHours"
            >
              Save
            </button>
          </Col>
        )}
      </Row>
    </>
  );

  function handleForm(event) {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  }

  const timeFields = () => {
    return daysName.map((day) => (
      <>
        <Row key={day} className="mt-4  g-2 d-flex justify-content-start  align-items-center">
          <Col sm={10} md={12} lg={customStyle ? 12 : 8}>
            <Row>
              <Col xs={6} sm={6} md={customStyle ? 6 : 3} lg={customStyle ? 6 : 3} className="">
                <label className="text-muted">{day}</label>
              </Col>
              <Col xs={6} sm={6} md={customStyle ? 6 : 3} lg={customStyle ? 6 : 3} className="">
                <Form.Group className="">
                  {/* <Form.Control  size="sm" name={day} value={form[day]} onChange={handleForm} /> */}
                  <Form.Control as="textarea" rows={2} name={day} value={form[day]} onChange={handleForm} />
                  <label className="text-error">{error[day]}</label>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    ));
  };

  useEffect(() => {
    if (workingHours?.length > 0) {
      workingHours.forEach((ele) => {
        if (ele.day === 0) {
          setForm((prev) => ({
            ...prev,
            Sunday: ele.hours,
          }));
        }
        if (ele.day === 1) {
          setForm((prev) => ({
            ...prev,
            Monday: ele.hours,
          }));
        }
        if (ele.day === 2) {
          setForm((prev) => ({
            ...prev,
            Tuesday: ele.hours,
          }));
        }
        if (ele.day === 3) {
          setForm((prev) => ({
            ...prev,
            Wednesday: ele.hours,
          }));
        }
        if (ele.day === 4) {
          setForm((prev) => ({
            ...prev,
            Thursday: ele.hours,
          }));
        }
        if (ele.day === 5) {
          setForm((prev) => ({
            ...prev,
            Friday: ele.hours,
          }));
        }
        if (ele.day === 6) {
          setForm((prev) => ({
            ...prev,
            Saturday: ele.hours,
          }));
        }
      });
    }
  }, [workingHours]);

  return (
    <Card body className="mt-2 shadow py-2 mb-5">
      {loader && loading()}
      <Form id="workinhHours" onSubmit={saveWorkingHours}>
        {availableAndSaveButton()}
        {timeFields()}
      </Form>
    </Card>
  );
}

export default MerchantWorkingHours;
