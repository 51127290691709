import React, { useContext, useState } from "react";
import { Card, Carousel, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import WebContext from "../../../context/web-context";
import ShowMoreText from "react-show-more-text";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";

import Trip from "./trip_advisor.png";
import "./MerchantListCard.css";

function formateNumber(number) {
  let f_val = number.replace(/\D[^\.]/g, "");
  let formatted = f_val.slice(0, 3) + "-" + f_val.slice(3, 6) + "-" + f_val.slice(6);
  return formatted;
}

function getMerchantImages(merchantImages) {
  if (Array.isArray(merchantImages)) {
    if (merchantImages.length > 0) {
      return (
        <Carousel interval={null}>
          {merchantImages?.map((img, i) => {
            return img.image ? (
              <Carousel.Item key={i}>
                <img
                  className="d-block w-100"
                  src={img.image}
                  alt="Slider image"
                  style={{
                    width: "100%",
                    height: 200,
                    objectFit: "contain",
                  }}
                />
              </Carousel.Item>
            ) : null;
          })}
        </Carousel>
      );
    }
  }
}

function MerchantListCard({ merchant }) {
  const { windowDimensions } = useContext(WebContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const obj = Object.fromEntries(params);

  const [showShadow, setShowShadow] = useState(false);
  const showWorkingHour = (hour) => {
    var dateInNumber = new Date().getDay();
    const currentWeekDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][new Date().getDay()];
    const current = hour.filter((day) => day.day === dateInNumber)[0];
    return !!current?.hours.length && `${currentWeekDay} ${current?.hours || ""}`;
  };
  const handleReview = (merchant) => {
    if (merchant?.tripAdvisor.num_reviews.length) {
      navigate(`/review/${merchant?.id}`, { state: { merchantLogo: merchant.logo } });
    } else {
      toast.error("You don't have reviews");
    }
  };
  return (
    <Card
      className={`py-4 pb-0 my-4 bg-white border-0 merchantsListCard ${showShadow && "shadow"} `}
      style={{
        minHeight: windowDimensions.width <= 768 ? "auto" : 250,
        transition: "all .35s ease",
      }}
      onMouseOver={() => setShowShadow(true)}
      onMouseOut={() => setShowShadow(false)}
    >
      <section className="contact-sec container bg-transparent">
        <div className="container-fluid">
          <div className="row order-2">
            <div className="col-12 col-md-8 p-2 order-2 order-md-1 order-lg-1  ">
              <div className="row">
                {/* <div className="col-12 col-md-3  d-none  d-lg-block">
                  <div className="row justify-content-start  justify-content-md-center">
                    <div className="col-sm-10 col-4 p-1 mb-1 ">
                      <div className={`rounded p-1 text-center ${windowDimensions.width <= 768 ? "" : "border"}`}>
                        <img
                          style={{
                            width: "100%",
                            minHeight: windowDimensions.width <= 768 ? 150 : 100,
                            maxHeight: windowDimensions.width <= 768 ? 150 : 100,
                            objectFit: "contain",
                          }}
                          src={
                            merchant?.logo ? merchant?.logo : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"
                          }
                          alt="Card cap"
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* //mobile section/// */}
                <div className="col-12 d-md-none d-flex flex-row">
                  <div className="row py-2 gx-2">
                    <div className={`col-4 `}>
                      <Link to="/MerchantList">
                        <img
                          style={{
                            width: "100%",
                            // minHeight: 100,
                            // maxHeight: 100,
                            objectFit: "contain",
                          }}
                          src={
                            merchant?.logo ? merchant?.logo : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"
                          }
                          alt="Card  cap"
                        />
                      </Link>
                    </div>
                    <div className="col-4 fw-bold text-dark text-capitalize small">{merchant?.businessName}</div>
                    <div className="col-4 fw-bold text-capitalize small">
                      <div className="row">
                        <div className="col-12 text-secondary text-capitalize">{merchant?.address}</div>
                        <div className="col-12 text-secondary text-capitalize">
                          {merchant?.address2 ? merchant?.address2 : ""}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-secondary text-capitalize">
                          {merchant?.city}, {merchant?.state}
                          {merchant?.zipcode}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <a
                            className="text-secondary"
                            href={`tel:${merchant.phone}`}
                            style={{ textDecoration: "none" }}
                          >
                            {formateNumber(merchant.phone)}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row d-sm-none d-flex flex-row">
                  <div className="col-6 small px-1"> Today 7:00am - 9:00pm</div>
                  <div className="col-6 px-1">
                    <div className="row px-1">
                      <div className="col-6 px-0 mx-0">
                        <Button
                          onClick={() =>
                            navigate(`/MerchantProfile/${merchant?.id}`, {
                              state: { merchant },
                            })
                          }
                          size="sm"
                          variant="success"
                          className={`btn btn-success  px-1  mx-0`}
                        >
                          <span
                            style={{
                              fontSize: windowDimensions.width <= 768 ? "smaller" : 12.5,
                            }}
                          >
                            More Info
                          </span>
                        </Button>
                      </div>
                      <div className="col-6 px-0 mx-0">
                        <Button
                          size="sm"
                          variant="success"
                          className={`btn px-1  mx-0`}
                          style={{
                            backgroundColor: "#00446B",
                            fontSize: windowDimensions.width <= 768 ? "smaller" : 12.5,
                          }}
                        >
                          <span
                            style={{
                              fontSize: windowDimensions.width <= 768 ? "smaller" : 12.5,
                            }}
                          >
                            Website
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div
                  className="col-12 col-md-3 d-flex align-items-start text-md-start text-capitalize text-dark h4 mt-1 d-none d-md-block"
                  style={{
                    marginLeft: windowDimensions.width <= 768 ? 20 : null,
                  }}
                >
                  {merchant.businessName}
                </div> */}

                {/* <div
                  className="col-12 col-md-6  d-none  d-sm-block"
                  style={{
                    marginLeft: windowDimensions.width <= 768 ? 20 : null,
                    color: "#878686",
                  }}
                >
                  <div className="row">
                    <div className="col-12  text-capitalize">{merchant.address}</div>
                  </div>
                  <div className="row">
                    <div className="col-12  text-capitalize">
                      {merchant.city}, {merchant.state} {merchant.zipcode}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <a
                        className=""
                        href={`tel:${merchant.phone}`}
                        style={{ textDecoration: "none", color: "#878686" }}
                      >
                        {formateNumber(merchant.phone)}
                      </a>
                    </div>
                  </div>

                  <div className="row gx-1 mt-2">
                    <div className="col-6">
                      <Button
                        onClick={
                          () =>
                            navigate("/MerchantProfile", {
                              state: { merchant: merchant && merchant },
                            })
                          // history("/MerchantProfile", { merchant: merchant && merchant })
                        }
                        size="sm"
                        variant="success"
                        className={`btn btn-success d-block w-75`}
                      >
                        More Info
                      </Button>
                    </div>
                  </div>
                </div> */}
                <div className="merchantProfile-container">
                  <div className="merchant-profileImage">
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "0.25rem",
                      }}
                      src={merchant?.logo ? merchant?.logo : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"}
                      alt="Card cap"
                    />
                  </div>

                  <div className="merchant-detail">
                    <h3 className="bussiness-name">{merchant.businessName}</h3>

                    <div className="address">{merchant.address}</div>
                    <div className="bussiness-name">
                      {merchant.city}, {merchant.state} {merchant.zipcode}
                    </div>
                    <div className="phone">{formateNumber(merchant.phone)}</div>
                    <div className="time">
                      {showWorkingHour(merchant.workingHours) || <span></span>}
                      <Button
                        onClick={() =>
                          navigate(`/MerchantProfile/${merchant?.id}`, {
                            state: { merchant },
                          })
                        }
                        size="sm"
                        variant="success"
                        // className={`btn btn-success  px-1  mx-0`}
                      >
                        <span
                          style={{
                            fontSize: windowDimensions.width <= 768 ? "smaller" : 12.5,
                          }}
                        >
                          More Info
                        </span>
                      </Button>
                    </div>

                    {/* <div className="merchantProfile-rating">
                      <span className="star-container">
                        <Rating size={25} fillColor={"#FBFF2D"} readonly initialValue={4} />
                        <span style={{ fontSize: "21px" }}>(142)</span>
                      </span>
                      <Button
                        onClick={() =>
                          navigate("/MerchantProfile", {
                            state: { merchant },
                          })
                        }
                        size="sm"
                        variant="success"
                        // className={`btn btn-success  px-1  mx-0`}
                      >
                        <span
                          style={{
                            fontSize: windowDimensions.width <= 768 ? "smaller" : 12.5,
                          }}
                        >
                          More Info
                        </span>
                      </Button>
                    </div> */}
                  </div>
                </div>
              </div>

              {merchant?.tripAdvisor?.rating_image_url && (
                <div className="merchantProfile-rating">
                  <span onClick={() => handleReview(merchant)} className="star-container">
                    {/* <img
                    src={Trip}
                    alt="trip advisor"
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  /> */}

                    <img
                      src={merchant?.tripAdvisor.rating_image_url}
                      style={{
                        width: 165,
                        height: 30,
                      }}
                    />
                    {/* <Rating size={22} fillColor={"#FBFF2D"} readonly initialValue={merchant?.tripAdvisor.rating || 0} /> */}
                    {+merchant?.tripAdvisor.num_reviews > 0 && (
                      <span style={{ fontSize: "17px" }}>({merchant?.tripAdvisor.num_reviews})</span>
                    )}
                  </span>
                </div>
              )}
              <hr />
              <div className="row">
                {merchant.isDeals && (
                  <div className="col-12 col-md-3">
                    <Button
                      size="sm"
                      variant="success"
                      className={`btn btn-success d-block ${windowDimensions.width <= 768 ? "" : "w-100"} `}
                    >
                      Deal Available
                    </Button>
                  </div>
                )}
                <div
                  // ${styles.textTruncateContainer}
                  className={`col-12 col-md-12  ${windowDimensions.width <= 768 ? "py-2" : null} `}
                  style={{
                    marginLeft: windowDimensions.width <= 768 ? 20 : null,
                  }}
                >
                  <ShowMoreText lines={2} more="Show more" less="Show less">
                    <p>{merchant.description}</p>
                  </ShowMoreText>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-md-4  carousel-dark order-1 order-md-2 order-lg-2 "
              style={{ height: 250, overflow: "hidden" }}
            >
              {getMerchantImages(merchant.images)}
            </div>
          </div>
        </div>
      </section>
    </Card>
  );
}

export default MerchantListCard;
