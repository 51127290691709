import React, { useContext, useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Tab, Tabs } from "react-bootstrap";
import MerchantListCard from "../Pages/MerchantListCard";
import DealsListCard from "../Pages/DealsListCard";
import WebContext from "../../../context/web-context";
import MerchantListCardHomePage from "../Pages/MerchantListCardHomePage";
import DealsListCardForSmallScreen from "../Pages/DealsListCardForSmallScreen";
import styles from "./MerchantsAndDeals.module.css";
import "./MerchantsAndDeals.css";

function MerchantsAndDeals({ merchantsList, dealsList, params }) {
  const { windowDimensions, userLocationAddress, authUser } = useContext(WebContext);
  const [activeTab, setActiveTab] = useState("merchantList");
  const buttonFontSize = windowDimensions.width <= 768 ? "fs-6" : "fs-4";

  const noDataFound = (content) => {
    return <div className="text-center">No {content} found</div>;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
  }, []);
  const merchantContent = () => {
    return (
      <>
        {merchantsList?.length > 0 ? merchantsListCards() : noDataFound("merchant")}
        {merchantsList?.length > 0 && (
          <Link
            className={`btn btn-success mx-3`}
            to={`/MerchantList?lat=${params.latitude}&lng=${params.longitude}`}
            style={{ backgroundColor: "#00446B" }}
          >
            View More
          </Link>
        )}
      </>
    );
  };

  const dealsContent = () => (
    <>
      {dealsList?.length > 0 ? dealsListCards() : noDataFound("deal")}
      {dealsList?.length > 0 && (
        <Link className={`btn btn-success mx-3`} to="/Deals" style={{ backgroundColor: "#00446B" }}>
          View More
        </Link>
      )}
    </>
  );

  const merchantsListCards = () => {
    return (
      <span>
        {merchantsList?.map((merchant) => {
          return windowDimensions.width <= 768 ? (
            <MerchantListCardHomePage key={merchant?.id} merchant={merchant} />
          ) : (
            <MerchantListCard key={merchant?.id} merchant={merchant} />
          );
        })}
      </span>
    );
  };
  const dealsListCards = () => {
    return dealsList?.map((deals, index) => {
      return windowDimensions.width <= 768 ? (
        <DealsListCardForSmallScreen key={deals?.id} deals={deals} image={deals?.image} />
      ) : (
        <DealsListCard key={deals?.id} deals={deals} />
      );
    });
  };

  return (
    <section
      style={{
        scrollMarginTop: windowDimensions.width <= 768 ? 1000 : 1000,
      }}
      id="MerchantsAndDealsSection"
      className={`container-fluid mt-4 py-4 ${styles.MerchantsAndDeals}`}
    >
      <div className="container ">
        {/* <Tabs
          defaultActiveKey="merchantList"
          id="MerchantsAndDeals"
          className="mb-3 d-flex justify-content-start align-items-center"
          style={{
            backgroundColor: "none",
            borderColor: "none",
          }}
          onSelect={(k) => setActiveTab(k)}
        >
          <Tab
            eventKey="merchantList"
            title={
              <p
                className={
                  activeTab == "merchantList" ? `btn btn-success ${buttonFontSize}` : `text-success ${buttonFontSize}`
                }
              >
                Listings
              </p>
            }
          >
            {merchantContent()}
          </Tab>
          <Tab
            eventKey="dealsAvailable"
            title={
              <p
                className={
                  activeTab == "dealsAvailable" ? `btn btn-success ${buttonFontSize}` : `text-success ${buttonFontSize}`
                }
              >
                Deals
              </p>
            }
          >
            {dealsContent()}
          </Tab>
        </Tabs> */}

        {merchantContent()}
      </div>
    </section>
  );
}

export default MerchantsAndDeals;
