import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot, faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import axios from "axios";
import { CONTACT_QUERY } from "../../../config/endpoints";
import MySpinner from "../../common/MySpinner";
import styles from "./Contact.module.css";
import WebContext from "../../../context/web-context";
import { useLocation } from "react-router-dom";

function Contact() {
  const { authUser, windowDimensions } = useContext(WebContext);
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  const [form, setForm] = useState({
    name: authUser ? authUser.user.firstName + " " + authUser.user.lastName : "",
    email: authUser ? authUser.user.email : "",
    subject: "",
    message: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [load, setLoad] = useState(false);

  function handleForm(e) {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }

  function submitForm(e) {
    e.preventDefault();

    if (!form.name || !form.email || !form.subject || !form.message) {
      toast.error("Please complete the form and then submit!!");
      return;
    }
    setLoad(true);
    axios
      .post(CONTACT_QUERY, form)
      .then((res) => {
        if (res.data.success) {
          // code to replace the form with greeting
          setSubmitted(true);
          setLoad(false);
        } else {
          toast.error(res.data.message);
          setLoad(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoad(false);
      });
  }

  const loader = () => (
    <div className="d-flex justify-content-center align-items-center text-success">
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  return (
    <div
      className="rounded-0 border-0 pad-top-sm pad-bottom-xs"
      id="contactus"
      style={{ scrollMarginTop: windowDimensions.width <= 768 ? 45 : 170 }}
    >
      <section className="rounded contact-sec container bg-grey pad-bottom-xs px-3 px-md-5 py-3">
        <div className="row pt-3">
          <div className="col-12">
            <h3 className="text-center text-md-start fw-bold" style={{ color: "#218C44" }}>
              Contact Us
            </h3>
          </div>
        </div>

        <div className="row pt-2">
          <div className="col-12 col-md-6 position-relative">
            {/* {load && loader()} */}
            {load ? (
              loader()
            ) : !submitted ? (
              <>
                <form action="#" onSubmitCapture={submitForm}>
                  <div className="row pt-2">
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        name="name"
                        value={form.name}
                        onChange={handleForm}
                        className={[styles.inputBorder, "form-control"].join(" ")}
                        placeholder="Name *"
                      />
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-12 col-md-10">
                      <input
                        type="email"
                        name="email"
                        value={form.email}
                        onChange={handleForm}
                        className={[styles.inputBorder, "form-control"].join(" ")}
                        placeholder="Email Address *"
                      />
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        name="subject"
                        value={form.subject}
                        onChange={handleForm}
                        className={[styles.inputBorder, "form-control"].join(" ")}
                        placeholder="Subject"
                      />
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-12 col-md-10">
                      <textarea
                        className={[styles.inputBorder, "form-control"].join(" ")}
                        name="message"
                        value={form.message}
                        onChange={handleForm}
                        placeholder="Your Message *"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-12 col-md-10 text-center text-md-start">
                      <button type="submit" className="register-now btn btn-success w-100">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <>
                <div
                  className="d-flex flex-column justify-content-center align-items-center w-100"
                  style={{ height: "250px" }}
                >
                  <h3 className={styles.thankyou}>Thank you</h3>
                  <p style={{ color: "#434d56" }}>for contacting us</p>
                  <p className="text-dark">We'll get back to you soon</p>
                  <p className="text-dark">
                    If you need immediate assistance with anything, please feel free to call us at 920-903-1010 and we
                    will be happy to help you.
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="col-12 col-md-6 gy-2">
            <div className="row gx-3">
              <div className="col-2 text-center">
                <FontAwesomeIcon
                  className="text-success"
                  style={{
                    background: "#fff",
                    padding: 10,
                    borderRadius: "50%",
                  }}
                  icon={faMobileScreen}
                />
              </div>
              <div className="col">
                <div className="row">
                  <div className="col-12 h5">
                    <strong>Phone Number</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <a href="tel:920-903-1010" className="text-dark">
                      920-903-1010
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row gx-3 my-2">
              <div className="col-2 text-center">
                <FontAwesomeIcon
                  className="text-success"
                  style={{
                    background: "#fff",
                    padding: 10,
                    borderRadius: "50%",
                  }}
                  icon={faEnvelope}
                />
              </div>
              <div className="col">
                <div className="row">
                  <div className="col-12 h5">
                    <strong>Email Address</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <a href="mailto:eazyersupport@eazyer.com" className="text-dark">
                      eazyersupport@eazyer.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-2 text-center">
                <FontAwesomeIcon
                  className="text-success"
                  style={{
                    background: "#fff",
                    padding: 10,
                    borderRadius: "50%",
                  }}
                  icon={faLocationDot}
                />
              </div>
              <div className="col">
                <div className="row">
                  <div className="col-12 h5">
                    <strong>Address</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <address>
                      <Row>
                        <Col>Rockin Rewards, LLC</Col>
                      </Row>
                      <Row>
                        <Col> 101 W Edison Ave.</Col>
                      </Row>
                      <Row>
                        <Col>Suite 234</Col>
                      </Row>
                      <Row>
                        <Col> Appleton, WI 54915</Col>
                      </Row>
                    </address>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
