import { LOGGED_ADMIN } from "../config/constants";

export const get_access_token = () => {
  const data = JSON.parse(sessionStorage.getItem(LOGGED_ADMIN));
  const access_token = data.token;
  if (access_token) {
    const token = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };

    return token;
  } else {
    return undefined;
  }
};
