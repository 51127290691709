import { Button, Spinner } from "react-bootstrap";
import ReactLoading from "react-loading";

const spinnerType = "spin";

function MySpinner({ height = undefined, width = undefined }) {
  return (
    <ReactLoading
      height={height}
      width={width}
      type={spinnerType}
      color="green"
    />
  );
}

export default MySpinner;
