import React, { useContext, useEffect, useState } from "react";
import Banner from "../Sections/Banner";
import Contact from "../Sections/Contact";
import Categories from "../Sections/Categories";
import MerchantsAndDeals from "../Sections/MerchantsAndDeals";
import SeachbarAndPoolBarForBigScreen from "../Pages/SeachbarAndPoolBarForBigScreen";
import commonService from "../../../services/common/commonService";
import WebContext from "../../../context/web-context";
import Loader from "../../common/Loader";
import { defaultLat, defaultLnt } from "../../common/googlePlaceApiKey";

function Section() {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { authUser, userLng, userLat, setPoolValue, userLocationAddress } = useContext(WebContext);
  const [offset, setOffset] = useState(0);
  const params = {
    latitude: authUser
      ? authUser?.location?.latitude
      : userLocationAddress?.lat
      ? userLocationAddress?.lat
      : defaultLat,
    longitude: authUser
      ? authUser?.location?.longitude
      : userLocationAddress?.lng
      ? userLocationAddress?.lng
      : defaultLnt,
  };
  useEffect(() => {
    setLoader(true);
    const fetchHomeData = async () => {
      const homeScreenData = await commonService.getHomeScreenData(params);
      if (homeScreenData) {
        setData(homeScreenData);
        setPoolValue(homeScreenData?.pool);
      }
      setLoader(false);
    };
    fetchHomeData();
  }, [userLng]);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const merchantsListData = data?.merchants?.length > 0 ? data?.merchants : [];
  return (
    <main id="main">
      {loader ? (
        <Loader height="80vh" width="100vw" />
      ) : (
        <>
          <Banner poolData={data.pool} />
          {offset > 444 && <SeachbarAndPoolBarForBigScreen poolData={data.pool} />}
          <Categories categories={data?.categories?.length > 0 ? data?.categories : []} />
          <MerchantsAndDeals
            params={params}
            merchantsList={merchantsListData}
            dealsList={data?.deals?.length > 0 ? data?.deals : []}
          />
          <Contact />
        </>
      )}
    </main>
  );
}

export default Section;
