import React, { useContext, useLayoutEffect } from "react";
import { Card, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import WebContext from "../../../context/web-context";
import SeachbarAndPoolBarForBigScreen from "./SeachbarAndPoolBarForBigScreen";
import SeachbarAndPoolBarForSmallScreen from "./SeachbarAndPoolBarForSmallScreen";

function Aboutus() {
  const location = useLocation();
  const { windowDimensions } = useContext(WebContext);

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <SeachbarAndPoolBarForSmallScreen />
      <SeachbarAndPoolBarForBigScreen />

      <Card
        style={{ paddingTop: windowDimensions?.width > 768 && "85px" }}
        className="rounded-0 border-0 border-top bg-grey"
      >
        <section className="contact-sec container bg-white py-3 px-3">
          <Container>
            <div className="row">
              <h2 style={{ color: "#218C44" }}>Company Overview</h2>
            </div>
          </Container>
          <Container>
            <div className="row">
              <p>
                Money today does not go as far as it used to. Saving money has become more important. It’s no easy task
                to find deals on everything you need, let alone in one place. We understand the importance of this
                concept, and have discovered a way to provide the most savings possible, all under one roof. It is our
                goal to make your life EAZYER than ever!{" "}
              </p>
              <p>
                Eazyer.com is a privately held "Daily Deals," directory listings, and reviews company. Think of other
                sites that offer "Daily Deals," listings and reviews; then think about what they could change that would
                make your experience more rewarding. We are determined to provide the latest and greatest deals and
                incentives to suit our Members’ needs, and deliver the best customer service possible to our both our
                Merchants and Members. As we are expanding across the nation, we are reaching new Consumers and
                Merchants every day.
              </p>
              <p>
                {" "}
                Eazyer offers Merchants the ability to offer discounted Deals to Members. Merchants will have the
                benefit of having both a dedicated, ad free Listing and Profile Page on the site. We also offer enhanced
                packages, allowing us to tailor our services to your exact needs and budget. We help local Merchants
                reach more customers, increasing business and community development.{" "}
              </p>
              <p>
                Our Members have exclusive access to the best possible deals, no matter where they are. Multiple
                subscription packages are available for our members as well, which provide additional monthly entries
                into our rewards and giveaways. With unique features like our points system, Community Pool, and Members
                Pool, our Members’ experience with Eazyer.com is now more rewarding and exclusive.
              </p>
              <p>
                {" "}
                Here at Eazyer, we’re a team of dedicated and energetic individuals. We have searched every nook and
                cranny to provide drool-worthy steals, and help you save money! No need to think twice before stepping
                into a store again! With Eazyer, take comfort in knowing that not only are you getting the best possible
                deal on all your needs, but you’re actively helping your local businesses and community grow!
              </p>
              <p>
                {" "}
                With our location based services, we can provide you with the best possible deals, no matter where you
                are! So whether you’re on the go and need a deal fast, on vacation in a new place, or simply relaxing at
                home, Eazyer.com is there to meet all of your needs.
              </p>
              <p>
                Here at Eazyer.com, we take pride in our culture. Our core values have been an integral part of our
                success. They are a reflection of what is important to us as a team and a business. Take a moment to
                browse Eazyer.com, sign up, and start benefiting from instant rewards today!
              </p>
            </div>
          </Container>
        </section>
      </Card>
    </>
  );
}

export default Aboutus;
