import React, { useEffect, useState } from "react";
import DashboardContainer from "../components/DashboardContainer";
import MySpinner from "../../common/MySpinner";
import PageTitle from "../components/PageTitle";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import adminService from "../../../services/admin/adminServices";
import { Card, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { Button, Input, Popconfirm } from "antd";

const Configuration = () => {
  const [loading, setLoading] = useState(false);
  const [configData, setConfigData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [edit, setEdit] = useState("");
  const options = {
    onSizePerPageChange: (sizePerPage, page) => {},
    onPageChange: setCurrentPageNumber,
    page: currentPageNumber,
  };

  useEffect(() => {
    fetchConfigList();
  }, []);

  const fetchConfigList = async () => {
    setLoading(true);
    const data = await adminService.getConfigList();
    if (data.success) setConfigData(data?.data.map((item, index) => ({ ...item, rowIndex: index + 1 })));
    else toast.error(data.message);
    setLoading(false);
  };

  const loader = () => (
    <div
      className="d-flex justify-content-center align-items-center text-success"
      style={{ height: "70vh", width: "auto" }}
    >
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  const noData = () => <Container className="d-flex justify-content-center">No data Found</Container>;

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row, rowIndex, colIndex) => row.rowIndex,
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "value",
      text: "value",
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: (cell, row, rowIndex, colIndex) => (
        <Input.Group>
          <Input
            key={row}
            placeholder="Please enter numeric value and enter to save"
            style={{ width: "calc(100% - 200px)" }}
            onPressEnter={(e) => updateConfigValue(cell, e.target.value)}
          />
        </Input.Group>
      ),
    },
  ];

  const updateConfigValue = async (id, edit) => {
    const data = await adminService.updateConfig(id, edit);
    if (data.success) fetchConfigList();
    else toast.error(data.message);
  };

  const content = () =>
    configData.length > 0 ? (
      <Card body className="shadow p-2">
        <BootstrapTable
          keyField="id"
          data={configData}
          columns={columns}
          pagination={paginationFactory(options)}
          callBack={() => {}}
          striped
          bordered={false}
          wrapperClasses="table-responsive"
        />
      </Card>
    ) : (
      noData()
    );
  return (
    <DashboardContainer>
      <PageTitle title="Configuration" />
      {loading ? loader() : content()}
    </DashboardContainer>
  );
};

export default Configuration;
