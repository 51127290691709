import { React, useContext, useState } from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import WebContext from "../../../context/web-context";
import ShowMoreText from "react-show-more-text";

import "./MerchantListCardHomePage.css";
import { toast } from "react-toastify";
function formateNumber(number) {
  // const numWithoutDashes = number.replace(/[^0-9]/g, "");
  // if (numWithoutDashes.length > 10) return number.slice(0, -1);
  // const dashPlaces = [3, 6];
  // return numWithoutDashes
  //   .split("")
  //   .reduce(
  //     (acc, curr, i) =>
  //       dashPlaces.includes(i) ? [...acc, "-", curr] : [...acc, curr],
  //     []
  //   )
  //   .join("");

  let f_val = number.replace(/\D[^\.]/g, "");
  let formatted = f_val.slice(0, 3) + "-" + f_val.slice(3, 6) + "-" + f_val.slice(6);
  return formatted;
}

function MerchantListCardHomePage({ merchant }) {
  const { windowDimensions } = useContext(WebContext);
  const navigate = useNavigate();
  const [showShadow, setShowShadow] = useState(false);

  const showWorkingHour = (hour) => {
    var dateInNumber = new Date().getDay();
    const currentWeekDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][new Date().getDay()];
    const current = hour.filter((day) => day.day === dateInNumber)[0];

    return !!current?.hours.length && `${currentWeekDay} ${current?.hours || ""}`;
  };
  const handleReview = (merchant) => {
    if (merchant?.tripAdvisor.num_reviews.length) {
      navigate(`/review/${merchant?.id}`, { state: { merchantLogo: merchant.logo } });
    } else {
      toast.error("You don't have reviews");
    }
  };
  return (
    <Card
      className={`py-4 my-4 bg-white border-0 merchantsListCard ${showShadow && "shadow"} `}
      style={{
        minHeight: windowDimensions.width <= 768 ? "auto" : 290,
        transition: "all .35s ease",
      }}
      onMouseOver={() => setShowShadow(true)}
      onMouseOut={() => setShowShadow(false)}
    >
      <section className="contact-sec container bg-transparent">
        {/*<div className="container-fluid">
          <div className="row order-2">
            <div className="col-12 px-0">
              <div className="row gx-0 px-0 me-0 flex-nowrap">
                <div className="col-7 px-0 mx-0">
                  <div className="row">
                    <div className="col-5 ">
                      <div className="row px-0 ">
                        <div className="col-12 d-flex justify-content-center align-items-center mx-0 px-0  p-1 mb-1 ">
                          <div
                            className={`rounded-3 p-1 ms-4 border px-2 text-center`}
                            style={{
                              width: 83,
                              height: 50,
                            }}
                          >
                            <img
                              style={{
                                width: "100%",
                                minHeight: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                              }}
                              src={
                                merchant?.logo
                                  ? merchant?.logo
                                  : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"
                              }
                              alt="Card cap"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-7 fw-bold small ps-2 mx-0 px-0 align-items-start text-capitalize  mt-1"
                      style={{
                        marginLeft: 20,
                        fontSize: "14px",
                      }}
                    >
                      {merchant.businessName}

                      <div className={`mt-1`}>
                        <Rating size={15} fillColor={"#FBFF2D"} readonly initialValue={4} />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 small ms-4" style={{ fontSize: 10 }}>
                      Today 7:00am - 9:00pm
                    </div>
                  </div>
                </div>
                <div
                  className="col-5 pe-3 small"
                  style={{
                    marginLeft: 20,
                    textAlign: "end",
                    fontSize: "10px",
                  }}
                >
                  <div className="row d-flex justify-content-end ">
                    <div className="col-12 text-secondary text-capitalize">{merchant.address}</div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-capitalize" style={{ color: "#878686" }}>
                      {merchant.city}, {merchant.state} {merchant.zipcode}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <a href={`tel:${merchant.phone}`} style={{ textDecoration: "none", color: "#878686" }}>
                        {formateNumber(merchant.phone)}
                      </a>
                    </div>
                  </div>
                  <div className="row mt-2 ">
                    <div
                      className="col-12 d-block mx-0"
                      style={{
                        textAlign: "end",
                      }}
                    >
                      <Button
                        onClick={() =>
                          navigate("/MerchantProfile", {
                            state: { merchant },
                          })
                        }
                        style={{
                          fontSize: "0.6rem",
                        }}
                        size="sm"
                        variant="success"
                        className={`btn btn-success  px-2`}
                      >
                        More Info
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row ">
                {merchant.isDeals && (
                  <div className="col-5 d-block" style={{ textAlign: "center" }}>
                    <Button size="sm" variant="success" className={`btn btn-success`}>
                      Deal Available
                    </Button>
                  </div>
                )}
                <div className={`col-7 py-2   `} style={{ fontSize: "0.8rem" }}>
                  <ShowMoreText lines={2} more="Show more" less="Show less">
                    <p>{merchant.description}</p>
                  </ShowMoreText>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* For Mobile view */}
        <div>
          <div className="merchant-container">
            <div className="merchant-image">
              <img
                style={{
                  width: "100%",
                  minHeight: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
                src={merchant?.logo ? merchant?.logo : "https://eayer.s3.us-east-2.amazonaws.com/logo-dummy.png"}
                alt="Card cap"
              />
            </div>
            <div className="merchant-info">
              <h6> {merchant.businessName}</h6>
              <div className="address-size">{merchant.address}</div>
              <div className="address-size">
                {merchant.city}, {merchant.state} {merchant.zipcode}
              </div>
              <div className="address-size">{formateNumber(merchant.phone)}</div>
              <div className="address-size">{showWorkingHour(merchant?.workingHours) || <span></span>}</div>

              {/* <div className="merchant-rating">
                <Rating size={20} fillColor={"#FBFF2D"} readonly initialValue={4} />
                <span style={{ fontSize: "15px" }}>(142)</span>
              </div> */}
            </div>
          </div>
          <div className="date-section">
            <div className="merchant-rating" onClick={() => handleReview(merchant)}>
              <img src={merchant?.tripAdvisor?.rating_image_url} />
              {/* <img
                src={Trip}
                alt="trip advisor"
                style={{
                  width: 20,
                  height: 20,
                }}
              />  
              <Rating size={15} fillColor={"#FBFF2D"} readonly initialValue={merchant?.tripAdvisor.rating || 0} /> */}
              {merchant.tripAdvisor && +merchant.tripAdvisor.num_reviews > 0 && (
                <span style={{ fontSize: "11px" }}>({merchant?.tripAdvisor.num_reviews})</span>
              )}
            </div>
            {/* <div className="gray-color">Thu 9:30AM - 5:30 PM</div> */}
            {/* <div className="gray-color">{showWorkingHour(merchant?.workingHours)}</div> */}
            <Button
              onClick={() => navigate(`/MerchantProfile/${merchant.id}`)}
              style={{
                fontSize: "0.6rem",
              }}
              size="sm"
              variant="success"
              className={`btn btn-success  px-2`}
            >
              More Info
            </Button>
          </div>
          <hr />
          <div>
            <ShowMoreText lines={2} more="Show more" less="Show less">
              <p>{merchant.description}</p>
            </ShowMoreText>
          </div>
        </div>
      </section>
    </Card>
  );
}

export default MerchantListCardHomePage;
