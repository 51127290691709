import React, { useContext, useState, useEffect, useLayoutEffect } from "react";
import { Link, useLocation, useNavigate, Navigate } from "react-router-dom";
import { Card, Col, Container, Form, Nav, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import MySpinner from "../../common/MySpinner";
import axios from "axios";
import { STATE_LIST } from "../../../config/endpoints";
import WebContext from "../../../context/web-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { SingUpContext } from "../../../store/signUpStore";
import commonService from "../../../services/common/commonService";
import styles from "./SignUp.module.css";
import { Button } from "antd";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const PWD_DIGIT_REGEX = /(?=.*\d)/; //should contain atleast 1 digit
const PWD_LOWER_REGEX = /(?=.*[a-z])/; //should contain atleast 1 lowercase
const PWD_UPPER_REGEX = /(?=.*[A-Z])/; //should contain atleast 1 uppercase
const PWD_SPCHAR_REGEX = /[$@$!%*?&]/; //should contain atleast 1 Special Character

function SignUp() {
  const { authUser, setAuthUser } = useContext(WebContext);
  // const [form, setForm] = useState(initObj);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [load, setLoad] = useState(false);
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [chkDigit, setChkDigit] = useState(false);
  const [chkLower, setChkLower] = useState(false);
  const [chkUpper, setChkUpper] = useState(false);
  const [chkSpecialChar, setChkSpecialChar] = useState(false);
  const [chkChar, setChkChar] = useState(false);
  const [{ memberFormData: form }, dispatchSignUp] = SingUpContext();
  const [data, setData] = useState({
    states: [],
  });
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    axios
      .get(STATE_LIST)
      .then((res) => {
        if (res.data.success) {
          setData({
            ...data,
            states: res.data.data.states,
          });
          setLoad(false);
        } else {
          setLoad(false);
        }
      })
      .catch((err) => {});
  }, []);

  function handleForm(e) {
    const name = e.target.name;
    const value = e.target.value;
    // setForm({
    //   ...form,
    //   [name]: value,
    // });
    dispatchSignUp({ type: "FILL_MEMBER_FORM", payload: { name, value } });
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChangeEmail = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (!isValidEmail(value)) {
      setError("Email is invalid");
    } else {
      setError(null);
    }
    // setForm({
    //   ...form,
    //   [e.target.name]: e.target.value,
    // });
    dispatchSignUp({ type: "FILL_MEMBER_FORM", payload: { name, value } });
  };

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(form.password));
    setChkDigit(PWD_DIGIT_REGEX.test(form.password));
    setChkLower(PWD_LOWER_REGEX.test(form.password));
    setChkUpper(PWD_UPPER_REGEX.test(form.password));
    setChkSpecialChar(PWD_SPCHAR_REGEX.test(form.password));
    form.password.length > 8 ? setChkChar(true) : setChkChar(false);
  }, [form.password]);

  // function handlePwdVal(e) {
  //   // setForm({
  //   //   ...form,
  //   //   password: e.target.value,
  //   // });
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   dispatchSignUp({ type: "FILL_MEMBER_FORM", payload: { name, value } });

  // }

  function handleDate(e) {
    // setForm({
    //   ...form,
    //   dob: e.target.value
    //     .replace(/^(\d\d)(\d)$/g, "$1/$2")
    //     .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
    //     .replace(/[^\d\/]/g, ""),
    // });
    const name = e.target.name;
    const value = e.target.value
      .replace(/^(\d\d)(\d)$/g, "$1/$2")
      .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
      .replace(/[^\d\/]/g, "");
    dispatchSignUp({ type: "FILL_MEMBER_FORM", payload: { name, value } });
  }
  const handleCheckbox = (name) => {
    const value = !form.checked;
    dispatchSignUp({ type: "FILL_MEMBER_FORM", payload: { name, value } });
  };

  const submitForm = async () => {
    if (!form.checked) {
      toast.error("Please accept the terms and conditions");
      return false;
    }
    if (error) {
      toast.error(error);
    } else {
      setLoad(true);
      const data = await commonService.memberSignUp(form);
      if (data?.success) {
        toast.success("Your registration is successful. Please check your email to verify the account");
        dispatchSignUp({ type: "RESET_MEMBER_FORM" });
      } else {
        toast.error(data.message);
      }
      setLoad(false);
    }
  };

  //
  if (authUser && authUser.token) {
    return <Navigate to="/" replace={true} />;
  }

  const loader = () => (
    <div className="d-flex  justify-content-center ">
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );

  return (
    <section className="log-sec signup-section">
      <Container
        fluid
        className="d-flex justify-content-center align-items-center w-100 p-md-5 p-2"
        style={{ minHeight: "inherit" }}
      >
        <Col md="8" lg="7">
          <Card className={[styles.cardHeight, "shadow py-3"].join(" ")}>
            <Card.Body>
              {load && loader()}

              <div className="register-tabs">
                <Button className="activeButton" size="large" onClick={() => navigate("/Signup")}>
                  Member Signup
                </Button>
                <Button size="large" onClick={() => navigate("/MerchantSignup")}>
                  Merchant Signup
                </Button>
              </div>

              <div className="rounded">
                <div className="gy-4 my-2">
                  <div className="text-center">
                    <h4 style={{ color: "#218C44" }}>Register Now For Your FREE Lifetime Premium Membership!</h4>
                  </div>
                </div>
                <div className="row gy-4 pt-3 px-md-4">
                  <div className="col-12 col-md-6">
                    <input
                      value={form.firstName}
                      name="firstName"
                      onChange={handleForm}
                      className="form-control"
                      placeholder="First Name*"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      name="lastName"
                      value={form.lastName}
                      onChange={handleForm}
                      className="form-control"
                      placeholder="Last Name*"
                    />
                  </div>
                </div>
                <div className="row gy-4 pt-3 px-md-4">
                  <div className="col-12 col-md-6">
                    <input
                      name="email"
                      type="email"
                      value={form.email}
                      onChange={handleChangeEmail}
                      className="form-control"
                      placeholder="Email*"
                      autoComplete="false"
                    />
                    {/* {error && <label className="text-error">{error}</label>} */}
                    {error && <h5 className="text-error">{error}</h5>}
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="password"
                      name="password"
                      value={form.password}
                      onChange={handleForm}
                      className="form-control"
                      placeholder="Password*"
                      aria-invalid={validPwd ? "false" : "true"}
                      aria-describedby="pwdnote"
                      onFocus={() => setPwdFocus(true)}
                      onBlur={() => setPwdFocus(false)}
                    />
                  </div>
                </div>

                <div className="row gy-4 pt-3 px-md-4">
                  <div className="col-12 col-md-6">
                    <input
                      name="dob"
                      size="10"
                      maxLength="10"
                      value={form.dob}
                      onChange={handleDate}
                      inputMode="numeric"
                      className="form-control"
                      placeholder="DOB (mm/dd/yyyy)*"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      name="zipcode"
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      value={form.zipcode}
                      onChange={handleForm}
                      className="form-control"
                      placeholder="Zip code*"
                    />
                  </div>
                </div>
                <div className="row gy-4 pt-3 px-md-4">
                  <div className="col-12 col-md-6">
                    <input
                      name="city"
                      value={form.city}
                      onChange={handleForm}
                      className="form-control"
                      placeholder="City*"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Select name="state" onChange={handleForm} value={form.state}>
                      <option value="" className={styles.formTextColors}>
                        -- select state --
                      </option>
                      {data.states.map((state, index) => {
                        return (
                          <option key={index} value={state.name} className={styles.formTextColors}>
                            {state.abbreviation}
                            {/* {`${state.name} (${state.abbreviation})`} */}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {/* <input
                      name="state"
                      value={form.state}
                      onChange={handleForm}
                      className="form-control"
                      placeholder="State*"
                    /> */}
                  </div>
                </div>
                <div className="row gy-4 pt-3 px-md-4 mb-2">
                  <div className="col-12 col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        value="checked"
                        name="checked"
                        checked={form.checked}
                        onChange={(e) => {
                          // setForm({ ...form, checked: !form.checked });
                          handleCheckbox(e.target.name);
                          // dispatchSignUp({ type: "FILL_MEMBER_FORM", payload: { "checked", !form.checked } });
                        }}
                        type="checkbox"
                        id="checkRemember"
                      />
                      <label className="form-check-label" htmlFor="checkRemember">
                        <Link
                          to="/TermsAndConditions"
                          // className="text-black text-muted"
                          style={{ textDecoration: "none" }}
                        >
                          Terms and Conditions
                        </Link>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 pt-3 px-md-4">
                  <div id="pwdnote" className={`row ${pwdFocus && !validPwd ? styles.instructions : styles.offscreen}`}>
                    <div className="row">
                      <div className="col-12 col-lg-6 col-md-12">
                        Must include Uppercase letters.{" "}
                        {chkUpper ? (
                          <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                        ) : (
                          <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                        )}
                      </div>
                      <div className="col-12 col-lg-6 col-md-12">
                        Must include lowercase letters.{" "}
                        {chkLower ? (
                          <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                        ) : (
                          <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 col-md-12">
                        At Least 1 number.{" "}
                        {chkDigit ? (
                          <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                        ) : (
                          <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                        )}
                      </div>
                      <div className="col-12 col-lg-6 col-md-12">
                        Allowed special characters: <span aria-label="exclamation mark">!</span>{" "}
                        <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span>{" "}
                        <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>{" "}
                        {chkSpecialChar ? (
                          <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                        ) : (
                          <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 col-md-12">
                        Minimum 8 characters.{" "}
                        {chkChar ? (
                          <FontAwesomeIcon className={styles.facheck} icon={faCheckCircle} />
                        ) : (
                          <FontAwesomeIcon className={styles.faXcheck} icon={faCircleXmark} />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <span className="text-primary">
                    Password shoud contain one uppercase, numeric and special character
                    </span> */}
                  <div className="col-12 text-left">
                    <button
                      type="button"
                      onClickCapture={submitForm}
                      className="register-now btn btn-success"
                      style={{ lineHeight: 1 }}
                    >
                      Register Now
                    </button>
                  </div>
                  <div className="col-12 text-center">
                    <label className="fs-6">
                      Already have an account?{" "}
                      <Link smooth to="/signin">
                        Sign In
                      </Link>
                    </label>
                  </div>

                  {/* <div className="col-12 text-center">
                    <label className="fs-6">
                    If you have any questions, please feel free to <Link smooth to="/#contactus">Contact Us</Link> anytime.
                    </label>
                  </div> */}
                </div>
              </div>

              <p></p>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    </section>
  );
}

export default SignUp;
