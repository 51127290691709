import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { LOGGED_ADMIN } from "../../../config/constants";
import {toast} from 'react-toastify'
import AdminContext from "../../../context/admin-context";

function AdminLogout() {

    const {setAuthUser} = useContext(AdminContext)

    sessionStorage.removeItem(LOGGED_ADMIN)
    setAuthUser(null)
    toast.info("Logged out successfully")
    return <Navigate to="/admin-login" replace />
}

export default AdminLogout