import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

import { Badge, Container, Card, OverlayTrigger, Popover, Button } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { MERCHANT_ACTIVATE, MERCHANT_APPROVE, MERCHANT_LIST, MERCHANT_STATUS } from "../../../config/endpoints";
import myAxios from "../../../config/my-axios";
import DashboardContainer from "../components/DashboardContainer";
import PageTitle from "../components/PageTitle";
import "./AdminMerchantList.css";
import { toast } from "react-toastify";
import MySpinner from "../../common/MySpinner";
import { getUrlLastSegment, useQuery } from "../../../config/helper";
import OSpinner from "../../common/OSpinner";

const options = {
  onSizePerPageChange: (sizePerPage, page) => {},
  onPageChange: (page, sizePerPage) => {},
};

function AdminMerchantList() {
  const params = useQuery();
  const [merchants, setMerchants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [badgeLoader, setBadgeLoader] = useState(false);
  let accountStatus = params.as;

  const defaultSorted = [
    {
      dataField: "name",
      order: "desc",
    },
  ];

  const columns = [
    {
      dataField: "businessName",
      text: "Business name",
      filter: textFilter({
        placeholder: "Search...",
      }),
      sort: true,
      formatter: (cell, row, rowIndex, colIndex) => <Link to={`/admin/merchants/${row.id}`}>{row.businessName}</Link>,
    },
    {
      dataField: "name",
      text: "Name",
      filter: textFilter({
        placeholder: "Search...",
      }),
      formatter: (cell, row, rowIndex, colIndex) => row.name,
    },
    {
      dataField: "logo",
      text: "Image",

      formatter: (cell, row, rowIndex, colIndex) => <img src={row.logo} style={{ height: 40, width: "auto" }} />,
    },
    {
      dataField: "email",
      text: "Email",
      filter: textFilter({
        placeholder: "Search...",
      }),
    },
    {
      dataField: "phone",
      text: "Phone",
      filter: textFilter({
        placeholder: "Search...",
      }),
    },

    {
      dataField: "state",
      text: "State",
      filter: textFilter({
        placeholder: "Search...",
      }),
    },

    {
      dataField: "signupDate",
      text: "Signup Date",
      filter: textFilter({
        placeholder: "Search...",
      }),
    },
    {
      dataField: "accountStatus",
      text: "Account status",
      formatter: (cell, row, rowIndex, colIndex) => (
        <>
          <Badge
            bg={row.accountStatus === "approved" && "success"}
            className={row.accountStatus === "approved" && "ms-2"}
          >
            {row.accountStatus !== "approved" ? (
              <Button
                type="button"
                variant="secondary"
                size="sm"
                className="bg-primary ms-0 "
                style={{ fontSize: 10 }}
                onClickCapture={(e) => approveMerchant(e, row.id, rowIndex)}
              >
                approve merchant
              </Button>
            ) : (
              row.accountStatus
            )}
          </Badge>
          <br />
          <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose={true}
            overlay={
              <Popover>
                <Popover.Body>
                  <a href="#" onClickCapture={(e) => makeInactive(e, row.id, rowIndex)}>
                    make {row.status == "active" ? "inactive" : "active"}
                  </a>
                </Popover.Body>
              </Popover>
            }
          >
            <Badge className="hover-cursor mt-2 ms-2" bg={row.status == "active" ? "success" : "warning"}>
              {row.status}
            </Badge>
          </OverlayTrigger>
          {/* <Badge
            onClickCapture={(e) => makeInactive(e, row.id, rowIndex)}
            className="hover-cursor mt-2 ms-2"
            bg={row.status == "active" ? "success" : "warning"}
          > */}
          {/* {row.status} */}
          {/* {badgeLoader ? (
              <MySpinner height={"10px"} width={"10px"} />
            ) : (
              row.status
            )} */}
          {/* </Badge> */}
        </>
      ),
      filter: textFilter({
        placeholder: "Search...",
      }),
    },
    {
      text: "Action",
      formatter: (cell, row, rowIndex, colIndex) => (
        <Link to={"/admin/merchants/add/" + row.id}>
          <span className="fas fa-pencil-alt"></span>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    getMerchantList();
  }, [params.as]);

  function approveMerchant(e, id, index) {
    setLoading(true);
    myAxios
      .post(MERCHANT_APPROVE, { merchantId: id })
      .then((r) => {
        setLoading(false);
        let res = r.data;
        if (res.success) {
          toast.success("Merchant approved successfully");
          getMerchantList();
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  }

  function makeInactive(e, id, index) {
    e.preventDefault();
    setBadgeLoader(true);
    setLoading(true);
    myAxios
      .post(MERCHANT_ACTIVATE, { merchantId: id })
      .then(function (re) {
        setBadgeLoader(false);
        setLoading(false);
        let res = re.data;
        if (res.success) {
          setMerchants((oldMerchants) => {
            let newMerchants = [...oldMerchants];
            newMerchants[index] = res.data;
            return newMerchants;
          });
        } else {
          toast.error(res.message);
        }
      })
      .catch((er) => {
        setBadgeLoader(false);
        setLoading(false);
        toast.error(er.message);
      });
  }
  function getMerchantList() {
    setLoading(true);
    let filter = { accountStatus: params.as };
    myAxios
      .post(MERCHANT_LIST, { filter })
      .then((res) => {
        if (res.data && res.data.success) {
          if (res.data.success === true) {
            setMerchants(res.data.data);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const content = () =>
    merchants.length > 0 ? (
      <Card body className="shadow p-2">
        <BootstrapTable
          keyField="id"
          data={merchants}
          columns={columns}
          pagination={paginationFactory(options)}
          striped
          bordered={false}
          wrapperClasses="table-responsive"
          filter={filterFactory()}
          // defaultSorted={defaultSorted}
          classes="customBootStrapClasses"
        />
      </Card>
    ) : (
      noData()
    );
  const loader = () => (
    <div
      className="d-flex justify-content-center align-items-center text-success"
      style={{ height: "70vh", width: "auto" }}
    >
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  const noData = () => <Container className="d-flex justify-content-center">No data Found</Container>;

  function handleMerchantStatusChange(merchantId, status) {
    let params = {};
    if (status == "inactive") {
      params = {
        merchantId,
        status: "active",
      };
    } else if (status == "active") {
      params = {
        merchantId,
        status: "inactive",
      };
    }

    setLoading(true);
    myAxios
      .post(MERCHANT_STATUS, params)
      .then((res) => {
        if (res.data && res.data.success) {
          if (res.data.success === true) {
            toast.success(res.data.message);
            getMerchantList();
          }
        } else {
          toast.error(res.data.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <>
      <DashboardContainer>
        {load && <OSpinner />}
        <PageTitle
          title={"Merchant List"}
          rightSide={
            <Link to="/admin/merchants/add" className="btn btn-success btn-sm" style={{ float: "right" }}>
              <span className="fas fa-plus"></span> add merchant
            </Link>
          }
        />

        {loading ? loader() : content()}
      </DashboardContainer>
    </>
  );
}

export default AdminMerchantList;
