import { useEffect, useState } from "react";
import { Card, Container, Spinner, Table } from "react-bootstrap";
import MySpinner from "../components/common/MySpinner";
import myAxios from "../config/my-axios";

function useTable(url, body = {}, process = true) {
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(true);

  const loader = () => (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  const noData = () => <Container className="d-flex justify-content-center">No data Found</Container>;

  const content = (props) =>
    docs.length > 0 ? (
      <Card body className="shadow p-2">
        <Table striped hover responsive>
          {props.children}
        </Table>
      </Card>
    ) : (
      noData()
    );

  function updateItemDetail(index, key, value) {
    let newArr = [...docs];
    let objItem = { ...docs[index] };
    objItem[key] = value;
    newArr[index] = objItem;

    setDocs(newArr);
  }

  useEffect(() => {
    if (process) {
      myAxios
        .post(url, {
          ...body,
        })
        .then((res) => {
          if (res.data && res.data.success) {
            setDocs([...res.data.data]);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [process]);

  function Tbl(props) {
    return <div style={{ maxWidth: "100vw" }}>{loading ? loader() : content(props)}</div>;
  }

  return [Tbl, docs, updateItemDetail];
}

export default useTable;
