import { Link } from "react-router-dom";

export default function SidebarLink({ title, icon, to, target, closeSidebar }) {
  return (
    <li className="sidebar-link" onClickCapture={closeSidebar}>
      <Link
        className="text-white text-capitalize text-decoration-none link-hover d-flex align-items-center"
        to={to}
        target={target}
      >
        <span className={icon}></span>
        <span>{title}</span>
      </Link>
    </li>
  );
}
