import { Button, Spinner } from "react-bootstrap";

function OSpinner() {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          position: "absolute",
          width: "100%",
          height: "80vh",
          zIndex : 99999999999999
        }}
      >
        <Button variant="dark" disabled style={{ opacity : 1 }}>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          &nbsp; loading...
        </Button>
      </div>
    </div>
  );
}

export default OSpinner;
