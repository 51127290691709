import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Button, Card, Form } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import styles from "./MerchantLogin.module.css";
import { toast } from "react-toastify";
import AdminContext from "../../../context/admin-context";
import { BASE_URL, DEVELOPMENT_SERVER_MERCHANT_URL, DEVELOPMENT_SERVER_URL } from "../../../config/endpoints";
import { LOGGED_ADMIN, LOGGED_MERCHANT } from "../../../config/constants";
import MySpinner from "../../common/MySpinner";
import MerchantContext from "../../../context/merchant-context";
import { Link } from "react-router-dom";

function MerchantLogin() {
  const { authMerchant, setAuthMerchant } = useContext(MerchantContext);
  let initObj = {
    checkRemember: false,
  };
  const [form, setForm] = useState(initObj);
  const [data, setData] = useState({
    email: "",
    password: "",
    error: "",
    loading: false,
  });

  function handleForm(e) {
    e.target.name == "checkRemember"
      ? setForm({
          ...form,
          [e.target.name]: e.target.checked,
        })
      : setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
  }
  function updateData(e) {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      error: "",
    });
  }

  function loginUser(e) {
    e.preventDefault();
    if (data.loading) return;
    if (!data.email || !data.password) {
      setData({
        ...data,
        error: "Email & password are required!",
      });
      return false;
    }

    // log user | calling login api
    setData({
      ...data,
      loading: true,
    });
    axios
      .post(BASE_URL + "auth/merchant-login", data)
      .then((res) => {
        if (res.data.success && res.data.data.token) {
          // set token
          if (form.checkRemember === true) {
            localStorage.setItem(LOGGED_MERCHANT, JSON.stringify(res.data.data));
          }
          sessionStorage.setItem(LOGGED_MERCHANT, JSON.stringify(res.data.data));
          setAuthMerchant(res.data.data);
          // toast.success("Welcome back")
        } else {
          toast.error(res.data.message);
          setData({
            ...data,
            loading: false,
          });
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setData({
          ...data,
          loading: false,
        });
      });
  }

  if (authMerchant && authMerchant.token) {
    return <Navigate to="/merchant" replace={true} />;
  }

  const loader = () => (
    <div className="d-flex  justify-content-center ">
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );

  return (
    <div className={styles.login}>
      <h4>
        <strong>Merchant Login Form</strong>
      </h4>
      <br />
      <Card>
        <Card.Body className="bg-light">
          {data.loading && loader()}
          <Form onSubmit={loginUser}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Text className="text-danger">{data.error}</Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={data.email}
                style={{ minWidth: 270 }}
                onChange={updateData}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" value={data.password} name="password" onChange={updateData} />
              <div className="row gy-4 pt-2 mb-2">
                <div className="col-12 text-end">
                  <Link to="/merchant-reset-password" style={{ textDecoration: "none", color: "#878686" }}>
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <div className="row gy-4 pt-3 mb-2">
                <div className="col-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="checkRemember"
                      id="checkRemember"
                      onChange={handleForm}
                      checked={form.checkRemember}
                    />
                    <label className="form-check-label" htmlFor="checkRemember" style={{ color: "#878686" }}>
                      Remember me
                    </label>
                  </div>
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              {/* <Form.Check type="checkbox" label="Remember" /> */}
            </Form.Group>
            <Form.Group className="text-center">
              <Button className="px-4" variant="dark" size="sm" type="submit">
                Login
              </Button>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default React.memo(MerchantLogin);
