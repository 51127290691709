import { toast } from "react-toastify";

export const handleFailure = (err) => {
  const errorRes = err.response;
  const status = !!errorRes && errorRes.status;

  const errorMSg = !!errorRes && !!errorRes.data && errorRes.data.message;

  status !== 401 && toast.error(errorMSg, { toastId: errorMSg });
};
