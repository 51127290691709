import React from "react";
import NotFoundImage from "./notFoundDog.png";
const NotFoundPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        padding: 22,
        backgroundColor: "white",
        marginTop: 27,
      }}
    >
      <img
        src={NotFoundImage}
        style={{
          width: 273,
          height: 235,
        }}
      />
      <h5
        style={{
          padding: "16px 0px 0px",
          color: "#00446b",
        }}
      >
        COMING SOON
      </h5>
      <p
        style={{
          textAlign: "center",
          fontSize: 16,
        }}
      >
        I'm sorry, but we currently do not have Merchants in this area yet. Please check again soon as we are adding new
        businesses to eazyer nationwide each and every day. In the meantime, we still strongly encourage you to register
        as a Member or Merchant to be eligible for all of the exciting giveaways and rewards we have to offer. Thank you
        for visiting!
      </p>
    </div>
  );
};

export default NotFoundPage;
