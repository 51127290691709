import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone, faLocation, faLocationDot, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import "./Footer.css";

function Footer() {
  const navigate = useNavigate();
  const navigateAndReset = (e) => {
    navigate(e, { replace: true });
    window.scrollTo(0, 0);
  };
  return (
    <footer id="footer">
      <div className="footer-holder container">
        <div className="row ">
          <div className="col-xs-12">
            <div className="col1 pe-5">
              <h3 className="text-uppercase text-white">About Company</h3>
              <p className="fotext pe-5 secondaryWhite">
                We are changing the way Merchants advertise online and giving Consumers better deals, faster rewards,
                and amazing giveaways!
              </p>
            </div>
            <div className="col2">
              <h3 className="text-uppercase text-white">Quick Links</h3>
              <ul className="list-unstyled f-nav">
                <li>
                  <Link className="secondaryWhite linkDecoration" to="/aboutus">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    className="secondaryWhite linkDecoration"
                    onClick={(e) => navigateAndReset("/Benefits")}
                    to="/Benefits"
                  >
                    Benefits
                  </Link>
                </li>
                <li>
                  <Link className="secondaryWhite linkDecoration" to="/giveaways">
                    Giveaways
                  </Link>
                </li>
                <li>
                  <Link className="secondaryWhite linkDecoration" to="/contactus">
                    Contact US
                  </Link>
                </li>
                <li>
                  <Link className="secondaryWhite linkDecoration" to="/Faqs">
                    FAQs
                  </Link>
                </li>
                {/* <li>
                  <Link to="/">Offers</Link>
                </li>
                <li>
                  <Link to="/">Deals</Link>
                </li>
                <li>
                  <Link to="/">Latest News</Link>
                </li> */}
                {/* <li>
                  <Link to="/">Support</Link>
                </li> */}
                <li>
                  <Link className="secondaryWhite linkDecoration" to="/TermsAndConditions">
                    Terms &amp; Conditions
                  </Link>
                </li>
                {/* <li>
                  <Link to="/"> Contact Us </Link>
                </li> */}
              </ul>
            </div>
            <div className="col3">
              <h3 className="text-uppercase text-white">Helpful Links</h3>
              <ul className="list-unstyled f-nav">
                <li>
                  <Link className="secondaryWhite linkDecoration" to="/signin">
                    Member SignIn
                  </Link>
                </li>
                <li>
                  <Link className="secondaryWhite linkDecoration" to="/signup">
                    Member SignUp
                  </Link>
                </li>
                <li>
                  <Link className="secondaryWhite linkDecoration" to="/MerchantSignup">
                    Merchant SignUp
                  </Link>
                </li>
                {/* <li>
                  <Link to="/">Add Listing</Link>
                </li>
                <li>
                  <Link to='/'>Privacy Policy</Link>
                </li> */}
              </ul>
              <h3 className="text-uppercase text-white mt-3">Follow us</h3>
              <ul className="list-unstyled socail-network">
                <li>
                  <a href="https://www.facebook.com/eazyerdeals/" target="blank">
                    <FontAwesomeIcon style={{ fontSize: 20 }} icon={faFacebook} />
                  </a>
                </li>
                {/* <li>
                  <a href="https://twitter.com/eazyerdeals" target="blank">
                    <FontAwesomeIcon
                      style={{ fontSize: 20 }}
                      icon={faTwitter}
                    />
                  </a>
                </li> */}
                <li>
                  <a href="https://www.instagram.com/eazyerdeals/" target="blank">
                    <FontAwesomeIcon style={{ fontSize: 20 }} icon={faInstagram} />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/eazyerdeals/" target="blank">
                    <FontAwesomeIcon style={{ fontSize: 20 }} icon={faLinkedin} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col4">
              <h3 className="text-uppercase text-white">Contact US</h3>
              <ul className="list-unstyled contact-list">
                <li>
                  <span className="icon icon-location">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      style={{
                        color: "#E5E5E5",
                        //  height: "20px"
                        fontSize: 20,
                      }}
                    />
                  </span>
                  <address className="secondaryWhite linkDecoration">
                    <Row>
                      <Col>Rockin Rewards, LLC</Col>
                    </Row>
                    <Row>
                      <Col> 101 W Edison Ave.</Col>
                    </Row>
                    <Row>
                      <Col>Suite 234</Col>
                    </Row>
                    <Row>
                      <Col> Appleton, WI 54915</Col>
                    </Row>
                  </address>
                </li>
                <li>
                  <span className="icon icon-phone">
                    <FontAwesomeIcon
                      icon={faPhoneVolume}
                      style={{
                        color: "#E5E5E5",
                        // height: "18px",
                        transform: "rotate(-25deg)",
                        fontSize: 20,
                      }}
                    />
                  </span>
                  <span className="tel">
                    <a href="tel:920-903-1010" className="secondaryWhite linkDecoration pt-1">
                      920-903-1010
                    </a>
                  </span>
                </li>
                <li>
                  <span className="icon icon-email">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{
                        color: "#E5E5E5",
                        //  height: "20px"
                        fontSize: 20,
                      }}
                    />
                  </span>
                  <span className="mail">
                    <a href="mailto:eazyersupport@eazyer.com" className="secondaryWhite linkDecoration pt-1">
                      eazyersupport@eazyer.com
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col text-center footer-area text-white">
            <p>© Eazyer 2022. All Rights Reserved</p>
          </div>
        </div>
      </div>
      {/* <div className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <p>© Eazyer 2022. All Rights Reserved</p>
            </div>
            <div className="col-xs-12 col-sm-7">
              <ul className="list-unstyled footer-nav">
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <Link to='/aboutus'>About Us</Link>
                </li>
                <li>
                  <Link to="/Faqs">FAQs</Link>
                </li>
                 <li>
                  <Link to='/'>Privacy Policy</Link>
                </li> 
                <li>
                  <Link to="/TermsAndConditions">Terms and Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
    </footer>
  );
}

export default Footer;
