import React from "react";

const PageTitle = ({ title, rightSide }) => {
  return (
    <>
      <div className="container d-flex  justify-content-between flex-wrap">
        <div className="fs-4 mb-3">{title}</div>
        <div>{rightSide}</div>
      </div>
      <hr />
    </>
  );
};

export default PageTitle;
