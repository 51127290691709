import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./UserContext";
import { ToastContainer, toast } from "react-toastify";
import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <UserProvider>
      <App />
    </UserProvider>
    <ToastContainer theme="colored" />
  </BrowserRouter>
);
