import React, { useContext, useLayoutEffect } from "react";
import { Card, Container, Accordion } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import WebContext from "../../../context/web-context";
import SeachbarAndPoolBarForBigScreen from "./SeachbarAndPoolBarForBigScreen";
import SeachbarAndPoolBarForSmallScreen from "./SeachbarAndPoolBarForSmallScreen";

function Faqs() {
  const location = useLocation();
  const { windowDimensions } = useContext(WebContext);

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <SeachbarAndPoolBarForSmallScreen />
      <SeachbarAndPoolBarForBigScreen />

      <Card
        style={{ paddingTop: windowDimensions?.width > 768 && "85px" }}
        className="rounded-0 border-0 border-top bg-grey"
      >
        <section className="contact-sec container bg-white py-3 px-3">
          <Container>
            <div className="row">
              <h2 style={{ color: "#218C44" }}>Frequently Asked Questions</h2>
            </div>
          </Container>
          <Container>
            <div className="row">
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <b>What is Eazyer.com?</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    Eazyer.com® is a local business information and deals site with a twist. Not only do we offer deals
                    from local merchants, we also have up to date listings for businesses, allowing Users to read and
                    post reviews, see business hours, images, locations, etc. Every Friday and the last day of each
                    month, we giveaway Visa® Gift Cards and every 3 months we giveaway an exciting travel credit from
                    Travelocity®. Every one of our Members earn points for virtually all of their activity on our site.
                    These points can be redeemed for gift cards to local and national businesses and/or used to increase
                    their chances of winning some of our great giveaways. Click Here to become a Member today.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <b>How do I Sign Up?</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    Visit us at Eazyer.com® and click sign up on the homepage at the top right.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <b>What information is required to sign up?</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    You will need to enter in your name, email, birth date*, and create a password.
                    <br /> *must be at least 18 years old to have an account with Eazyer.com®.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <b>Do you have a money back guarantee?</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    Customer satisfaction is one of our top priorities. If at any time, you feel that you’re not getting
                    the level of service or value out of your Premium subscription, you are welcome to cancel anytime
                    and are welcome to continue as a FREE Basic Member or Merchant. We will gladly cancel your Premium
                    subscription and refund your current months fee. All the points you have earned to that point are
                    yours to keep and use as you wish.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <b>Do you have a mobile app for iOS or Android?</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    We are currently in the development of our mobile app for both the Android and iOS markets. We
                    expect to have those available in the respective platforms are coming very soon. In the meantime,
                    the eazyer.com web platform is designed as a "responsive" website so it can be viewed and used on
                    any device or screen size.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <b>Do you store my credit card information?</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    Absolutely not! 100% of our payments are encrypted, secure and routed through PayPal® or Braintree®
                    if you do not have a PayPal® account.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <b>How can I change my profile information?</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    When you log into your account, from there you can go to your profile and edit/update your
                    information at any time.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    <b>How does the Quarterly Travelocity® Giveaway work?</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    Every 3 months we randomly draw 1 Members name for this awesome giveaway and that Member wins a
                    $2,500 credit good for use on travelocity.com® for anything available on their site.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    <b>What is "My Points"?</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    You get points (based on your subscription level) for virtually all of your activity on Eazyer.com®,
                    such as posting reviews, sharing on Social Media, Redeeming Deals you have purchased, etc. The
                    points you earn can be used in almost all of our Giveaways or redeemed for gift cards to a large
                    variety of local and national businesses.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    <b>What are "Special Offers?"</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    Merchants you’ve saved as a favorite will send you updates and special deals, which will appear in
                    your message center and/or in your email updates.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    <b>How can I cancel or change my subscription?</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    Members can cancel or change their subscription at any time. If you have any issues or concerns
                    doing so, feel free to contact us directly by calling us at 920-903-1010.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>
                    <b>How do I redeem a Deal I purchased on Eazyer.com?</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    When you purchase a Deal on Eazyer.com®, it will be sent to your Message Center as well as your My
                    Deals page. All you have to do is present that Deal to the Merchant at the time you wish to redeem
                    it. They will enter a unique password on your device which will authenticate and cancel out that
                    specific Deal. Don’t forget, that you get awarded points once you actually use a Deal that you have
                    purchased.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                  <Accordion.Header>
                    <b>Why would I share my location?</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    This feature is so we can better customize deals for you in and around the city you are in to
                    enhance your user experience.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13">
                  <Accordion.Header>
                    <b>How often will I be billed for my Premium subscription?</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    Your monthly subscription will be billed via PayPal® (or Braintree®) on the same day each month from
                    the date that you signed up.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Container>
        </section>
      </Card>
    </>
  );
}

export default Faqs;
