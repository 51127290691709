import axios from "axios";
import React, { useState, useEffect } from "react";
import { Card, Col, Container } from "react-bootstrap";
import Lock from "../../../assets/images/Icons/lock.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  MERCHANT_FORGOT_PASSWORD,
  MERCHANT_FORGOT_PASSWORD_GET_OTP,
  MERCHANT_FORGOT_PASSWORD_VERIFY_OTP,
  USER_FORGOT_PASSWORD,
  USER_FORGOT_PASSWORD_GET_OTP,
  USER_FORGOT_PASSWORD_VERIFY_OTP,
} from "../../../config/endpoints";
import MySpinner from "../../common/MySpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./ForgotPassword.module.css";
import OtpInput from "react-otp-input";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const PWD_DIGIT_REGEX = /(?=.*\d)/; //should contain atleast 1 digit
const PWD_LOWER_REGEX = /(?=.*[a-z])/; //should contain atleast 1 lowercase
const PWD_UPPER_REGEX = /(?=.*[A-Z])/; //should contain atleast 1 uppercase
const PWD_SPCHAR_REGEX = /[$@$!%*?&]/; //should contain atleast 1 Special Character

function ForgotPassword({ reserMerchant = false }) {
  const navigate = useNavigate();
  //stage -1 : get User email & send OTP
  //stage -2 : Verify OTP
  //stage -3 : Submit new password
  const [stage, setStage] = useState(1);
  const [resetToken, setResetToken] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [confirmUserOTP, setConfirmUserOTP] = useState("");

  let initObj = {
    newPassword: "",
    confirmPassword: "",
  };
  const [form, setForm] = useState(initObj);
  const [load, setLoad] = useState(false);
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [chkDigit, setChkDigit] = useState(false);
  const [chkLower, setChkLower] = useState(false);
  const [chkUpper, setChkUpper] = useState(false);
  const [chkSpecialChar, setChkSpecialChar] = useState(false);
  const [chkChar, setChkChar] = useState(false);
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const handlePwdVal = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(form.newPassword));
    setChkDigit(PWD_DIGIT_REGEX.test(form.newPassword));
    setChkLower(PWD_LOWER_REGEX.test(form.newPassword));
    setChkUpper(PWD_UPPER_REGEX.test(form.newPassword));
    setChkSpecialChar(PWD_SPCHAR_REGEX.test(form.newPassword));
    form.newPassword.length > 8 ? setChkChar(true) : setChkChar(false);
  }, [form.newPassword]);
  useEffect(() => {
    setValidMatch(form.newPassword === form.confirmPassword);
  }, [form.confirmPassword]);

  const loader = () => (
    <div className="d-flex justify-content-center align-items-center text-success">
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  function submitForm() {
    const v1 = PWD_REGEX.test(form.newPassword);
    const v2 = PWD_REGEX.test(form.confirmPassword);
    if (!v1 || !v2) {
      toast.error("Please enter your new password");
      return;
    }
    const params = {
      password: form?.newPassword,
      confirm: form?.confirmPassword,
      token: resetToken,
    };
    setLoad(true);
    let url = reserMerchant ? MERCHANT_FORGOT_PASSWORD : USER_FORGOT_PASSWORD;
    axios
      .post(url, params)
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          setForm(initObj);
          setLoad(false);
          setResetToken("");
          setStage(1);
          reserMerchant
            ? navigate("/merchant-login", { replace: true })
            : navigate("/SignIn", { replace: true });
        } else {
          toast.error(res.data.message);
          setLoad(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoad(false);
      });
  }
  const submitNewPassWord = () =>
    load ? (
      loader()
    ) : (
      <>
        <div className="row gy-4 pt-3 px-4">
          <div className="col-12">
            <input
              type="password"
              name="newPassword"
              value={form.newPassword}
              onChange={handlePwdVal}
              className="form-control"
              placeholder="New Password*"
              aria-invalid={validPwd ? "false" : "true"}
              aria-describedby="pwdnote"
              onFocus={() => setPwdFocus(true)}
              onBlur={() => setPwdFocus(false)}
            />
          </div>
        </div>
        <div className="row gy-4 pt-3 px-4">
          <div className="col-12">
            <input
              type="password"
              name="confirmPassword"
              value={form.confirmPassword}
              onChange={handlePwdVal}
              className="form-control"
              placeholder="Confirm Password*"
              aria-invalid={validPwd ? "false" : "true"}
              aria-describedby="cnfpwdnote"
              onFocus={() => setMatchFocus(true)}
              onBlur={() => setMatchFocus(false)}
            />
          </div>
        </div>
        <div className="row gy-4 py-4 px-4 text-center">
          <div
            id="pwdnote"
            className={`row ${
              pwdFocus && !validPwd ? styles.instructions : styles.offscreen
            }`}
          >
            <div className="row">
              <div className="col-12 col-md-12">
                Must include Uppercase letters.{" "}
                {chkUpper ? (
                  <FontAwesomeIcon
                    className={styles.facheck}
                    icon={faCheckCircle}
                  />
                ) : (
                  <FontAwesomeIcon
                    className={styles.faXcheck}
                    icon={faCircleXmark}
                  />
                )}
              </div>
              <div className="col-12 col-md-12">
                Must include lowercase letters.{" "}
                {chkLower ? (
                  <FontAwesomeIcon
                    className={styles.facheck}
                    icon={faCheckCircle}
                  />
                ) : (
                  <FontAwesomeIcon
                    className={styles.faXcheck}
                    icon={faCircleXmark}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                At Least 1 number.{" "}
                {chkDigit ? (
                  <FontAwesomeIcon
                    className={styles.facheck}
                    icon={faCheckCircle}
                  />
                ) : (
                  <FontAwesomeIcon
                    className={styles.faXcheck}
                    icon={faCircleXmark}
                  />
                )}
              </div>
              <div className="col-12 col-md-12">
                Allowed special characters:{" "}
                <span aria-label="exclamation mark">!</span>{" "}
                <span aria-label="at symbol">@</span>{" "}
                <span aria-label="hashtag">#</span>{" "}
                <span aria-label="dollar sign">$</span>{" "}
                <span aria-label="percent">%</span>{" "}
                {chkSpecialChar ? (
                  <FontAwesomeIcon
                    className={styles.facheck}
                    icon={faCheckCircle}
                  />
                ) : (
                  <FontAwesomeIcon
                    className={styles.faXcheck}
                    icon={faCircleXmark}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                Minimum 8 characters.{" "}
                {chkChar ? (
                  <FontAwesomeIcon
                    className={styles.facheck}
                    icon={faCheckCircle}
                  />
                ) : (
                  <FontAwesomeIcon
                    className={styles.faXcheck}
                    icon={faCircleXmark}
                  />
                )}
              </div>
              <div
                className={`col-12 col-md-12 ${
                  validMatch ? "" : styles.offscreen
                }`}
              >
                Password Match.{" "}
                {validMatch ? (
                  <FontAwesomeIcon
                    className={styles.facheck}
                    icon={faCheckCircle}
                  />
                ) : (
                  <FontAwesomeIcon
                    className={styles.faXcheck}
                    icon={faCircleXmark}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            id="cnfpwdnote"
            className={`row ${
              matchFocus && !validMatch ? styles.instructions : styles.offscreen
            }`}
          >
            <div className="row">
              <div className="col-12 col-md-12">
                Must match the first password input field.{" "}
                {validMatch ? (
                  <FontAwesomeIcon
                    className={styles.facheck}
                    icon={faCheckCircle}
                  />
                ) : (
                  <FontAwesomeIcon
                    className={styles.faXcheck}
                    icon={faCircleXmark}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-12 text-center">
            <button
              type="button"
              onClickCapture={submitForm}
              className="register-now btn btn-success"
            >
              Reset Password
            </button>
          </div>
        </div>
      </>
    );
  const getOrVarifyOTP = (type) => {
    let params = {};
    setLoad(true);
    let url =
      type == "get"
        ? reserMerchant
          ? MERCHANT_FORGOT_PASSWORD_GET_OTP
          : USER_FORGOT_PASSWORD_GET_OTP
        : reserMerchant
        ? MERCHANT_FORGOT_PASSWORD_VERIFY_OTP
        : USER_FORGOT_PASSWORD_VERIFY_OTP;

    if (type === "get") {
      params = {
        email: userEmail,
      };
    } else {
      params = {
        email: userEmail,
        otp: confirmUserOTP,
      };
    }
    axios
      .post(url, params)
      .then((res) => {
        if (res.data.success) {
          setLoad(false);
          toast.success(res.data.message);
          if (type === "get") {
            setStage(2);
          } else {
            setResetToken(res?.data?.data?.token);
            setStage(3);
          }
        } else {
          toast.error(res.data.message);
          setLoad(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoad(false);
      });
  };
  const sendUserEmail = () =>
    load ? (
      loader()
    ) : (
      <>
        <div className="row gy-4 pt-3 px-4">
          <div className="col-12">
            <input
              type="email"
              name="sendUserEmail"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              className="form-control"
              placeholder="Enter Your Email"
              required
            />
          </div>
        </div>
        <div className="row gy-4 py-4 px-4 text-center">
          <div className="col-12 text-center">
            <button
              type="button"
              onClickCapture={() => getOrVarifyOTP("get")}
              className="register-now btn btn-success"
            >
              Get OTP
            </button>
          </div>
        </div>
      </>
    );
  const confirmOTP = () =>
    load ? (
      loader()
    ) : (
      <>
        <div className="row gy-4 pt-3 px-4">
          <div className="col-12 d-flex">
            <OtpInput
              value={confirmUserOTP}
              onChange={(e) => setConfirmUserOTP(e)}
              numInputs={4}
              separator={<span>-</span>}
              containerStyle={{ width: "100%" }}
              isInputNum
              inputStyle={{
                width: "3rem",
                height: "3rem",
                margin: "0 1rem",
                fontSize: "2rem",
                border: "none",
                borderBottom: "1px solid rgba(0,0,0,0.5)",
              }}
            />
          </div>
        </div>
        <div className="row gy-4 py-4 px-4 text-center">
          <div className="col-12 text-center">
            <button
              type="button"
              onClickCapture={() => getOrVarifyOTP("verify")}
              className="register-now btn btn-success"
            >
              Verify OTP
            </button>
          </div>
        </div>
      </>
    );

  const handleStages = () => {
    if (stage === 1) {
      return sendUserEmail();
    } else if (stage === 2) {
      return confirmOTP();
    } else if (stage === 3) {
      return submitNewPassWord();
    }
  };

  useEffect(() => {
    return () => {
      setStage(1);
      setResetToken("");
    };
  }, []);

  return (
    <section className="log-sec overflow-hidden">
      <Container
        fluid
        className="d-flex justify-content-center align-items-center w-100 p-md-5 p-3 "
        style={{ minHeight: "inherit" }}
      >
        <Col
          xl="4"
          sm="6"
          className="mb-md-6 me-md-6 flex-grow-1 flex-md-grow-0 col-xl-4 col-sm-6"
        >
          <Card className={styles.cards}>
            <Card.Body>
              <div className="rounded">
                <div className="gy-4 my-2">
                  <div className="text-center">
                    <img className={`${styles.lock}`} src={Lock} alt="" />
                    <h6 style={{ color: "#218C44" }}>
                      <strong>RESET PASSWORD</strong>
                    </h6>
                  </div>
                </div>
                {handleStages()}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    </section>
  );
}

export default ForgotPassword;
