import React from "react";
import { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { MERCHANT_SAVE_KEYWORDS } from "../../../config/endpoints";
import MySpinner from "../../common/MySpinner";
import { toast } from "react-toastify";
import axios from "axios";
import { Input, Tag } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import AdminContext from "../../../context/admin-context";
import "../../merchant/Pages/AddMerchantKeywordsStyle.css";
const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const AddMerchantKeywords = ({
  customStyle = false,
  keywordString = null,
  merchantId,
  getMerchantDetails = undefined,
}) => {
  const { authUser } = useContext(AdminContext);
  const [loader, setLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [tags, setTags] = useState([]);
  // const [tagsString, setTagString] = useState([]);
  const [enterString, setEnterString] = useState("");

  useEffect(() => {
    // getTagsData();
    if (keywordString) {
      const tagsArray = keywordString.split(",");
      setTags(tagsArray);
    }
  }, [keywordString]);

  // const getTagsData = () => {
  //   if (keywordStringArray?.length > 0) {
  //     let tagsArray = [];
  //     keywordStringArray?.forEach((data) => {
  //       tagsArray.push({ id: data, text: data });
  //     });
  //     setTags(tagsArray);
  //   } else {
  //     setTags([]);
  //   }
  // };

  // const handleDelete = (i) => {
  //   setTags(tags.filter((tag, index) => index !== i));
  // };

  // const handleAddition = (tag) => {
  //   setTags([...tags, tag]);
  // };

  // const handleDrag = (tag, currPos, newPos) => {
  //   const newTags = tags.slice();

  //   newTags.splice(currPos, 1);
  //   newTags.splice(newPos, 0, tag);

  //   // re-render
  //   setTags(newTags);
  // };

  // const handleTagClick = (index) => {
  //
  // };

  const saveKeywords = (data) => {
    const payload = {
      ...data,
      merchantId,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${authUser?.token}`,
        "content-type": "application/json",
      },
    };
    setLoader(true);
    axios
      .post(MERCHANT_SAVE_KEYWORDS, payload, config)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          let data = res.data;
          if (data.success) {
            toast.success(data.message);
            if (getMerchantDetails !== undefined) {
              getMerchantDetails();
            }
          } else {
            toast.error(data.message);
          }
        } else {
          toast.error("something went wrong");
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.message);
      });
  };
  const loading = () => (
    <div
      className="d-flex justify-content-center align-items-center mb-5"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // let tagsArray = [];
    // if (tags?.length > 0) {
    //   tags.forEach((tag) => {
    //     tagsArray.push(tag.id);
    //   });
    // }
    // const tagsString = tagsArray.join(",");
    const payload = {
      // keywordString: tagsString.toString(),
      keywordString: tags.toString(),
    };
    payload && saveKeywords(payload);
  };
  const addKeyword = () => {
    if (tags.length) {
      setTags([...tags, enterString]);
    } else {
      setTags([enterString]);
    }
    setEnterString("");
  };
  const removeAddedTags = (index) => {
    setDeleteLoader(true);
    // const updatedTags = tagsString.filter((e, tagIndex) => tagIndex !== index);
    const updatedTags = tags.filter((e, tagIndex) => {
      if (tagIndex !== index) {
        return e;
      }
    });

    // setTagString(updatedTags);
    setTags(updatedTags);
    setDeleteLoader(false);
  };

  return (
    <Card body className="mt-2 shadow py-2">
      {loader && loading()}
      <Row className="g-2 justify-content-start align-items-center">
        <Col sm={12} md={6} lg={6}>
          {!deleteLoader &&
            tags.map((tag, index) => (
              // <Tag color="#00446B" closable onClose={() => removeAddedTags({ keywordString: null })}>
              <Tag color="#00446B" key={index}>
                {tag}
                <CloseCircleOutlined
                  onClick={(e) => removeAddedTags(index)}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Tag>
            ))}
          <Input
            placeholder="Please type and enter to add new tag"
            style={{ margin: "10px 0" }}
            value={enterString}
            onPressEnter={(e) => addKeyword()}
            autocomplete={false}
            onChange={(e) => setEnterString(e.target.value.replace(/[^a-zA-Z0-9-\-\ \']/g, ""))}
          />
          {/* <ReactTags
            inline
            tags={tags}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            // handleTagClick={handleTagClick}
            inputFieldPosition="bottom"
          /> */}
          <Col sm={12} md={6} lg={6} className={customStyle && "d-flex justify-content-end"}>
            <button
              // disabled={!tagsString.length}
              onClick={(e) => {
                handleFormSubmit(e);
                // setTags([...tags, { id: "", text: tagsString }]);
              }}
              // type="submit"
              className="btn btn-success"
            >
              Save Keyword
            </button>
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

export default AddMerchantKeywords;
