import React, { useLayoutEffect } from "react";
import { useContext } from "react";
import { Card, Container, Accordion, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import WebContext from "../../../context/web-context";
import SeachbarAndPoolBarForBigScreen from "./SeachbarAndPoolBarForBigScreen";
import SeachbarAndPoolBarForSmallScreen from "./SeachbarAndPoolBarForSmallScreen";
import styles from "./TermsAndConditions.module.css";

function TermsAndConditions() {
  const location = useLocation();
  const { windowDimensions } = useContext(WebContext);

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <SeachbarAndPoolBarForSmallScreen />
      <SeachbarAndPoolBarForBigScreen />

      <Card
        style={{ paddingTop: windowDimensions?.width > 768 && "85px" }}
        className="rounded-0 border-0 border-top bg-grey"
      >
        <section className="contact-sec container bg-white py-3 px-3">
          <Container>
            <div className="row">
              <h5 style={{ color: "#218C44" }}>Terms and Conditions for Merchants and Members</h5>
              <p>
                Rockin’ Rewards, LLC and its affiliates and related companies, including www.eazyer.com, (collectively
                "Rockin’ Rewards"), own, operate and provide the www.eazyer.com website (the "Website") and its products
                or services to you subject to the following Conditions and Terms of Use (the "Terms"). By visiting,
                shopping on, or using our Website, you accept these Terms. Please read them carefully. These Terms
                contain very important disclosures and information regarding terms of sale, and several other terms that
                affect your legal rights.
              </p>
              <p>
                <b>
                  If you do not agree to the terms set out below, and those terms referenced therein, immediately stop
                  accessing or using any of the websites and/or services. By using the websites and/or services, you
                  have accepted the terms. By using the websites and/or services, you agree to these terms.
                </b>
              </p>
            </div>
          </Container>
          <Container>
            <div className="row">
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <b>1. Your Acceptance of the Terms</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      The Terms consist of these Conditions and Terms of Use, the Eazyer.com Privacy Policy, the
                      Eazyer.com Member Pool Policy, the Eazyer.com Community Pool Policy, the Conditions and Terms of
                      Use for Merchants and any supplemental terms, notices and other communications provided to you.
                      These Terms constitute a binding legal agreement between you ("you," "your" and/or "yourself") and
                      Rockin’ Rewards, LLC and its successors, subsidiaries, and affiliates, including, but not limited
                      to, www.eazyer.com ("Rockin’ Rewards," "we," "us" and/or "our"). These Terms govern your use of
                      the Website, and all versions thereof, and all products or services accessed through the Websites
                      ("Services").
                    </p>{" "}
                    <p>
                      {" "}
                      These Terms and separate terms of service or guidelines setting forth additional conditions may
                      both apply to a service or product offered via the Websites ("Additional Terms"), which are
                      incorporated by reference herein. The Additional Terms are intended to supplement these Terms, but
                      to the extent there is a conflict between these Terms and any Additional Terms, the Additional
                      Terms will control unless the Additional Terms expressly state otherwise and/or unless otherwise
                      prohibited by applicable law.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <b>2. Registering and Creating Your Account</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Registering requires you to create an account with www.eazyer.com by registering directly on the
                      Website (an "Account"). Registering will allow you to obtain access to certain Services, including
                      Deals (as defined below). By creating an Account, you agree to provide only accurate, complete
                      registration information, and agree to keep that information up-to-date. Individuals and entities
                      whose privilege to access the Website or use the Services has previously been terminated may not
                      register for an Account, nor may they designate a previously terminated individual or entity to
                      use their Account on their behalf. When you register, you will create log-in credentials specific
                      to you (a "User ID and Password"). Access to the Website and Services are not authorized by any
                      other person or entity using your User ID and you are responsible for preventing any unauthorized
                      use of your credentials.
                    </p>
                    <p>
                      You are solely responsible for any and all access to the Website or use of the Services by persons
                      using your User ID. If someone accesses our Website or Services using a User ID that we have
                      issued to you, we will rely on that User ID and will assume that access has been made by you. If
                      you become aware that your User ID is being used without authorization please notify us
                      immediately.
                    </p>
                    <p>
                      Upon registering and creating your Account, You will have access to your own private message
                      center ("Eazyer.com Message Center"). The Eazyer.com Message Center will, among other things, be
                      used by Eazyer.com to communicate with You. Communications may include important and time
                      sensitive information. You are encouraged to frequently check your Eazyer.com Message Center. You
                      may also use the Eazyer.com Message Center to communicate with Eazyer.com.
                    </p>
                    <p>
                      Further, upon registering and creating your Account, You will have the opportunity to follow other
                      Members and their purchases, as well as have other Members follow You and Your Purchases. In order
                      to follow another Member, the Member being followed must grant You permission to follow them. Once
                      another Member has granted You permission to follow them, that Member will join your "My Circle."
                      You may remove Members from your My Circle at any time and for any reason.
                    </p>
                    <p>
                      In addition to your My Circle, you can stay connected with Merchants by designating a Merchant as
                      your favorite and adding them to Your list of favorite Merchants ("Eazyer.com Favorites"). From
                      time to time, and as explain herein, Eazyer.com may automatically add certain Merchants to Your
                      Eazyer.com Favorites, and restrict Your ability to remove said Merchant from Your Eazyer.com
                      Favorites. By adding a Merchant to Your Eazyer.com Favorites all deals for that Merchant will be
                      automatically provided to you via your Eazyer.Com Message Center.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <b>3. Certain Conditions Placed on Your Use of the Websites and Services</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Your permission to use the Websites and/or Services is conditioned upon your agreement that you:
                    </p>
                    <ul>
                      <li>are 18 years of age or older;</li>
                      <li>will comply with the Terms; and</li>
                      <li>are able to form a binding contract with us.</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <b>4. Membership Levels; Payment of Monthly Membership Dues; Termination of Membership</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Eazyer.com will have two (2) levels of member-ship: Basic and Premium. Each level will receive
                      different benefits as described on the Website and in these Terms.
                    </p>
                    <div className="row">
                      <div className="col-md-9">
                        <Table responsive bordered>
                          <thead className={styles.theading}>
                            <tr>
                              <th></th>
                              <th>Basic</th>
                              <th>Premium</th>
                            </tr>
                          </thead>
                          <tbody className={styles.tbodys}>
                            <tr>
                              <th>
                                <b>Members Fee</b>
                              </th>
                              <th>
                                <b>FREE</b>
                              </th>
                              <th>
                                <b>$10.00 / month</b>
                              </th>
                            </tr>
                            <tr>
                              <th>Access to Discounts, Points, Giveaways and Contests</th>
                              <th>Yes</th>
                              <th>Yes</th>
                            </tr>
                            <tr>
                              <th>3X Eazyer Points and Subscription Credit Multiplier</th>
                              <th>-</th>
                              <th>Yes</th>
                            </tr>
                            <tr>
                              <th>3X Drawing Entry Multiplier</th>
                              <th>-</th>
                              <th>Yes</th>
                            </tr>
                            <tr>
                              <th>Access to Premium Member Only Exclusive Deals</th>
                              <th>-</th>
                              <th>Yes</th>
                            </tr>
                            <tr>
                              <th>100% Limited Cash Back Guaranty*</th>
                              <th>-</th>
                              <th>Yes</th>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <p>
                        *Rockin’ Rewards’ 100% Limited Cash Back Guaranty is a promise that if a Premium Member does not
                        receive savings from purchasing deals or earning credits which equal the amount of subscription
                        fees paid by a member during the period beginning the date of the Member’s initial membership,
                        or subsequent anniversaries, and the twelve (12) month anniversary of said date, the Member will
                        be refunded 100% of the membership fees paid during that time. A Premium Member will not be
                        eligible for the 100% Limited Gash Back Guaranty if they achieve any of the following:
                      </p>
                      <ul>
                        <li>
                          Purchased deals throughout the year and saved the equivalent of or more than what was paid in
                          subscription fees during the period beginning the date of the Member’s initial membership, or
                          subsequent anniversaries, and the twelve (12) month anniversary of said date;
                        </li>
                        <li>
                          Purchased deals provided exclusively to Premium Members and saved the equivalent of or more
                          than what was paid in subscription fees during the period beginning the date of the Member’s
                          initial membership, or subsequent anniversaries, and the twelve (12) month anniversary of said
                          date;
                        </li>
                        <li>
                          Used the Merchants Loyalty Points feature and in turn saved the equivalent of or more than
                          what was paid in subscription fees during the period beginning the date of the Member’s
                          initial membership, or subsequent anniversaries, and the twelve (12) month anniversary of said
                          date;
                        </li>
                        <li>
                          Earned Eazyer Points which equaled the same or more than what they paid in subscription fees
                          during the period beginning the date of the Member’s initial membership, or subsequent
                          anniversaries, and the twelve (12) month anniversary of said date;
                        </li>
                        <li>
                          Won a Five Hundred Dollar ($500.00) Members’ Pool Giveaway at least once throughout the period
                          beginning the date of the Member’s initial membership, or subsequent anniversaries, and the
                          twelve (12) month anniversary of said date; or
                        </li>
                        <li>
                          Won any other prizes, trips or giveaways with a value equal to or greater than what they paid
                          in subscription fees during the period beginning the date of the Member’s initial membership,
                          or subsequent anniversaries, and the twelve (12) month anniversary of said date.
                        </li>
                      </ul>
                      <p>
                        Membership Dues are due either monthly or annually, based upon the option You pick, with monthly
                        payments being charged to your PayPal account on the same date each month and annual payments
                        being charged to your PayPal account on the same date each year. The date of charge will be the
                        date on which you created and registered your account or upgraded your membership with
                        Eazyer.com. You can terminate Your membership, or upgrade or downgrade Your membership, at any
                        time and for any reason.
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <b>5. Purchases and Transactions</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      By operating www.eazyer.com Rockin’ Rewards provides its members opportunities to purchase certain
                      products and services (a "Deal") from third-party merchants (a "Merchant"), using promotional Deal
                      exchangeable for Merchant goods and/or services (a "Deal"). The availability of a Deal may be
                      limited by time, quantity and other factors. Rockin’ Rewards’ promotion of each Deal is a form of
                      advertisement for the Merchant.
                    </p>
                    <p>
                      Some Deals have special terms that vary from the general terms in this Section. These special
                      terms will be set forth in the Fine Print (as define below). When you purchase, accept, use or
                      attempt to use a Deal, you agree to all Terms. Furthermore, you also agree to the Fine Print
                      identified in the Deal copy and any additional deal-specific terms. These Terms apply to all Deal
                      unless the Fine Print on a particular Deal states otherwise. In the event of a conflict between
                      these Terms and the Fine Print on a Deal, the Fine Print will control.
                    </p>
                    <p>
                      <strong>The "Fine Print."</strong> In addition to the Terms, Deals come with specific
                      restrictions, terms and conditions (the "Fine Print"). The Fine Print may restrict when or where
                      You can redeem a Deal, as well as other Deal-specific limitations. Please be sure to always read
                      the Fine Print carefully. When the Fine Print and the Terms differ, the Fine Print controls.
                    </p>
                    <p>
                      Our Website displays discounts in connection with each Deal. Discounts are calculated based upon
                      information provided to us solely by Merchants. This information may include the retail price
                      suggested by the manufacturer, supplier or the Merchant, or an estimated price based on standard
                      industry practice. Because the information on which we base our discounts may vary, displayed
                      discount information may or may not be representative of the prevailing discount in every area or
                      on any particular day.
                    </p>
                    <p>
                      <strong>How to Complete a Deal or Transaction.</strong> When you place an order for a given Deal,
                      you agree to purchase the selected Deal on the associated terms, restrictions, conditions and Fine
                      Print. After placing your order, you will receive a confirmation of your purchase and your PayPal
                      account will be charged for the agreed upon amount. When the Deal for the Deal is ready to be used
                      you will be notified by email and message to your Eazyer.com Message Center.
                    </p>
                    <p>
                      <strong>Using Deal or Coupons.</strong> The following additional terms apply to all Deal, unless
                      otherwise stated on the Deal, in the Fine Print or required by law:
                    </p>
                    <ul>
                      <li>Deal are not redeemable for cash;</li>
                      <li>
                        Deal cannot be used for taxes, tips, prior balances and/or shipping or handling, as applicable;
                      </li>
                      <li>Deal must be redeemed in their entirety in one visit to a Merchant;</li>
                      <li>Duplicate use, sale or trade of a Deal is strictly prohibited;</li>
                      <li>
                        Neither Rockin’ Rewards nor a Merchant is responsible for lost or stolen Deal or Deal reference
                        numbers;
                      </li>
                      <li>Use of a Deal for alcoholic beverages is at the sole discretion of the Merchant; and</li>
                      <li>
                        Unless the Fine Print states otherwise, the Deal price does not include any taxes, which may be
                        charged to you separately by the Merchant at the time you redeem the Deal.
                      </li>
                    </ul>
                    <p>
                      <strong>Deal Expiration, Refunds and Specific Date Deals.</strong> Some Deal may expire on a date
                      printed on the Deal. However, if a state requires the Merchant to honor all or a part of the Deal
                      beyond the expiration date, then the Deal or any part thereof will expire in accordance with the
                      applicable law.
                    </p>
                    <p>
                      The Refund policy for any Deal will be stated on your Deal, if a Deal was provided, and will
                      always be stated at the time of purchase.
                    </p>
                    <p>
                      Some Deals are date-specific, such as a Deal for a ticketed event or a Deal where you select a
                      specific day to stay at a hotel ("Date-Specific Deal"). As such, the Deal can only be redeemed on
                      a single date or you might have to choose from a list of available dates before you buy a Deal. In
                      either case, the specific date will be identified on the Deal, and you can only redeem the Deal on
                      this specified date, and the entire Deal will expire on the date specified on the Deal. No full or
                      partial refunds are given to Members who fail to redeem a Date-Specific Deal on the date specified
                      on the Deal, and you may not reschedule a Date-Specific Deal for another date or time for any
                      reason. If the Date-Specific Deal is canceled or rescheduled by Us or the Merchant, we will send
                      you a message to your email and Eazyer.com Message Center notifying you prior to such cancelation
                      or rescheduling. If the Date-Specific Deal is rescheduled, our message will include the new date.
                      If the Date-Specific Deal will not be rescheduled, You will receive a refund of the amount You
                      paid.
                    </p>
                    <p>
                      <strong>Merchant is the Issuer.</strong> The Merchant is the issuer of the Deal and is solely
                      responsible for (i) redeeming the Deal, (ii) for all goods and services it provides to you, and
                      (iii) for any and all injuries, damages, liabilities and costs you may suffer, whether directly or
                      indirectly, in full or in part, related to the use or redemption of a Deal or not, as well as for
                      any unclaimed property liability arising from unredeemed or partially redeemed Deal. Unless
                      indicated otherwise, Rockin’ Rewards is solely the marketer of the Merchant’s goods or services.
                      You hereby irrevocably waive all rights related to, and release Rockin’ Rewards and its
                      subsidiaries, affiliates, partners, officers, directors, employees and agents from, any and all
                      liabilities arising from or related to any act or omission of a Merchant in connection with your
                      use of a Deal or the products and/or services provided in connection with a Deal.
                    </p>
                    <p>
                      <strong>Availability of Deals.</strong> Rockin’ Rewards reserves the right, in its sole
                      discretion, to exclude or otherwise limit the availability of a Deal, the Websites, the Services
                      or any other product or service. Additionally, some Deals may have geographical and jurisdictional
                      boundaries, as well as a limited number of availability. We cannot represent or warrant that any
                      product or service promoted on the Website will be available for purchase by any particular person
                      or at any particular time. Any attempt by You to obtain more than the permitted number of Deal,
                      including, without limitation, using multiple or different identities, registration or e-mail
                      addresses, will void your purchases and may subject your Account to termination, which shall be
                      determined at our sole discretion.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <b>6. Eazyer Points</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      "Eazyer Points" are a reward earned by Members that may only be applied as additional entries into
                      drawings and giveaways, as those are made available from time-to-time. Eazyer Points do not have a
                      cash value and so they are not refundable for cash, except where required by law. Resale of Eazyer
                      Points is strictly prohibited.
                    </p>
                    <p>
                      How to Earn Eazyer Points. There are two basic ways to earn Eazyer Points: (1) Registration
                      Points, which are automatically given in exchange for being a member; and (2) Activity Points,
                      which are given to Members in connection with a specific activity or action.
                    </p>
                    <p>
                      Eazyer Registration Points. Every member is given Twenty Five Hundred (2,500) points upon
                      registering on Eazyer.com and completing a new Member profile. Registration points are not
                      susceptible to any multiplier unless otherwise stated.
                    </p>
                    <p>
                      <strong>Eazyer Activity Points.</strong> Members will earn points by completing the following
                      activities or actions:
                    </p>
                    <ul>
                      <li>
                        Posting a Review on a Merchant’s Profile Page (as defined in the Conditions and Terms of Use for
                        Merchants). Basic Membership: 10 Points per review posted, Premium Membership: 20 Points per
                        review posted.
                      </li>
                      <li>
                        Refer a friend who becomes a Member, completes a new Member profile and as such, joins Your "My
                        Circle". Basic Membership: 100 Points per referral, Premium Membership: 500 Points per referral.
                      </li>
                      <li>
                        Checking-In with a Merchant by Scanning a Merchant’s QR Code at the Merchant’s place of
                        business. Basic Membership: 5 Points per check in, Premium Membership: 15 Points per check in.
                      </li>
                      <li>
                        Sharing a Merchant’s deal on a social media website by clicking the "Share" button on the
                        Website. Basic Membership: 10 Points per share, Premium Membership: 20 Points per share.
                      </li>
                      <li>
                        When another Member who is part of Your "My Circle" earns Eazyer Points by completing any of the
                        activities listed above, You will receive Eazyer Points equal to Twenty Percent (20%) of the
                        Eazyer Points received by said "My Circle" Member.
                      </li>
                    </ul>
                    <p>
                      The activities, as well as the number of Eazyer Points received for completing said activities,
                      may be adjusted at any time and by any means in Rockin’ Rewards sole and absolute discretion.
                    </p>
                    <p>
                      <strong>Eazyer Points During Your Birthday Month.</strong> During the month of Your birthday, you
                      will earn double Eazyer Activity Points.
                    </p>
                    <p>
                      <strong>Using Eazyer Points.</strong> Eazyer Points can be redeemed as additional entries into
                      contests and giveaways on Eazyer.com. Contests and giveaways may include, but do not necessarily
                      include, trips, electronics. If a Member redeems Eazyer Points as additional entries into contests
                      and giveaways, but does NOT win in that specific contest or giveaway, a percentage of the Eazyer
                      Points redeemed for additional entries will be refunded to said Member. The percentage of refunded
                      points is based upon Membership Level: Basic Membership: 2% of Eazyer Points refunded; Premium
                      Membership: 10% of Eazyer Points refunded; of Eazyer Points refunded.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <b>7. Official Rules for Contests, Drawings, Giveaways, Community Pools, Member Pools and Etc.</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <strong>NO PURCHASE NECESSAY</strong>
                    </p>
                    <p>
                      <strong>To Enter.</strong> Members and Merchants will be entered into contests, drawings and
                      giveaways as described in previous sections of these Terms. All entries must be received the last
                      day of each contest, drawing or giveaway period in order to be eligible. Rockin’ Rewards is not
                      responsible for lost, late, misdirected, illegible, incomplete, or incorrect entries. By
                      submitting to any contest, drawing or giveaway, you willingly opt into our contact lists and grant
                      full permission to Rockin’ Rewards and its subsidiaries, affiliates, directors, employees and
                      agents to occasionally send information to your email and/or Eazyer.com Message Center.
                    </p>
                    <p>
                      <strong>Prize Details.</strong> During each contest, drawing or giveaway period, random winners
                      will be randomly chosen from the submissions. Prizes will vary at the sole and absolute discretion
                      of Rockin’ Rewards. Odds of winning a prize depends on the number of eligible entries received. No
                      prize substitution or cash redemption is allowed by the winner, unless otherwise stated. Prizes
                      are not transferable, unless otherwise stated. All taxes incurred as a result of winning prizes
                      are the sole responsibility of the winner.
                    </p>
                    <p>
                      <strong>Awarding of Prizes, Drawings and Giveaways.</strong> The contest, drawing and giveaway
                      winners will be selected by random drawing from among eligible entries received. The drawing will
                      take place on or about the date listed in the terms of that specific contest or drawing. Rockin’
                      Reward’s decisions on all matters relating to this contest are final. The contest winner will be
                      notified via e-mail and Eazyer.com Message Center within one (1) business day following the
                      drawing. Failure to respond and accept within 72 hours of the e-mail or message will result in
                      forfeiture of the prize and Rockin’ Rewards will award the prize to an alternate winner selected
                      by random drawing from among the remaining eligible entries. Failure to comply with any terms and
                      conditions may, at the sole discretion of Rockin’ Rewards, result in the winner’s
                      disqualification, the forfeiture of his or her interest in the prize, and the award of the prize
                      to an alternate winner in a random drawing.{" "}
                      <strong>
                        Prizes may be awarded in person, via e-mail or via Eazyer.com Message Center. Participants agree
                        to have their photo likeness and Eazyer.com Username displayed on the Website or other social
                        media websites as determined by Rockin’ Rewards.
                      </strong>
                    </p>
                    <p>
                      <strong>Eligibility.</strong> The drawing is open to any Eazyer.com member age 18 or older and
                      must be a legal resident of the United States. All federal, state and local laws apply. Void where
                      prohibited. Eazyer.com, Rockin’ Rewards, LLC and their parent companies, advertising and promotion
                      agencies, are not responsible for printing, distributing or production errors and my rescind,
                      cancel or revoke any contest or drawing upon any such error without liability and in Rockin’
                      Rewards’ sole discretion.
                    </p>
                    <p>
                      <strong>Additional Terms Relating to the Contest.</strong> Rockin’ Rewards will not be responsible
                      for incomplete, lost, late, postage-denied, misdirected or illegal entries, or for failure to
                      receive entries due to transmission failures or technical failures of any kind, including without
                      limitation, malfunctioning of any network, hardware or software, whether originating with sender
                      or Rockin’ Rewards. In the event of a dispute, all entries will be deemed to have been submitted
                      by the owner of the Eazyer.com Account from which they were sent. For those purposes, an
                      Eazyer.com Account holder shall mean the natural person assigned to such Ezyer.com Account. Any
                      questions regarding the owner of an Eazyer.com Account shall be determined by Rockin’ Rewards in
                      its sole and absolute discretion. Entrants further agree to be bound by the terms relating to the
                      use of this Website, including these Conditions and Terms of Use. In the event the contest is
                      compromised by a virus, non-authorized human intervention, tampering or other cause beyond the
                      reasonable control of or Rockin’ Rewards which corrupts or impairs the administration, security,
                      fairness or proper operation of the contest, drawing or giveaway, Rockin’ Rewards reserves the
                      right in its sole discretion to suspend, modify or terminate the contest. Should the contest be
                      terminated prior to the stated expiration date, Rockin’ Rewards reserves the right to award the
                      prize based on the entries received before the termination date.
                    </p>
                    <p>
                      <strong>Dispute Resolution.</strong> By entering any contest or drawing, entrants agree that any
                      and all disputes, clams, and causes of action arising out of or in connection with a drawing,
                      contest or giveaway, or any prizes awarded, shall be resolved individually without resort to any
                      form of class action. All issues and questions concerning the construction, validity,
                      interpretation and enforceability of these Terms, or the rights and obligations of any entrant and
                      Rockin’ Rewards shall be governed by, and construed in accordance with the laws of the State of
                      Wisconsin, without giving effect to any choice of law or conflict of law rules or provisions, that
                      would cause the application of the laws of any jurisdiction other than Wisconsin. The invalidity
                      and unenforceability of any provision of these rules shall not affect the invalidity or
                      unenforceability of any other provision. In the event that any such provision is determined to be
                      invalid or unenforceable, these rules shall be construed in accordance with their terms as if the
                      invalid or unenforceable provision was not contained therein.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    <b>8. Ownership of the Websites and Services</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    Rockin’ Rewards, its subsidiaries and affiliates, including, but not limited to, www.Eazyer.com own,
                    control and license the Websites and/or Services and all the content that is included or otherwise a
                    part of the Websites and/or Services (the "Content").
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    <b>9. Copyright/Trademarks</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    The Website’s Content and/or Service’s Content are copyrighted under the United States copyright
                    laws and/or similar laws of other jurisdictions. Without any limitation, Rockin’ Rewards owns a
                    copyright over all Content, including the use and form of use of the Content. Trademarks and service
                    marks, including, without limitation, "Eazyer" and "Rockin’ Rewards" which appear on the Websites
                    and/or in the Services are the marks of Rockin’ Rewards, LLC or affiliated entities. You agree not
                    to display or use, in any manner, any such marks without Rockin’ Rewards’ prior written permission.
                    Without limitation to anything herein, you may not use meta tags or other "hidden text" utilizing
                    Our names or marks without Our express written consent. Notwithstanding the foregoing, any benefit
                    received by Your use of such marks will inure exclusively to Rockin’ Rewards or its respective
                    owners. The Content may not be copied, modified, reproduced, downloaded or distributed in any way,
                    in whole or in part, without the express prior written permission of Rockin’ Rewards, unless and
                    except as is expressly provided in these Terms. Any unauthorized use of the Content is prohibited.
                    You agree to abide by any and all additional trademark and copyright notices, information or
                    restrictions contained in any part of the Websites and/or Services.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    <b>10. Your Access to the Website and Services</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Subject to compliance with the Terms, You are granted a limited license to access and make
                      personal use of the Websites and/or Services, but You are expressly prohibited from:
                    </p>
                    <ul>
                      <li>
                        any collection and use of any product listings, descriptions, prices or any other information
                        posted on the Website for any purpose other than your own personal use, including, without
                        limitation, any commercial purpose, including marketing;
                      </li>
                      <li>
                        any downloading or copying of Website or Account information for any reason other than your
                        personal use, or any use of data mining, robots or similar data gathering and extraction tools;
                      </li>
                      <li>
                        framing or utilizing framing techniques to enclose any portion of the Website without express
                        written consent;
                      </li>
                      <li>interfering in any way with the proper functioning of the Websites and/or Services;</li>
                      <li>
                        attempting to gain access to secured portions of the Websites and/or Services to which You are
                        not allowed or are not granted access;
                      </li>
                      <li>using any process to search or harvest information from the Websites and/or Services;</li>
                      <li>
                        using the Websites and/or the Services to access or collect any personally identifiable
                        information for any purpose;
                      </li>
                      <li>
                        using the Website and/or Services to generate unsolicited email advertisements or spam; or
                      </li>
                      <li>deep-linking to any portion of the Website</li>
                    </ul>
                    <p>
                      Your unauthorized use of the Websites, Services, and/or any Content or material found thereon will
                      immediately terminate all of the permissions or licenses granted to You in these Terms.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    <b>11. Submitted Member Content</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Through Our Websites and Services We may offer You the opportunity to post reviews, comments and
                      other content ("Member Content"), and You agree not to post Member Content that is illegal,
                      obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights
                      or otherwise injurious to any third party. You also agree not to post any Member Content that
                      contains software viruses or any form of "spam," including, but not limited to, political
                      campaigning, commercial solicitation, and mass mailings. You agree not to impersonate any person
                      or entity or otherwise mislead as to the origin of Member Content. Although Rockin’ Rewards does
                      not regularly review posted Member Content, We reserve the right to, but we are not obligated to,
                      remove or edit any Member Content. Any Member Content of any kind made by You or any user of the
                      Websites and/or Services are those of the respective author(s) or distributor(s) and not of
                      Rockin’ Rewards.
                    </p>
                    <p>
                      You represent and warrant that you own or otherwise control all of the rights to the Member
                      Content that you post, that the Member Content is accurate, that use of the Member Content you
                      supply does not violate this policy and will not cause injury to any person or entity and that you
                      will indemnify Rockin’ Rewards for all claims resulting from Member Content you supply. Unless
                      stated otherwise, by posting Member Content or submitting any material you grant Rockin’ Rewards a
                      non-exclusive, royalty-free, perpetual, irrevocable and fully sub-licensable right to use,
                      reproduce, adapt, publish, create derivative works from, distribute and display Your Member
                      Content at any place, during any time and by any medium, as well as the right to use the name that
                      you submit in connection with such Member Content. Rockin’ Rewards does not take any
                      responsibility and or liability for any Member Content posted on www.eazyer.com.
                    </p>
                    <p>
                      Unless expressly requested, Rockin’ Rewards does not request or accept unsolicited submissions for
                      ideas or other products or services. Because We do not wish to have any future misunderstandings
                      when We develop new projects, whether internally or by third parties, that might seem similar to
                      their submitted ideas or materials, please do not make any such unsolicited submissions. If you
                      choose to make unsolicited submission, all unsolicited submissions shall be subject to these Terms
                      and you agree to grant to Rockin’ Rewards the irrevocable right and license to the submission as
                      if it were Member Content.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>
                    <b>12. Third-Party Links and Content</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    Sometimes communication from Websites or Services, or communications from Us will include content or
                    links from or to third-party websites that we do not control, maintain or endorse. In order to
                    access those third-party websites You may be required to leave our Websites and/or Services. Because
                    We do not control those third-party websites or the content contained therein, you expressly
                    acknowledge and agree that We are in no way responsible or liable for any of those third-party
                    websites, including their content, policies, failures, promotions, products, services, actions
                    and/or any damages, losses, failures or problems caused by, related to or arising from those third
                    parties or their websites. YOUR CORRESPONDENCE AND BUSINESS DEALINGS WITH OTHERS FOUND ON OR THROUGH
                    THE WEBSITES AND/OR SERVICES, INCLUDING, WITHOUT LIMITATION, THE PAYMENT AND DELIVERY OF PRODUCTS
                    AND SERVICES, AND ANY TERMS, CONDITIONS, WARRANTIES AND REPRESENTATIONS ASSOCIATED WITH ANY SUCH
                    DEALINGS, ARE SOLELY BETWEEN YOU AND THE THIRD-PARTY. YOU ARE HIGHLY ENCOURAGED TO REVIEW ALL
                    POLICIES, RULES, TERMS AND REGULATIONS.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                  <Accordion.Header>
                    <b>
                      13. Indemnity, Reservation of Right, Release; Exclusion of Warranties; Limitations of Liability
                    </b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      You agree to defend, indemnify and hold harmless Rockin’ Rewards and its subsidiaries, parent
                      companies, officers, directors, employees and agents from and against any and all claims, damages,
                      obligations, losses, liabilities, attorney’s fees, costs or debts and expenses from: (i) your use
                      of and access to www.eazyer.com; (ii) your violation of any of these Terms; (iii) your violation
                      of any third-party right, or (iv) any claim that any Member Content submitted by you causes damage
                      to a third-party.
                    </p>
                    <p>
                      Rockin’ Rewards reserves the right to monitor or take action regarding disputes that you may have
                      with other users, customers or any Merchants, but Rockin’ Rewards is under no obligation to
                      monitor or take action. To the extent legally permitted, you release us from any claims or
                      liability related to any conduct of any other Member or Merchant. You agree to waive any provision
                      in any jurisdiction which materially states: "A GENERAL RELEASE DOES NOT INCLUDE CLAIMS WHICH THE
                      CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE,
                      WHICH, IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR."
                    </p>
                    <p>
                      YOU HEREBY EXPRESSLY AND IRREVOCABLY AGREE THAT YOUR USE OF THIS WEBSITE IS AT YOUR OWN RISK.
                      UNLESS OTHERWISE SPECIFIED THE WEBSITES AND SERVICES ARE PROVIDED "AS IS," "WITH ALL FAULTS" AND
                      "AS AVAILABLE." No express or implied representations, warranties or guarantees of any kind about
                      the Websites, Services, or products, whether owned by Rockin’ Rewards or a third party are made
                      available to you by us, unless specified in writing.
                    </p>
                    <p>
                      WE MAKE NO WARRANTIES AS TO PRIVACY AND SECURITY OTHER THAN AS EXPRESSLY STATED IN THE PRIVACY
                      POLICY. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY A ROCKIN’ REWARDS REPRESENTATIVE SHALL
                      CREATE A WARRANTY. TO THE MAXIMUM EXTENT PERMITTED BY LAW, ROCKIN’ REWARDS AND OUR OFFICERS,
                      DIRECTORS, AGENTS, SUBSIDIARIES, AND MERCHANTS DISCLAIM ANY IMPLIED WARRANTIES THAT THE WEBSITE
                      AND SERVICES ARE MERCHANTABLE, TIMELY, ACCURATE, FIT FOR A PARTICULAR PURPOSE OR NEED, OF
                      SATISFACTORY QUALITY, OR NON-INFRINGING. WE DO NOT GUARANTEE THAT: (i) YOUR USE OF THE WEBSITES
                      AND/OR SERVICES WILL MEET YOUR REQUIREMENTS; (ii) YOUR USE OF THE WEBSITES AND/OR SERVICES WILL BE
                      UNINTERRUPTED, TIMELY, SECURE, AVAILABLE, AND FREE FROM ANY ERROR; (iii) ANY PRODUCTS OR SERVICES
                      OBTAINED BY YOU AS A RESULT OF YOUR USING THE WEBSITES AND/OR SERVICES WILL BE RELIABLE; OR (iv)
                      ELECTRONIC COMMUNICATIONS SENT FROM ROCKIN’ REWARDS WILL BE FREE OF VIRUSES OR OTHER HARMFUL
                      COMPONENTS.
                    </p>
                    <p>
                      SUBJECT TO THE EXCLUSIONS OF WARRANTIES PROVISIONS PROVIDED HEREIN, YOUR SOLE AND EXCLUSIVE REMEDY
                      FOR ANY DISPUTE WITH US IS TO DISCONTINUE YOUR USE OF THE WEBSITES AND SERVICES. YOU EXPRESSLY
                      UNDERSTAND AND AGREE THAT ROCKIN’ REWARDS, ITS AFFILIATES, OFFICERS, DIRECTORS, AGENTS, VENDORS OR
                      MERCHANTS SHALL NOT BE LIABLE TO YOU FOR ANY OF THE FOLLOWING: (i) ANY CLAIMS EXCEEDING THE TOTAL
                      AMOUNT OF FEES THAT YOU PAID US DURING THE PREVIOUS ONE-YEAR PERIOD FOR THE SPECIFIC PRODUCT
                      AND/OR SERVICE AT ISSUE OR (ii) ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY
                      DAMAGES ARISING FROM YOUR USE OF, INABILITY TO USE OR RELIANCE UPON THE WEBSITES AND SERVICES.
                      THESE LIMITATIONS APPLY TO, BUT ARE NOT LIMITED TO, ANY CLAIMS FOR LOST PROFITS, LOST DATA, LOSS
                      OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION OR ANY OTHER COMMERCIAL DAMAGES OR
                      LOSSES, EVEN IF WE KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. IF ANY APPLICABLE
                      JURISDICTION DOES NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
                      INCIDENTAL DAMAGES, IN SUCH JURISDICTION, OUR LIABILITY, AND THE LIABILITY OF OUR AFFILIATES,
                      OFFICERS, DIRECTORS, AGENTS AND MERCHANTS SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW.
                    </p>
                    <p>
                      SUBJECT TO THE EXCLUSIONS OF WARRANTIES, LIMITATIONS OF LIABILITY AND ARBITRATION PROVISIONS
                      PROVIDED HEREIN, YOU AGREE TO RELEASE ROCKIN’ REWARDS FROM ANY AND ALL CLAIMS OR LIABILITY RELATED
                      TO ANY PRODUCT OR SERVICE OF A THIRD-PARTY MERCHANT AND ANY ACTION OR INACTION BY A THIRD-PARTY
                      MERCHANT.
                    </p>
                    <p>
                      NOTHING IN THESE TERMS SHALL EXCLUDE OR LIMIT ROCKIN’ REWARDS’S WARRANTY OR LIABILITY FOR LOSSES
                      THAT MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW, IF ANY.
                    </p>
                    <p>
                      This section will survive the termination of this Agreement and your use of the Websites and/or
                      Services.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13">
                  <Accordion.Header>
                    <b>14. Termination of Membership and Accounts</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      To terminate your Agreement with Rockin’ Rewards you must close all of your Eazyer.com Accounts.
                    </p>
                    <p>
                      Not with standing anything to the contrary in these Terms, Rockin’ Rewards, in its sole
                      discretion, may discontinue any product or Service at any time and for any reason. At any time and
                      for any reason Rockin’ Rewards may terminate your Agreement with it, and all of your Eazyer.com
                      Accounts if (i) You do not comply with the Terms; (ii) a Service provider with whom Rockin’
                      Rewards works has terminated its relationship with Rockin’ Rewards or ceased to offer the Services
                      because of Your action or in-action; (iii) Rockin’ Rewards is required to do so by law; (iv)
                      Rockin’ Rewards no longer provides Services to the area where you live; or (v) Rockin’ Rewards
                      determines it is no longer commercially viable to provide you with Services.
                    </p>
                    <p>
                      Without limiting any of the foregoing, Rockin’ Rewards may, in its sole discretion and without any
                      warning, terminate or suspend any Member information, including, but not limited to, passwords,
                      member IDs and Accounts, if Rockin’ Rewards considers Your conduct to be unacceptable or if you
                      breach this Agreement or the Terms. If Your Member Information is terminated, you agree to
                      immediately cease access to our Websites and Services. These Terms will survive termination of
                      this Agreement. Vouchers issued prior to termination will be honored according to the applicable
                      Terms.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="14">
                  <Accordion.Header>
                    <b>15. Choice of Law</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    Except for the arbitration provisions contained herein which shall be governed by the Federal
                    Arbitration Act, You agree that any claim or dispute between You and Rockin’ Rewards shall be
                    governed by the laws of the State of Wisconsin without regard to conflict of law principles,.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="15">
                  <Accordion.Header>
                    <b>16. Arbitration and Venue</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Without regard to anything to the contrary herein, except for those matters that can be taken to
                      small claims court in Wisconsin, any dispute or claim resulting from or relating to You visiting
                      the Websites, purchasing or redeeming a Voucher or using the Services available from Rockin’
                      Rewards, as well as the validity or enforceability of this section, shall be resolved by binding
                      arbitration.
                    </p>
                    <p>
                      Either party may bring suit in court to enjoin infringement, unauthorized access or trespass to
                      computer networks or any other intellectual property claim. Furthermore, any dispute resolution
                      proceeding must be on an individual basis and not in a class, consolidated or representative
                      action.
                    </p>
                    <p>
                      The arbiter shall rule on any challenge as to the arbiter’s jurisdiction or to the validity or
                      enforceability of the agreement to arbitrate. If this arbitration provision or any part thereof is
                      held unenforceable, that provision shall be severable and the balance of the arbitration provision
                      remain in full force and effect. If any claim proceeds in court the parties both waive their right
                      to a jury trial and agree all litigation shall be exclusively in the courts of Outagamie County,
                      Wisconsin.
                    </p>
                    <p>
                      Contact information for Rockin’ Rewards’ Registered Agent:
                      <br />
                      ROGER D. HEMMERLING <br />
                      3426 N. JUANITA LANE <br />
                      APPLETON , WI 54911
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="16">
                  <Accordion.Header>
                    <b>17. Privacy, Electronic Communication, Entire Agreement, Amendments and Waivers</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Our Privacy Policy was designed to disclose to you how we collect, use and protect your personal
                      data because your privacy is important. Please read the Privacy Policy to help make informed
                      decisions when using our Websites and Services. The Privacy Policy is incorporated herein by
                      reference.
                    </p>
                    <p>
                      Most communications between you and Rockin’ Rewards will be electronic, and you consent to receive
                      communications in electronic form. You agree that all terms and conditions, agreements, notices
                      and other communications provided to you electronically shall be enforced as if they were in
                      writing.
                    </p>
                    <p>
                      These Terms, along with the Privacy Policy, and all other notices published on the Website or any
                      Voucher, shall constitute the entire agreement concerning your use of the Websites and/or
                      Services. Rockin’ Rewards reserves the right to occasionally change the Terms, which shall become
                      effective when posted to the Website. Staying up to date on any changes to the Terms is your sole
                      responsibility. By using the Websites and/or Services after changes or updates have been made to
                      the Terms indicates you accept and agree to the modified Terms. Changes to Terms will not apply to
                      Vouchers purchased prior to the date of modifications. Under no circumstances will an e-mail or
                      telephonic waiver or modification of the Terms be valid.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="17">
                  <Accordion.Header>
                    <b>18. General Terms</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Should any part of this Agreement be held invalid or unenforceable, that portion will be construed
                      consistent with applicable law and the remaining portions will remain in full force and effect.
                      This Agreement may be assigned for any reason and at any time without notice to you by Rockin’
                      Rewards. You, however, are not permitted to assign this Agreement or any rights and obligations
                      under the Agreement, without our prior written consent. Any attempt to assign this Agreement or
                      your rights and obligations will be invalid. The parties are independent contractors, and in no
                      way have we created a partnership, employment relationship or agency relationship. There are no
                      third-party beneficiaries of this Agreement. Failure by Rockin’ Rewards to enforce any provision
                      of this Agreement is not a waiver of the right to enforce such provision.
                    </p>
                    <p>
                      By using the Websites and/or Services, you represent and warrant that you are legally able to
                      accept these Terms, either more than 18 years of age or an emancipated minor, and fully able and
                      competent to enter into the agreements, terms, obligations, affirmations, representations and
                      warranties set forth in this Agreement. You also represent and warrant that you will abide by and
                      comply with this Agreement and its Terms. You must immediately cease use of the Websites and/or
                      Services if you are not legally able to accept these Terms or refuse to abide by and comply with
                      the Terms.
                    </p>
                    <p>
                      Rockin’ Rewards rights under this Agreement will survive any termination of this Agreement or the
                      Terms.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Container>
        </section>
      </Card>
    </>
  );
}

export default TermsAndConditions;
