import { React, useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { MERCHANTS_WEB } from "../../../config/endpoints";
import axios from "axios";
import MySpinner from "../../common/MySpinner";
import MyScrollToTop from "../../common/MyScrollToTop";
import DealsCard from "./DealsCard";
import SeachbarAndPoolBarForSmallScreen from "./SeachbarAndPoolBarForSmallScreen";
import SeachbarAndPoolBarForBigScreen from "./SeachbarAndPoolBarForBigScreen";
import WebContext from "../../../context/web-context";
import DealsListCardForSmallScreen from "./DealsListCardForSmallScreen";
import Loader from "../../common/Loader";

function Deals() {
  const { windowDimensions } = useContext(WebContext);
  const [merchants, setMerchants] = useState([]);
  const [dealsLoader, setDealsLoader] = useState(false);

  useEffect(() => {
    setDealsLoader(true);
    axios
      .get(MERCHANTS_WEB)
      .then((res) => {
        setDealsLoader(false);
        let data = res.data;
        if (data.success) {
          setMerchants(data.data);
        }
      })
      .catch((err) => {
        setDealsLoader(false);
      });
  }, []);

  const noDataFound = (content) => {
    return <div className="text-center my-5 py-5">No {content} found</div>;
  };
  const dealsContent = () =>
    merchants.length > 0
      ? merchants.map((deals) => {
          return windowDimensions.width <= 768 ? (
            <DealsListCardForSmallScreen key={deals?.id} deals={deals} image={deals?.logo} />
          ) : (
            <DealsCard key={deals.id} deals={deals} />
          );
        })
      : noDataFound("deal");

  return (
    <div
      className="bg-grey py-2"
      style={{
        transition: ".3s ease-in-out",
      }}
    >
      <SeachbarAndPoolBarForSmallScreen />
      <SeachbarAndPoolBarForBigScreen />
      <Container
        className={`container justify-content-center d-flex flex-wrap px-4 pt-4 ${
          windowDimensions.width > 768 && "mt-5"
        }`}
      >
        {dealsLoader ? <Loader height={"40px"} width={"40px"} /> : dealsContent()}
      </Container>
      <MyScrollToTop />
    </div>
  );
}

export default Deals;
