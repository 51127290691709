import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Button, Card, Form } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import styles from "./AdminLogin.module.css";
import { toast } from "react-toastify";
import AdminContext from "../../../context/admin-context";
import { BASE_URL } from "../../../config/endpoints";
import { LOGGED_ADMIN } from "../../../config/constants";
import MySpinner from "../../common/MySpinner";

function AdminLogin() {
  const { authUser, setAuthUser } = useContext(AdminContext);

  const [data, setData] = useState({
    email: "",
    password: "",
    error: "",
    loading: false,
  });

  function updateData(e) {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      error: "",
    });
  }

  function loginUser(e) {
    e.preventDefault();
    if (data.loading) return;
    if (!data.email || !data.password) {
      setData({
        ...data,
        error: "Email & password are required!",
      });
      return false;
    }

    // log user | calling login api
    setData({
      ...data,
      loading: true,
    });
    axios
      .post(BASE_URL + "auth/admin-login", data)
      .then((res) => {
        if (res.data.success && res.data.data.token) {
          // set token
          sessionStorage.setItem(LOGGED_ADMIN, JSON.stringify(res.data.data));
          setAuthUser(res.data.data);
          // toast.success("Welcome back")
        } else {
          toast.error(res.data.message);
          setData({
            ...data,
            loading: false,
          });
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setData({
          ...data,
          loading: false,
        });
      });
  }

  if (authUser && authUser.token) {
    return <Navigate to="/admin" replace={true} />;
  }

  const loader = () => (
    <div className="d-flex  justify-content-center ">
      <MySpinner height={"40px"} width={"40px"} />
    </div>
  );
  return (
    <div className={styles.login}>
      <h4>
        <strong>Admin Login Form</strong>
      </h4>
      <br />
      <Card>
        <Card.Body className="bg-light">
          {data.loading && loader()}
          <Form onSubmit={loginUser}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Text className="text-danger">{data.error}</Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={data.email}
                style={{ minWidth: 270 }}
                onChange={updateData}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" value={data.password} name="password" onChange={updateData} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              {/* <Form.Check type="checkbox" label="Remember" /> */}
            </Form.Group>
            <Form.Group className="text-center">
              <Button className="px-4" variant="dark" size="sm" type="submit">
                Login
              </Button>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default React.memo(AdminLogin);
