import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav, Container, Button, Dropdown, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import WebContext from "../../../context/web-context";
import Logo from "../../../assets/images/logo-1.png";
import styles from "./NavBar.module.css";
import "./NavBar.css";
import bannerStyles from "../Sections/Banner.module.css";

function NavBar() {
  let navigate = useNavigate();
  const location = useLocation();
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [sidebar, setSidebar] = useState(false);

  const {
    authUser,
    showSearchBar,
    windowDimensions,
    setShowSearchBar,
    handleSearchBarFormSubmit,
    categoryInput,
    useUserLocation,
    searchBarModal,
    autoCompleteInputField,
    handleSearchBarModalShow,
    selectedKeyword,
    setSelectedKeyword,
    handleCategorySearchBarField,
  } = useContext(WebContext);

  function switchSidebar() {
    setSidebar(!sidebar);
  }

  function handleRoute(route = null) {
    setSidebar(false);
    if (route) {
      navigate(route);
    }
  }

  useEffect(() => {
    return () => {
      setShowSearchBar(false);
    };
  }, []);
  const isHomePage = location.pathname === "/" || location.pathname === "/home";
  return (
    <>
      <Navbar bg="white" expand="md" fixed="top" className="shadow shadow-sm">
        <Container fluid>
          {searchBarModal()}
          <div className={` ${windowDimensions <= 768 ? "w-100" : ""}`} style={{ flex: 1 }}>
            <Row className="d-flex flex-row justify-content-between">
              <Col className="col-4 d-flex align-items-center">
                <Link
                  className={`navbar-brand ${windowDimensions <= 768 ? "w-100" : ""}`}
                  style={{ flex: 1 }}
                  to="/"
                  onClick={(e) => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <img height="60" width="160" className={styles.logo} src={Logo} alt="" />
                </Link>
              </Col>

              <Col className={`col-2 d-flex align-items-center justify-content-end`}>
                <FontAwesomeIcon fontSize={25} icon={faBars} className="text-dark d-md-none" onClick={switchSidebar} />
              </Col>
            </Row>
            <form
              onClick={() => {
                handleSearchBarModalShow();
              }}
              onSubmit={handleSearchBarFormSubmit}
              className={` border   ${isHomePage && !showSearchBar && "d-none"} ${
                windowDimensions.width > 480 && "d-none"
              }`}
              style={{ borderRadius: "19px", overflow: "hidden" }}
              action="/search"
            >
              <div className="">
                <div className={`${bannerStyles.searbarContainer}`}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // marginLeft: 12,
                      width: "60%",
                    }}
                  >
                    <input
                      style={{
                        border: 0,
                        width: "100%",
                        fontWeight: 400,
                        lineHeight: 1.5,
                        // marginLeft: 10,
                        fontSize: 13,
                      }}
                      autofocus={false}
                      onChange={(e) => {
                        handleSearchBarModalShow();
                        setSelectedKeyword(e.target.value);
                      }}
                      placeholder="Hair Salons, Restaurants, etc."
                      value={selectedKeyword}
                    />
                  </div>
                  {/* <div className={`${bannerStyles.searbarInput12}`} style={{ paddingRight: "1px" }}>
                    {handleCategorySearchBarField(`p-0 categorySearchBar ${bannerStyles.searbarInput}`)}
                  </div> */}
                  <div style={{ display: "flex" }} className={`py-2`}>
                    <div className={`vr`}></div>
                  </div>
                  <div
                    className={`${bannerStyles.searbarInput22} `}
                    style={{
                      paddingLeft: "1px",
                      flex: isInputFocus && 1,
                    }}
                  >
                    {autoCompleteInputField(`${bannerStyles.myInputClass}`)}
                  </div>

                  <button
                    onClick={(e) => {
                      handleSearchBarFormSubmit(e);
                    }}
                    disabled={useUserLocation ? false : !categoryInput}
                    type="submit"
                    className={`small btn-icon ${bannerStyles.searbarButton}`}
                    style={{ width: 40 }}
                  >
                    <FontAwesomeIcon fontSize={15} color="#fff" icon={faSearch} />
                  </button>
                </div>
              </div>
            </form>
            {!showSearchBar && (
              <Link
                className={`navbar-brand ${windowDimensions <= 768 ? "w-100" : ""}`}
                style={{ flex: 1 }}
                to="/"
                onClick={(e) => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <span className="text-success" style={{ marginLeft: 15, fontSize: 15 }}>
                  <strong>
                    <em>
                      <small className={`${styles.sloganFont}`}>Better Deals</small>
                      <span style={{ color: "#00446b" }}>
                        {" "}
                        <small className={`${styles.sloganFont}`}>Faster Rewards</small>{" "}
                      </span>
                      <small className={`${styles.sloganFont}`}> Amazing Giveaways</small>{" "}
                    </em>
                  </strong>
                </span>
              </Link>
            )}
          </div>
          <Navbar.Collapse className="d-flex" id="navbarScroll">
            <Nav
              className="flex-grow-1 justify-content-center d-none d-md-flex "
              style={{ maxHeight: "200px" }}
              navbarScroll
            >
              <Link
                className={[styles.navfont, "nav-link text-dark mx-2"].join(" ")}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                to="/"
              >
                Home
              </Link>
              <Link className={[styles.navfont, "nav-link text-dark mx-2"].join(" ")} to="/aboutus">
                About us
              </Link>
              <Link className={[styles.navfont, "nav-link text-dark mx-2"].join(" ")} smooth to="/Benefits">
                Benefits
              </Link>
              <Link className={[styles.navfont, "nav-link text-dark mx-2"].join(" ")} smooth to="/giveaways">
                Giveaways
              </Link>
              <Link className={[styles.navfont, "nav-link text-dark mx-2"].join(" ")} smooth to="/contactus">
                Contact Us
              </Link>
            </Nav>

            <div className="d-none d-md-block justify-content-space-between w-auto">
              {authUser && authUser.user ? (
                <>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="light"
                      size="sm"
                      className={`bg-transparent border-0 fw-bold text-dark ${styles.navfont}`}
                    >
                      <span style={{ color: "#00446b" }}>Hi {authUser.user.firstName}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="end" className="rounded-0 shadow ">
                      <Dropdown.Item>
                        <Link className="nav-link text-black" to="/MyAccount">
                          My Account
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="text-white">
                        <Link className="nav-link text-white " to="/logout">
                          <Button className="w-100" style={{ backgroundColor: "#00446b" }}>
                            Log out
                          </Button>
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : (
                <>
                  <Link
                    className="btn btn-success btn-sm px-3 mx-3 fw-bold"
                    to="/SignUp"
                    style={{ backgroundColor: "#218c44", borderRadius: 4 }}
                  >
                    Sign Up
                  </Link>
                  <Link
                    className="btn btn-success btn-sm px-3 fw-bold ms-2 me-4"
                    to="/SignIn"
                    style={{ backgroundColor: "#00446b", borderRadius: 4 }}
                  >
                    Sign In
                  </Link>
                </>
              )}
            </div>
          </Navbar.Collapse>

          <div
            id="sidebar"
            className="position-absolute p-3"
            style={{
              height: "100vh",
              width: "70%",
              display: sidebar ? "block" : "none",
              top: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "white",
              // opacity: 0.9,
              transition: "width 2s",
            }}
          >
            <FontAwesomeIcon
              icon={faBars}
              size="2x"
              className="position-absolute text-black"
              style={{ right: 15, top: 15 }}
              onClick={switchSidebar}
            />
            <div
              className={`d-flex flex-column align-items-end justify-content-start mt-5 mx-4`}
              style={{ height: "90vh" }}
            >
              <Link
                className="nav-link text-black fs-2"
                to="/"
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                onClickCapture={() => handleRoute()}
              >
                Home
              </Link>

              <Link className="nav-link text-black fs-2" to="/aboutus" onClickCapture={() => handleRoute()}>
                About us
              </Link>
              <Link className="nav-link text-black fs-2" to="/Benefits" onClickCapture={() => handleRoute()}>
                Benefits
              </Link>
              <Link className="nav-link text-black fs-2" to="/giveaways" onClickCapture={() => handleRoute()}>
                Giveaways
              </Link>

              <Link className="nav-link text-black fs-2" to="/contactus" onClickCapture={() => handleRoute()}>
                Contact Us
              </Link>

              {authUser ? (
                <>
                  <a className="nav-link text-black fs-2" onClickCapture={() => handleRoute("/logout")}>
                    Logout
                  </a>
                </>
              ) : (
                <>
                  <a
                    className="btn px-3 btn-success mt-3 fs-2 bg-success-alt mx-3"
                    onClickCapture={() => handleRoute("/SignUp")}
                    style={{ lineHeight: 1, width: 120 }}
                  >
                    <small> Sign Up</small>
                  </a>
                  <br />

                  <a
                    className="btn px-3 btn-dark mt-2 fs-2 bg-primary-alt mx-3"
                    onClickCapture={() => handleRoute("/SignIn")}
                    style={{ lineHeight: 1, width: 120 }}
                  >
                    <small> Sign In</small>
                  </a>
                </>
              )}
            </div>
          </div>
        </Container>
      </Navbar>
      {/* white box covering navbar height */}
      <div className={styles.navbarspace}></div>
    </>
  );
}

export default NavBar;
